import Axios from "axios";
//import helperstore from "@/store/helper_store";
//import QRCode from 'qrcode';

//var helperstoredetails = helperstore;
var authenticationToken = "";

export default {

	baseURL: process.env.VUE_APP_CareerCubical_BASE_URL,
	//baseURL:"https://localhost:7101/api",
	PythonAPI: process.env.VUE_APP_Python_API,
	VueJsUrl: process.env.VUE_APP_Js,
	TermUrl: process.env.VUE_APP_TERMS,
	PrivacyUrl: process.env.VUE_APP_PRIVACY,
	HelpUrl: process.env.VUE_APP_HELP,
}