import Vue from 'vue'
import VueRouter from 'vue-router'
import cognitoAuth from '@/cognito'
import helperstore from '@/store/helper_store'
import authstore from '@/store/prescription_store'
import Signin from '@/views/Accounts/login.vue'
import Profile from '@/views/Accounts/profile.vue'
import Dashboard from '@/views/Dashboard/dashboard.vue'
import CV from '@/views/CV/CV.vue'
import createCV from '@/views/CV/createCV.vue'
import Cvjdmatch from '@/views/CV/CvJdMatch.vue'
import BuyPackage from '@/views/Payment/BuyPackage.vue'
import PaymentSuccess from '@/views/Payment/PaymentSuccess.vue'
import PaymentCancel from '@/views/Payment/PaymentCancel.vue'
import UpgradeSuccess from '@/views/Payment/UpgradeSuccess.vue'
import UpgradeCancel from '@/views/Payment/UpgradeCancel.vue'
import ResetPassword from '@/views/Accounts/ResetPassword.vue'
import Security from '@/views/Accounts/security.vue'
import ChangePlan from '@/views/Payment/ChangePlan.vue'
import PaymentHistory from '@/views/Payment/PaymentHistory.vue'
import PurchasedPackages from '@/views/Payment/PurchasedPackages.vue'
import CoverLetter from '@/views/CoverLetter/CoverLetter.vue'
import CoverLetterFinal from '@/views/CoverLetter/CoverLetterFinal.vue'
import DownloadS3Files from '@/views/DownloadS3Files.vue'
import Registration from '@/views/Registration.vue'
import forgotpassword from '@/views/Accounts/forgotpassword.vue'
import changepassowrd from '@/views/Accounts/changepassowrd.vue'



Vue.use(VueRouter)

function convertToTimestamp(expiryTimeString) {
    const expiryDate = new Date(expiryTimeString);
    return Math.floor(expiryDate.getTime() / 1000); // Convert to seconds
}

function requireAuth(to, from, next) {
    var uid;
    var helperStore = helperstore;
    if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));

        const tokenExpiryTime = uid.expiretime; // Replace with actual token expiry time

        // Convert token expiry time string to timestamp
        const tokenExpiryTimestamp = convertToTimestamp(tokenExpiryTime);

        // Get the current timestamp
        const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds

        // Check authentication
        if (tokenExpiryTimestamp > currentTimestamp) {
            next(); // Allow access to the route
        } else {
            localStorage.clear();
            next({
                path: '/Signin',
                query: { redirect: to.fullPath }
            }); // Redirect to login page if token has expired
        }

    }
    else {
        localStorage.clear();
        next({
            path: '/Signin',
            query: { redirect: to.fullPath }
        }); // Redirect to login page if token has expired
    }


    //Set Document Title
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = "CareerCubicle";
    }

}

// function beforesessionout(to, from, next) {
//     cognitoAuth.isAuthenticated((err, loggedIn) => {
//         if (err) return next()
//         if (!loggedIn) {
//             next()
//         } else {
//             localStorage.removeItem('loggedinuserobj');
//             //cognitoAuth.logout(); 
//             next()
//         }
//     })
// }

const routes = [{
    path: "/",
    redirect: {
        name: "Signin"
    },
    meta: { title: "Home - Career Cubicle" },
},
{
    path: '/signin',
    name: 'Signin',
    component: Signin,
},
{
    path: '/CV/:id',
    name: 'CV',
    component: CV,
    beforeEnter: requireAuth,
    meta: { title: "Edit CV - Cubicle portal" },
},
{
    path: '/createCV',
    name: 'createCV',
    component: createCV,
    beforeEnter: requireAuth,
    meta: { title: "Create CV - Cubicle portal" },
},
{
    path: '/Cvjdmatch',
    name: 'Cvjdmatch',
    component: Cvjdmatch,
    beforeEnter: requireAuth,
    meta: { title: "CV-JD Match - Cubicle portal" },
},
{
    path: '/security',
    name: 'security',
    component: Security,
    beforeEnter: requireAuth,
    meta: { title: "Security - Cubicle portal" },
},
{
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: requireAuth,
    meta: { title: "Profile - Cubicle portal" },
},
{
    path: '/paymenthistory',
    name: 'PaymentHistory',
    component: PaymentHistory,
    beforeEnter: requireAuth,
    meta: { title: "Payment History - Cubicle portal" },
},{
    path: '/purchasedpackages',
    name: 'PurchasedPackages',
    component: PurchasedPackages,
    beforeEnter: requireAuth,
    meta: { title: "Packages - Cubicle portal" },
},
{
    path: '/BuyPackage',
    name: 'BuyPackage',
    component: BuyPackage,
},
{
    path: '/PaymentSuccess',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
},
{
    path: '/PaymentCancel',
    name: 'PaymentCancel',
    component: PaymentCancel,
},
{
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { title: "Reset password - Career Cubical" },
},
{
    path: '/ChangePlan',
    name: 'ChangePlan',
    component: ChangePlan,
    beforeEnter: requireAuth,
},
{
    path: '/UpgradeSuccess',
    name: 'UpgradeSuccess',
    component: UpgradeSuccess,
},
{
    path: '/UpgradeCancel',
    name: 'UpgradeCancel',
    component: UpgradeCancel,
},
{
    path: '/CoverLetter',
    name: 'CoverLetter',
    component: CoverLetter,
    beforeEnter: requireAuth,
},
{
    path: '/CoverLetterFinal',
    name: 'CoverLetterFinal',
    component: CoverLetterFinal,
    beforeEnter: requireAuth,
},

{
    path: '/DownloadS3Files',
    name: 'DownloadS3Files',
    component: DownloadS3Files,
},
{
    path: '/Registration',
    name: 'Registration',
    component: Registration,
},

{
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: forgotpassword,
},
{
    path: '/changepassowrd',
    name: 'changepassowrd',
    component: changepassowrd,
    beforeEnter: requireAuth,
},

{
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Dashboard,
    beforeEnter: requireAuth,
    meta: { title: "Dashboard -  Career Cubicle" },
}
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: __dirname
})

router.beforeEach((to, from, next) => {
    next();
});

export default router;