<template>
  <div>
      <div class="header">
  <div class="main-header">
    <div class="left-logo">
      <a  href="/Dashboard"><img src="../assets/CC-Logo-White.svg" alt=""/></a>
    </div>
    <div class="top-right">
      <div class="top-head-left">
          <a href="javascript:void(0);" class="menu-toggle">
            <i class="material-icons">menu</i>
          </a>
          <h2>{{heading}}</h2>
      </div>
      <div class="top-head-right">
        <div class="dropdown">
            <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img v-if="img==''" src="../assets/profile-img.png" alt=""/>
              <img v-else :src="img" alt=""/>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="/profile"><i class="fa fa-user"></i> {{fullName}}</a>
              <a class="dropdown-item" href="/profile"><i class="fa fa-envelope"></i> {{email}}</a>
              <a class="dropdown-item" href="/changepassowrd"><i class="fa fa-key"></i> Change Password</a>
              <a class="dropdown-item" @click="Logout()" href="javascript:void(0);"><i class="fa fa-sign-out"></i>  logout</a>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
 import axios from "axios";
 import rest_service from "@/api/rest_service_api.js";
export default {
    props: ["heading"],
  data(){
    return{
      email:"",
      showPassword: false,
      password:"",
      img:"",
      fullName:''

    }
  },
  created(){
    var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
    this.email = uid.user.email;
    this.Getuser();
  },
  mounted(){
    $('[data-toggle="tooltip"]').tooltip();

    $(document).ready(function(){
     
       $('.left-nav ul.main-menu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
        $('.left-nav ul.main-menu .submenu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });

        

    });
    $(".menu-toggle").click(function(){
          $(".main-section").toggleClass("menu-active");
        });

  },
   updated() {
    $('[data-toggle="tooltip"]').tooltip();

    $(document).ready(function(){
     
       $('.left-nav ul.main-menu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
        $('.left-nav ul.main-menu .submenu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });

        

    });
    $(".menu-toggle").click(function(){
          $(".main-section").toggleClass("menu-active");
        });

  },
  methods: {
    Getuser() {
      debugger;
      var self = this;
      if(localStorage.getItem("fullName")){
        if(localStorage.getItem("image")){self.img=localStorage.getItem("image");}
           
           self.fullName=localStorage.getItem("fullName");
        }
        else{
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserbyemail",
       //url: "https://localhost:7101/api" + "/User/GetUserbyemail",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            var profile = response.data.result;
             self.fullName = (profile.firstName != ""&& profile.firstName != null ? profile.firstName : "")+" "+(profile.lastName != ""&& profile.lastName != null ? profile.lastName : "");
             if(self.fullName.trim()==""){
               self.fullName="Profile";
             }
             localStorage.setItem("fullName",self.fullName);  
            // self.Email = profile.email != ""&& profile.email != null ? profile.email : "";
            // self.Pin_code = profile.pincode != ""&& profile.pincode != null ? profile.pincode : "";
            // self.Profession = profile.profession != ""&& profile.profession != null ? profile.profession : "";
            // self.Country = profile.country != ""&& profile.country != null ? profile.country : "";
            // self.City = profile.city != ""&& profile.city != null ? profile.city : "";
            // self.Phone = profile.phone != ""&& profile.phone != null ? profile.phone : "";
            // self.Uniqueid = profile.uniqueId != ""&& profile.uniqueId != null ? profile.uniqueId : "";
            debugger;
            if (
        profile.profilePic != null &&
        profile.profilePic != ""
      ) {
        self.GetProfilrpic(profile.profilePic);
        // self.resumeobj.coverletterobject;
      }
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }
    },
    GetProfilrpic(id) {
      var self = this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/S3proxy/GetAllImagebase64",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        responseType: "blob",
        params: {
          id: uid.user.email+"/"+id,
        },
      })
        .then(function (respo) {
          if (respo) {
            var reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result;
              console.log(base64String);
              // response.data.result.profilePic = base64String;
              self.img = base64String;
              localStorage.setItem("image", base64String);  
            };
            reader.readAsDataURL(respo.data);
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    Logout() {
      localStorage.clear();
      this.$router.push({ path: "signin" });
    },
  },
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
