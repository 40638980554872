<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="word-wrap: break-word; font-size: 16px; color: #49515f; line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div
          style="color: #fff; margin-top: 0;width: 100%; padding: 40px 50px; box-sizing: border-box;" :style="{background:resume.profile.color}"
        >
          <h1 v-if="resume.profile.firstname"
            style="max-width:calc(100% - 30%);display:block; word-wrap:break-word;text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
          >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
          <h1 v-if="resume.currentstep!=17 && !resume.profile.firstname"
            style="display:block; text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
          >Suresh Raina</h1>
          <span
            style="display:block; text-transform:uppercase; font-weight:500; letter-spacing:2px; border-top: 1px solid #eff0f2; 
            padding: 10px 0 0; margin: 5px 0 0;"
          >{{resume.profile.profession}}</span>
        </div>

        <div
          style="word-wrap: break-word; display: flex;height: 100%; font-size: 16px; color: #49515f; line-height: 1.3;font-family:Poppins, sans-serif;"
        >
          <div class="right-container" style="width: 70%;">
            <div style=" margin-top:25px;">
              <div v-if="resume.summary" style="margin-bottom:30px;">
                <div :style="{color:resume.profile.color}"
                  style="font-weight:600; display:flex; align-items:center; background:#eff0f2; letter-spacing:1px; padding:10px 15px; font-size:18px; text-transform:uppercase;"
                >Personal Profile</div>
                <div style="padding:15px 15px;">
                  <p v-html="resume.summary" style="margin:0; font-size:15px;"></p>
                </div>
              </div>
              <div v-if="resume.currentstep!=17 && !resume.summary" style="margin-bottom:30px;">
                <div :style="{color:resume.profile.color}"
                  style="font-weight:600; display:flex; align-items:center; background:#eff0f2;  letter-spacing:1px; padding:10px 15px; font-size:18px; text-transform:uppercase;"
                >Personal Profile</div>
                <div style="padding:15px 15px;">
                  <p style="margin:0; font-size:15px;color:grey;">Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.</p>
                </div>
              </div>

              <div
                v-if="resume.workExperience&&resume.workExperience.length>0"
                style="margin-bottom:30px;"
              >
                <div :style="{color:resume.profile.color}"
                  style="font-weight:600; display:flex; align-items:center; background:#eff0f2;  letter-spacing:1px; padding:10px 15px; font-size:18px; text-transform:uppercase;"
                >Work Experience</div>
                <div v-for="(item,index) in resume.workExperience" style="padding:15px 15px;">
                  <h4 style="margin:0; font-weight:500; font-size:17px;">{{item.jobtitle}}</h4>
                  <span
                    style="display:block; font-style:italic; font-size:14px; margin:10px 0; font-weight:500;"
                  >{{item.employer}} | {{item.startdate}} - {{item.enddate}}</span>
                  <ul style="margin:0; font-size:15px; padding:0 0 0 15px;">
                    <li
                      style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                      v-html="item.description"
                    ></li>
                  </ul>
                </div>
              </div>
              <div
                v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)"
                style="margin-bottom:30px;"
              >
                <div :style="{color:resume.profile.color}"
                  style="font-weight:600; display:flex; align-items:center; background:#eff0f2; letter-spacing:1px; padding:10px 15px; font-size:18px; text-transform:uppercase;"
                >Work Experience</div>
                <div style="padding:15px 15px;">
                  <h4 style="margin:0; font-weight:500; font-size:17px;color:grey;">Software Engineer</h4>
                  <span
                    style="display:block; font-style:italic; font-size:14px; margin:10px 0; font-weight:500;color:grey;"
                  >Compubotics | 07/2017 - Current</span>
                  <ul style="margin:0; font-size:15px; padding:0 0 0 15px;">
                    <li
                      style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                    >Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</li>
                  </ul>
                </div>
              </div>

              <div v-if="resume.education&&resume.education.length>0" style="margin-bottom:30px;">
                <div :style="{color:resume.profile.color}"
                  style="font-weight:600; display:flex; align-items:center; background:#eff0f2;  letter-spacing:1px; padding:10px 15px; font-size:18px; text-transform:uppercase;"
                >Educational History</div>
                <div v-for="(item,index) in resume.education" style="padding:15px 15px;">
                  <h4 style="margin:0; font-weight:500; font-size:17px;">{{ item.school_name }}</h4>
                  <span
                    style="display:block; font-style:italic; font-size:14px; margin:10px 0; font-weight:500;"
                  >{{item.degree +" "+item.field }} | {{item.grad_startdate }} - {{item.grad_enddate}}</span>
                  <ul style="margin:0; font-size:15px; padding:0 0 0 15px;">
                    <li
                      style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                      v-html="item.description"
                    ></li>
                  </ul>
                </div>
              </div>
              <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" style="margin-bottom:30px;">
                <div :style="{color:resume.profile.color}"
                  style="font-weight:600; display:flex; align-items:center; background:#eff0f2;  letter-spacing:1px; padding:10px 15px; font-size:18px; text-transform:uppercase;"
                >Educational History</div>
                <div style="padding:15px 15px;">
                  <h4 style="margin:0; font-weight:500; font-size:17px;color:grey;">Oxford University</h4>
                  <span
                    style="display:block; font-style:italic; font-size:14px; margin:10px 0; font-weight:500;color:grey;"
                  >Msc Computer Science | 01/2013 - 03/2016</span>
                  <ul style="margin:0; font-size:15px; padding:0 0 0 15px;">
                    <li
                      style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                    >Dean's list for the 2015–2016 academic year</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            class="left-container blue-bg"
            style="width: 30%;color: #49515f; padding:0; background:#eff0f2; margin-left:30px; border-radius: 30px 0 0 0;
            position: relative;margin-top: -100px;"
          >
            <div
              class="img-box"
              style="padding: 10px; position: relative; z-index: 1;background: #fff;margin:15px 15px 0; text-align: center;margin-top: -60px;
                border-radius: 4px; border: 1px solid #eee;display: flex;align-items: center;justify-content: center;"
            >
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 100%;width: 100px;object-fit: cover;height: 100px;margin: 0 auto;display: block; border-radius:50%;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 100%;width: 150px;object-fit: cover;height: 150px;margin: 0 auto;display: block; border-radius:50%;"
                src="https://img.freepik.com/free-photo/handsome-man-with-glasses_144627-18665.jpg?w=360&t=st=1694776719~exp=1694777319~hmac=47469ae6d7ce2888947cad33a4b8ab5cba0c7db1b96fed5e459d9bf559abbc8d"
                alt
              >
            </div>

            <div style>
              <div style="padding:20px 15px">
                <div :style="{color:resume.profile.color}"
                  style=" padding:20px 0; font-size:18px; text-transform:uppercase; font-weight:500;"
                >Contact Me At</div>

                <div v-if="resume.profile.address" style="display:flex; margin-bottom:15px;">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/49515f/home--v1.png"
                      alt="home--v1"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;">{{resume.profile.address}}</p>
                </div>
                <div v-if="resume.profile.phone" style="display:flex; margin-bottom:15px;">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/49515f/phone-disconnected.png"
                      alt="phone-disconnected"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;">{{resume.profile.phone}}</p>
                </div>
                <div v-if="resume.profile.linkedin" style="display:flex; margin-bottom:15px; ">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/linkedin-2--v1.png"
                      alt="globe--v1"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;">{{resume.profile.linkedin}}</p>
                </div>
                <div v-if="resume.profile.website" style="display:flex; margin-bottom:15px; ">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/glyph-neue/24/49515f/globe--v1.png"
                      alt="globe--v1"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;">{{resume.profile.website}}</p>
                </div>
                <div v-if="resume.profile.twitter" style="display:flex; margin-bottom:15px; ">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/000/twitter--v1.png"
                      alt="globe--v1"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;">{{resume.profile.twitter}}</p>
                </div>
                <div v-if="resume.profile.email" style="display:flex; margin-bottom:15px;">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/49515f/f/new-post--v1.png"
                      alt="new-post--v1"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;">{{resume.profile.email}}</p>
                </div>
                <div v-if="resume.currentstep!=17 && !resume.profile.email" style="display:flex; margin-bottom:15px;">
                  <span style=" width:24px; height:24px;min-width:24px;">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/49515f/f/new-post--v1.png"
                      alt="new-post--v1"
                    >
                  </span>
                  <p style="margin:0 0 0 5px;color:grey;">suresh@email.com</p>
                </div>
              </div>
            </div>

            <div v-if="resume.skills && resume.skills.length>0 " style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style="  padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Skills Summary</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  v-for="(item,index) in resume.skills"
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                >
                  {{item.text}}
                  <div style="display:block;">
                    <span
                      v-for="(data,index) in item.rate"
                      style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                    ></span>
                    <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                    ></span>-->
                    <span
                      v-if="(5-item.rate)!=5"
                      v-for="(data,index) in (5-item.rate)"
                      style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                    ></span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="resume.currentstep!=17 && !(resume.skills && resume.skills.length>0 )" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style=" padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Skills Summary</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                >
                  Accurate money handling
                  
                </li>
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                >
                  Sales Enterprising
                  
                </li>
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                >
                  Store opening and closing
                  
                </li>
              </ul>
            </div>

            <div v-if="resume.extraFields.accomplishments_text!='None'" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style="  padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Accomplishments</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.accomplishments_text"
                ></li>
              </ul>
            </div>
            <div v-if="resume.extraFields.affiliations_text!='None'" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style="  padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Affiliations</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.affiliations_text"
                ></li>
              </ul>
            </div>
            <div
              v-if="resume.extraFields.additional_information_text!='None'"
              style="padding:20px 15px;"
            >
              <div :style="{color:resume.profile.color}"
                style="  padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Additional Information</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.additional_information_text"
                ></li>
              </ul>
            </div>
            <div v-if="resume.extraFields.software_text!='None'" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style=" padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Softwares</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.software_text"
                ></li>
              </ul>
            </div>
            <div v-if="resume.extraFields.languages_text!='None'" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style=" padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Languages</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.languages_text"
                ></li>
              </ul>
            </div>
            <div v-if="resume.extraFields.certifications_text!='None'" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style="  padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Certifications</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.certifications_text"
                ></li>
              </ul>
            </div>
            <div v-if="resume.extraFields.interests_text!='None'" style="padding:20px 15px;">
              <div :style="{color:resume.profile.color}"
                style=" padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Interests</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="resume.extraFields.interests_text"
                ></li>
              </ul>
            </div>
            <div
              v-if="resume.extraFields.add_your_own_json.length>0"
              v-for="(data,index) in resume.extraFields.add_your_own_json"
              style="padding:20px 15px;"
            >
              <div :style="{color:resume.profile.color}"
                style="  padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >{{data.title}}</div>
              <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                <li
                  style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  v-html="data.body"
                ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {},
  methods: {
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        Color:self.resume.color,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


