

<template>
  <div class="signin-main" id="signin_container">
    <div class="signin-container">
      <div class="signin-hold">
        <div class="alert alert-danger" v-if="isinvalidcred">
          <p class="mb-0">Incorrect Username or Password</p>
        </div>
        <a href="javascript:void(0);" class="logo-sign-hold">
          <div class="logo-sgncontainer">
            <img src="../../assets/CC-Logo-Blue.svg" class="logo-fixed" />
          </div>
        </a>
        <h3 class="mt-4 mb-4">Sign In</h3>

        <form>
          <div class="mart-40">
            <div
              class="form-group signin-icons"
              v-bind:class="{ 'error-msg-hold': email && errormsg_email }"
            >
              <span class="signin-user"></span>

              <input
                type="text"
                name="email"
                v-model="email"
                class="form-control"
                autocomplete="off"
                placeholder=" "
                v-on:keyup.enter="nextPlease"
              />

              <label for="email">
                <b>Enter your email address</b>
              </label>
            </div>
            <span v-if="errormsg_email" style="color: #ff0000"
              >Please Enter Valid Email</span
            >
            <div class="form-group signin-icons mart-40 Passwordhols">
              <span class="singin-password"></span>
              <input
                type="password"
                name="psw"
                autocomplete=""
                placeholder=" "
                class="password-field"
                id="Password"
                v-model="password"
                v-on:keyup.enter="login()"
              />
              <label for="psw"><b>Enter your password</b></label>
              <div class="input-visible">
                <span
                  @click="ShowPassword"
                  v-bind:class="{
                    'signin-pass-visible': !showHideFlag && password.length > 0,
                    'signin-pass-hidden': showHideFlag && password.length > 0,
                  }"
                ></span>
              </div>
            </div>
            <span v-if="errormsg_password" style="color: #ff0000"
              >Please enter Valid Password</span
            >
          </div>
          <div class="col-md-12 px-0 text-right">          
              <a href="/forgotpassword">Forgot Password?</a>         
            </div>
          <div class="signin-btn-hold">
            <a
              href="#"
              @click.prevent="getstaffregistrationstatus()"
              class="btn-primary md-ink-ripple signin-btn"
              >Sign in</a
            >
          </div>
        </form>
      </div>

      <!-- <div class="signin-bottom-hold">
        
        <div class="signin-help">
          <ul>
            <li>
              <a :href="helpurl">Help</a>
            </li>
            <li class="divider-or">|</li>
            <li>
              <a :href="privacyurl">Privacy</a>
            </li>
            <li class="divider-or">|</li>
            <li>
              <a :href="termurl">Terms</a>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>


<script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api";
import myWorker from "@/config";
import moment from "moment";
var tenantpostdat = {
  operation: "read",
  payload: {
    TableName: "tenantURLMap",
    Key: {
      url:
        process.env.NODE_ENV === "production"
          ? window.location.hostname
          : process.env.VUE_APP_DEV_URL,
    },
  },
};

export default {
  data() {
    return {
      helpurl: rest_service.HelpUrl,
      privacyurl: rest_service.PrivacyUrl,
      showPassword: false,
      username: "",
      pass: "",
      error: null,
      errors: {},
      checkIsstaffexists: false,
      mutedloggedInUserInfo: this.loggedInUserInfo,
      is_login_success: false,
      tenantId: "",
      showHideFlag: false,
      hasLowerCaseChar: false,
      hasUpperCaseChar: false,
      hasSpecialChar: false,
      hasPassLength: false,
      hasNumberChar: false,
      isEmailEmpty: false,
      isPswEmpty: false,
      fromConfirm: false,
      logoURl: null,
      makeDisableOnLoad: true,
      makeDisableOnClick: false,
      loadDataFromSavedDetails: true,
      loggeedin_userinfo: "",
      loggedin_user_unique_id: "",
      authenticationToken: "",
      role_unique_id: "",
      makeDisable: true,
      password: "",
      password_length: 0,
      typed: false,
      contains_lowercase: false,
      contains_number: false,
      contains_uppercase: false,
      valid_password_length: false,
      valid_password: false,
      ispaswordrules: true,
      email: "",
      msg: [],
      tenant_api_url: process.env.VUE_APP_TENANT_URL,
      anonymusbaseurl: process.env.VUE_APP_ANONYMOUS_BASE_URL,
      businesssetupurl: process.env.VUE_APP_BUSINESS_SETUP_URL,
      staffregstatusurl: process.env.VUE_APP_GETSTAFF_REG_URL,
      isinvalidcred: false,
      logo: "",
      errormsg_password: "",
      errormsg_email: "",
      showFreeTrialButton: false,
      remainingdays: 0,
      totalvalidity: 0,
      graceday: 0,
      validitydays: 0,
      termurl: rest_service.TermUrl,
    };
  },
  created() {},
  beforeMount() {},
  mounted() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover",
    });
  },
  updated() {},
  computed: {},
  methods: {
    nextPlease: function (event) {
      document.getElementById("Password").focus();
    },
    ShowPassword() {
      var typ = document.getElementById("Password").getAttribute("type");
      if (typ == "password") {
        document.getElementById("Password").setAttribute("type", "text");
        this.showHideFlag = !this.showHideFlag;
      } else {
        document.getElementById("Password").setAttribute("type", "password");
        this.showHideFlag = !this.showHideFlag;
      }
      //return type;
    },

    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
        this.isinvalidcred = false;
        this.errormsg_email = false;
      } else {
        this.msg["email"] = "Please enter a valid email";
        this.errormsg_email = false;
      }
    },
    validatePassword(value) {
      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      // ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})
      if (strongRegex.test(value)) {
        this.ispaswordrules = true;
        this.msg["password"] = "";
      } else {
        this.msg["password"] = "";
        this.ispaswordrules = false;
        this.isinvalidcred = false;
        this.errormsg_password = false;
      }
    },
    signup() {
      var self = this;
      self.$cognitoAuth.signup(
        self.email,
        self.email,
        self.password,
        "+916290753847",
        "Animesh",
        (err, result) => {
          if (err) {
            console.error("Error occured!!!!!!!", err);
          } else {
            alert("Success!!!!");
            this.$router.push({ path: "dashboard" });
          }
        }
      );
    },
    getstaffregistrationstatus() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.errormsg_email = false;
      } else {
        this.errormsg_email = true;
      }

      console.log("inside getstaffdetails");
      debugger;
      var self = this;
      Axios({
        method: "post",
        url: rest_service.baseURL + "/Authenticate/login",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          username: self.email,
          password: self.password,
        },
      })
        .then(function (response) {
          if (response.data) {
            debugger;
            var dataToStore = JSON.stringify(response.data);
            localStorage.setItem("accesstoken", response.data.returnedToken);
            localStorage.setItem("loggedinuserobj", dataToStore);
           // self.GetPackage();
            self.$router.push({ path: "dashboard" });
          } else {
            return null;
          }
        })
        .catch(function (error) {
          self.isinvalidcred = true;
          console.log(error);
          return error;
        });
    },
    GetPackage() {
      debugger;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      Axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserPackage",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            var dataToStore = JSON.stringify(response.data.result);
            localStorage.setItem("UserPackageDetails", dataToStore);
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
  },
  updated() {},
};
</script>

<style type="text/css">
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body,
html {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100%;
}
html {
  font-size: 14px;
  height: 100%;
}
body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  height: 100%;
}

.signin-main {
    background: #cbdeeb;
    max-width: 100% !important;
    min-height: 100vh !important;
    padding: 50px;
}
.signin-main a {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: color, background-color, opacity;
}
.signin-main a:hover {
  text-decoration: none !important;
}
.signin-main h1 {
  font-size: 28px;
  font-weight: 500;
}
.signin-main h2 {
  font-size: 28px;
  font-weight: 400;
}
.signin-main h3 {
  font-size: 22px;
  font-weight: 400;
}
.signin-main h4 {
  font-size: 19px;
  font-weight: 400;
}
.signin-main h5 {
  font-size: 17px;
  font-weight: 500;
}
.signin-main .mart-40 {
  margin-top: 40px !important;
}
.signin-main .primary-btn {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  background: #1f85cd;
  color: #fff;
  border: 1px solid #1f85cd;
  border-radius: 0;
  text-decoration: none;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 115px;
  float: left;
  text-align: center;
}
.signin-main .primary-btn:hover {
  background: #0043a4;
  color: #fff;
  text-decoration: none;
  border-color: #0043a4;
}
.signin-main .secondary-btn {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  color: #1f85cd;
  border: 1px solid #1f85cd;
  border-radius: 0;
  text-decoration: none;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 115px;
  float: left;
  text-align: center;
}
.signin-main .secondary-btn:hover {
  background: #1f85cd;
  color: #fff;
  text-decoration: none;
  border-color: #1f85cd;
}
.signin-main .ripple {
  position: relative;
  overflow: hidden;
}
.signin-main .ripple:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
.signin-main .ripple:focus {
  box-shadow: none;
}
.signin-main .ripple:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  box-shadow: 0 0 0 30px #fff inset !important;
}
.signin-container {
  margin-top: 140px;
  background: none !important;
}
.login_hold {
  border: 1px solid #e4e8e7;
  border-radius: 3px;
  box-shadow: 0 0 3px 1px #e0dde0;
}
.login_main {
  padding: 12% 10% 19%;
}
.login_img {
  max-height: 440px;
}
.login_img img {
  height: 100%;
  width: 100%;
}
.login_hold .logoholder {
  margin-bottom: 40px;
}
.signin-container input {
  display: block;
  width: 100%;
  background: #fff !important;
  height: 45px !important;
  border: none !important;
  border-bottom: solid 2px #d2d4d5 !important;
  padding-left: 35px;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1d1d1d !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.signin-container .error-msg-hold input {
  display: block;
  width: 100%;
  background: #fff !important;
  height: 45px !important;
  border: none !important;
  border-bottom: solid 2px #f44336 !important;
  padding-left: 35px;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #f44336 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.form-elements::after,
.form-group::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.signin-container label {
  position: absolute;
  top: 8px;
  left: 35px;
  pointer-events: none;
  color: #bababa;
  font-size: 14px;
  font-weight: lighter;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.signin-container input:focus,
.signin-container input:not(:placeholder-shown) {
  box-shadow: none;
  outline: none;
  border-color: #1f85cd !important;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.signin-container input:focus ~ label,
.signin-container input:not(:placeholder-shown) ~ label {
  top: -15px;
  font-size: 13px;
  color: #1f85cd;
  font-weight: lighter;
}
.form-group {
  margin-bottom: 1rem;
}
.signin-container .error-msg-hold label,
.signin-container .error-msg-hold input:focus ~ label,
.signin-container .error-msg-hold input:valid ~ label {
  left: 35px;
  padding: 0;
}
.signin-container .material-icons {
  position: absolute;
  top: 5px;
  left: -2px;
  color: #bababa;
}
.signin-main {
  width: 100%;
  height: 100%;
  display: table;
  min-height: 100%;
}
.signin-container {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.signin-hold {
    max-width: 475px;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 60px 80px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.form-group.signin-icons {
  position: relative;
}
.signin-user:after {
  content: "";
  background: url(../../assets/signin-icon.png) -5px -5px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.singin-password:after {
  content: "";
  background: url(../../assets/signin-icon.png) -5px -57px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.error-msg-hold .signin-user:after {
  content: "";
  background: url(../../assets/signin-icon-red.png) -5px -5px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.error-msg-hold .singin-password:after {
  content: "";
  background: url(../../assets/signin-icon-red.png) -5px -57px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.signin-pass-hidden:after {
  content: "";
  background: url(../../assets/signin-icon.png) 7px -151px no-repeat;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 0;
  top: 11px;
}
.signin-icons .signin-pass-visible:after {
  content: "";
  background: url(../../assets/signin-icon.png) 7px -108px no-repeat;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 0px;
  top: 9px;
}
.signin-btn {
  float: none;
  margin: 0 auto;
  padding: 10px 45px;
  text-transform: uppercase;
  display: block;
}
.forgot-hold {
  display: block;
  width: 100%;
  text-align: right;
}
.signin-btn-hold {
  margin-top: 20px;
}
.signin-btn-hold .signin-btn {
  float: none;
  margin: 0 auto;
  padding: 10px 35px;
  display: block;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
.erroe-message {
  font-size: 13px;
  color: #f44336;
  text-align: left;
  float: left;
  margin-top: 5px;
}
.signin-bottom-hold {
  max-width: 475px;
  margin: 15px auto;
}
.signin-powered {
  float: left;
  font-weight: 500;
}
.signin-powered img {
  padding-left: 5px;
  max-width:100px;
}
.signin-help {
  float: none;
  text-align: center;
}
.signin-help ul li {
  list-style: none;
  display:inline-block;
  padding: 0 10px;
  color: #111;
  font-weight: 500;
}
.signin-help ul li:last-child {
  padding-right: 0;
}
.signin-help ul li a {
  color: #111;
}
.divider-or {
  color: #d2d4d5 !important;
}
.not-allowed-mark {
  cursor: not-allowed;
  max-width: 150px;
  margin: 0 auto;
  display: block;
}
.password-field:focus ~ .input-visible,
.password-field:valid ~ .input-visible {
  display: block;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
</style>

