import rest_service from "@/api/rest_service_api";
import axios from "axios";

const state={
	profile: {}
};

const actions={
	login ({ commit }, credentials) {
		      return axios.post('https://localhost:57799/api/Authenticate/login', credentials).then(res => {
		        commit('setProfile', res.data)
		      })
		    },
};

const mutations = {
	setProfile (state, profile) {
		state.profile = profile
	  },
};

const getters = {
	isAuthenticated: state => state.profile.name && state.profile.email
};

export default{
    state,
	actions,
	mutations,
	getters
};
