<template>
  <div>
    <div class="star-rating">
      <span
        v-for="rating in ratings"
        :key="rating"
        @click="setRating(rating)"
        :class="{ 'selected': rating <= selectedRating }"
      >
        &#9733; <!-- Unicode star character -->
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['maxRating'],
  data() {
    return {
      selectedRating: 0,
    };
  },
  computed: {
    ratings() {
      return Array.from({ length: this.maxRating }, (_, i) => i + 1);
    },
  },
  methods: {
    setRating(rating) {
      this.selectedRating = rating;
    },
  },
};
</script>

<style>
.star-rating {
  font-size: 0; /* Remove whitespace between stars */
}

.star-rating span {
  font-size: 24px; /* Adjust the size as needed */
  cursor: pointer;
  display: inline-block;
}

.star-rating span.selected {
  color: gold; /* Change the color of the selected stars */
}
</style>
