<template>
  <div></div>
</template>
<script>
export default {
  created() {
    debugger;
    var url = "";
    const currentURL = window.location.href;

    // Find the position of the "?" character in the URL
    const queryStringStart = currentURL.indexOf("?");
    if (queryStringStart !== -1) {
      // Extract the query string (everything after the "?") as is
      const queryString = currentURL.substring(queryStringStart + 1);

      // Find the "test" parameter within the query string
      const testParamStart = queryString.indexOf("url=");
      if (testParamStart !== -1) {
        // Extract the "test" parameter without decoding
        const testParam = queryString.substring(testParamStart + 4);
        url = testParam;
        console.log(testParam);
      }
    }
    // var url = this.$route.query.test;
    if (url != null && url != "") {
      this.Openfile(url);
    }
  },
  methods: {
    Openfile(url) {
      var url1 = decodeURIComponent(url);
      window.open(url1, "_blank", "channelmode=1");
    },
  },
};
</script>
