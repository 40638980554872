<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div class="left-container blue-bg" style="width: 30%;color: #fff;" :style="{background:resume.profile.color}" >
          <div class="img-box" style="padding: 20px 20px 0;">
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 150px;width: 100%;object-fit: cover;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 150px;width: 100%;object-fit: cover;"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              >
            </div>
          <div class="resume-block" style="padding: 30px 10px;">
            <div v-if="resume.profile.linkedin" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/linkedin-2--v1.png"
                  alt="linkedin-2--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.linkedin }}</p>
            </div>
            <div v-if="resume.profile.website" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/glyph-neue/24/000000/globe--v1.png"
                  alt="linkedin-2--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.website }}</p>
            </div>
            <div v-if="resume.profile.twitter" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/000/twitter--v1.png"
                  alt="linkedin-2--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.twitter }}</p>
            </div>
            <div v-if="resume.profile.phone" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/phone-disconnected.png"
                  alt="phone-disconnected"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.phone }}</p>
            </div>
            <div v-if="resume.profile.email" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/new-post--v1.png"
                  alt="new-post--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.email }}</p>
            </div>
            <div
              v-if="resume.currentstep!=17 && !(resume.profile.email)"
              style="display:flex; margin-bottom:15px;"
            >
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/new-post--v1.png"
                  alt="new-post--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">suresh@email.com</p>
            </div>
            <div v-if="resume.profile.address" style="display:flex;">
              <span
                style="background:#fff; border:2px solid #000; padding:4px; border-radius:50%; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/home--v1.png"
                  alt="home--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.address}}</p>
            </div>
          </div>
          <div v-if="resume.skills.length>0" class="heading" style="padding: 10px 10px;">
            <h4
              style="font-size:18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >PERSONAL STRENGTHS</h4>
          </div>
          <div v-if="resume.skills.length>0" class="resume-block" style="padding: 10px 10px;">
            <ul
              v-for="(item,index) in resume.skills"
              style="list-style: disc;padding-left: 0; margin:0;"
            >
              <li>
                {{item.text}}
                <div style="display:block;">
                  <span
                    v-for="(data,index) in item.rate"
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>-->
                  <span
                    v-if="(5-item.rate)!=5"
                    v-for="(data,index) in (5-item.rate)"
                    style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                  ></span>
                </div>
              </li>
            </ul>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.skills.length>0)"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size:18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >PERSONAL STRENGTHS</h4>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.skills.length>0)"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 25px; margin:0;">
              <li style="color:grey;">
                Accurate money handling
              </li>
              <li style="color:grey;">
                Store opening and closing 
              </li>
              <li style="color:grey;">
                Sales enterprising
              </li>
            </ul>
          </div>

          <!----------------------->

          <div
            v-if="resume.extraFields.accomplishments_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size:18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >ACCOMPLISHMENTS</h4>
          </div>
          <div
            v-if="resume.extraFields.accomplishments_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.accomplishments_text"></div>
            </ul>
          </div>

          <!----------------------------->
          <!----------------------->

          <div
            v-if="resume.extraFields.affiliations_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >AFFILIATIONS</h4>
          </div>
          <div
            v-if="resume.extraFields.affiliations_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.affiliations_text"></div>
            </ul>
          </div>

          <!----------------------------->
          <!----------------------->
          <!----------------------------->
          <!----------------------->

          <div
            v-if="resume.extraFields.additional_information_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >ADDITIONAL INFORMATION</h4>
          </div>
          <div
            v-if="resume.extraFields.additional_information_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.additional_information_text"></div>
            </ul>
          </div>

          <!----------------------------->
          <!----------------------->
          <div
            v-if="resume.extraFields.software_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >SOFTWARE</h4>
          </div>
          <div
            v-if="resume.extraFields.software_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.software_text"></div>
            </ul>
          </div>

          <!----------------------------->
          <!----------------------->

          <div
            v-if="resume.extraFields.languages_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >LANGUAGES</h4>
          </div>
          <div
            v-if="resume.extraFields.languages_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.languages_text"></div>
            </ul>
          </div>

          <!----------------------------->
          <!----------------------->

          <div
            v-if="resume.extraFields.certifications_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >CERTIFICATIONS</h4>
          </div>
          <div
            v-if="resume.extraFields.certifications_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.certifications_text"></div>
            </ul>
          </div>

          <!----------------------------->
          <!----------------------->

          <div
            v-if="resume.extraFields.interests_text!='None'"
            class="heading"
            style="padding: 10px 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >INTERESTS</h4>
          </div>
          <div
            v-if="resume.extraFields.interests_text!='None'"
            class="resume-block"
            style="padding: 10px 10px;"
          >
            <ul style="list-style: disc;padding-left: 0; margin:0;">
              <div v-html="resume.extraFields.interests_text"></div>
            </ul>
          </div>

          <!----------------------------->
        </div>

        <div class="right-container" style="width: 70%;padding: 30px;">
          <h1 v-if="resume.profile.firstname"
            style="word-wrap:break-word;display:block; text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
          >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
          <h1 v-if="resume.currentstep!=17 && !(resume.profile.firstname)"
            style="display:block; text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
          >Suresh Raina</h1>
          <span v-if="(resume.profile.profession)"
            style="display:block; text-transform:uppercase; font-weight:500; letter-spacing:1px; margin-bottom:30px;"
          >{{resume.profile.profession}}</span>
          <span v-if="resume.currentstep!=17 && !(resume.profile.profession)"
            style="display:block; text-transform:uppercase; font-weight:500; letter-spacing:1px; margin-bottom:30px;"
          >{{resume.profile.profession}}</span>

          <div v-if="resume.summary" class="heading" style="padding: 30px 0 10px;">
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >Summary</h4>
          </div>
          <div class="resume-box">
            <div v-if="resume.summary" class="resume-block" style="padding: 10px 10px;">
              <ul style="list-style: disc;padding-left: 0; margin:0;">
                <div v-html="resume.summary"></div>
              </ul>
            </div>
          </div>
            <div v-if="resume.currentstep!=17 && !(resume.summary)" class="heading" style="padding: 30px 0 10px;">
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >Summary</h4>
          </div>
          <div class="resume-box">
            <div v-if="resume.currentstep!=17 && !(resume.summary)" class="resume-block" style="padding: 10px 10px;">
              <ul style="list-style: disc;padding-left: 15px; margin:0;color:grey;">
                <div >Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.</div>
              </ul>
            </div>
          </div>
          <div
            v-if="resume.workExperience&&resume.workExperience.length>0"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >PROFESSIONAL WORK EXPERIENCE</h4>
          </div>

          <div
            v-if="resume.workExperience&&resume.workExperience.length>0"
            v-for="(item,index) in resume.workExperience"
            class="resume-box"
          >
            <!------------------------------>

            <h5 style="margin:20px 0 0; font-size:16px;">Positions Held</h5>
            <div class="resume-block" style="padding: 20px 0;">
              <div style="display:flex;margin-bottom:10px;">
                <p
                  style="margin:0; max-width:75%; width:100%;"
                >{{item.jobtitle}}, {{item.employer}} {{item.city +" " + item.country}}</p>
                <span
                  style="font-weight:600; margin-left:30px; display:inline-block;  max-width:87px; width:100%;"
                >{{item.startdate}} - {{item.enddate}}</span>
              </div>
            </div>
            <h5 style="margin:20px 0 0; font-size:16px;">Responsibilities</h5>
            <div class="resume-block" style="padding: 10px 20px;">
              <ul style="list-style: disc;padding-left: 0; margin:0;" v-html="item.description"></ul>
            </div>
            <!---------------------------------->
          </div>
            <div
            v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >PROFESSIONAL WORK EXPERIENCE</h4>
          </div>

          <div
            v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)"
            class="resume-box"
          >
            <!------------------------------>

            <h5 style="margin:20px 0 0; font-size:16px;color:grey;">Positions Held</h5>
            <div class="resume-block" style="padding: 20px 0;">
              <div style="display:flex;margin-bottom:10px;">
                <p
                  style="margin:0; color:grey;max-width:75%; width:100%;"
                >Software Engineer, Compubotics Banglore India</p>
                <span
                  style="font-weight:600;color:grey; margin-left:30px; display:inline-block;  max-width:25%; width:100%;"
                >07/2017 - Current</span>
              </div>
            </div>
            <h5 style="margin:20px 0 0; font-size:16px;color:grey;">Responsibilities</h5>
            <div class="resume-block" style="padding: 10px 20px;">
              <ul style="list-style: disc;padding-left: 15px; margin:0;color:grey;" >Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</ul>
            </div>
            <!---------------------------------->
          </div>
          <div
            v-if="resume.education&&resume.education.length>0"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >ACADEMIC CREDENTIALS</h4>
          </div>
          <div v-if="resume.education&&resume.education.length>0" class="resume-box">
            <div
              v-for="(item,index) in resume.education"
              
            >
              <!-- <ul
                style="list-style: disc;padding-left: 15px; margin:0;"
              >{{item.degree +" "+item.field }} {{ item.school_name }} - {{ item.school_location }}</ul>
              <ul
                style="list-style: disc;padding-left: 15px; margin:0;"
              >{{item.grad_startdate }} - {{item.grad_enddate}}</ul> -->
              <div class="resume-block" style="padding: 20px 0;">
              <div style="display:flex;margin-bottom:10px;">
                <p
                  style="margin:0; max-width:75%; width:100%;"
                >{{item.degree +", "+item.field }} {{ item.school_name }}  {{ item.school_location }}</p>
                <span
                  style="font-weight:600; margin-left:30px; display:inline-block;  max-width:87px; width:100%;"
                >{{item.grad_startdate }} - {{item.grad_enddate}}</span>
              </div>
            </div>
            </div>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4
              style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
            >ACADEMIC CREDENTIALS</h4>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" class="resume-box">
            <div
              
            >
              <div class="resume-block" style="padding: 20px 0;">
              <div style="display:flex;margin-bottom:10px;">
                <p
                  style="margin:0; color:grey;max-width:75%; width:100%;"
                >Msc, Computer Science Oxford University Mumbai,India</p>
                <span
                  style="font-weight:600;color:grey; margin-left:30px; display:inline-block;  max-width:25%; width:100%;"
                >01/2013 - 03/2016</span>
              </div>
            </div>
            </div>
          </div>
          <!---------------------------------->
          <div
            v-if="resume.extraFields.add_your_own_json.length>0"
            v-for="(data,index) in resume.extraFields.add_your_own_json"
          >
            <div class="heading" style="padding: 30px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; border-bottom: 1px solid #000;padding-bottom: 15px; text-transform:uppercase;"
              >{{data.title}}</h4>
            </div>
            <div class="resume-box">
              <div class="resume-block" style="padding: 10px 10px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li style=" padding-bottom: 10px;" v-html="data.body"></li>
                </ul>
              </div>
            </div>
          </div>

          <!--------------------------------->
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {},
  methods: {
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Color:self.resume.color,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            setTimeout(y => {
              self.navigate();
            }, 1000);
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


