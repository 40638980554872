<template>
  <div>    
    <div class="alert-hold"  id="successmsg" v-if="isVisible">
      <div class="alert-inner text-pad"  v-bind:class="{ 'error-prompt-message': iserror}">
        <div class="disp-tcell">
          <span v-html="datamessage"></span>
          <a href="javascript:void(0);" class="close-icon" v-on:click="closemsg()">
            <i class="material-icons">close</i>
          </a>
        </div>
      </div>
    </div>   
     
  </div>
</template>

<script>
export default {

  props: {
    datamessage: String,
    isVisible: {
      type: Boolean,
      default: false
    },
    iserror:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {  };
  },
  methods: {
    closemsg() {
         this.$emit("closemsg");
      //this.isVisible = !this.isVisible; 
    
    }
  },
  create()
  {
    
  },
  updated() {
    // $(document).ready(function() {
    //    setTimeout(function () {this.closemsg(); }.bind(this), 50000);
    // });
  }
};
</script>
