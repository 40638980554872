<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <a href="#"><img src="/img/CC-Logo-White.1144d714.svg" alt="" /></a>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <div>
        <h1>Payment completed successfully</h1>
        <img
          style="max-width: 80%"
          title="Careercubicle"
          alt="Careercubicle"
          src="https://careercubicle.com/images/success.png"
          border="0"
        />
      </div>
      <h5>Login details to access your cubicle have been emailed to you.</h5>
      <br />
      <br />
      <h6>
        In case you have any questions or clarifications, you can email
        <a href="mailto:contact@careercubicle.com">contact@careercubicle.com</a>
      </h6>
      <div v-if="showloader">
        <div
          class="processing_popup upload full-spinner"
          style="visibility: visible"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import myWorker from "@/config";
export default {
  created() {},
  components: {},
  data() {
    return {
      showloader: false,
    };
  },
  methods: {
    Dashboard() {
      this.$router.push({ path: "Dashboard" });
    },
  },
};
</script>
 