<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="display: flex;height: 100%; position:relative; font-size: 16px; color: #000; line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div
          :style="{width: '30%', color: '#fff', padding:'30px 15px 30px 15px', background:resume.profile.color}" style="margin-left:15px"
        >
          <div class="img-box" style="padding: 20px 0 0;">
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 150px;width: 100%;object-fit: cover;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 150px;width: 100%;object-fit: cover;"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              >
            </div>

          <div v-if="resume.summary" style="padding-left:0;">
            <div
              style=" color:#fff; padding:0; margin-top:5px;font-size:18px; text-transform:uppercase; font-weight:500;"
            >Profile</div>
            <p
              v-html="resume.summary"
              style="margin:15px 0 0; font-style:italic; line-height:26px;"
            ></p>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.summary" style="padding-left:0;">
            <div
              style=" color:#fff; padding:0; font-size:18px; text-transform:uppercase; font-weight:500;"
            >Profile</div>
            <p
              style="margin:15px 0 0; font-style:italic; line-height:26px;color:grey;"
            >Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.</p>
          </div>
          <div style>
            <div style="padding:20px 0">
              <div
                style=" color:#fff; padding:20px 0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Contact Me</div>

              <div v-if="resume.profile.address" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/ffffff/home--v1.png"
                    alt="home--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.address}}</p>
              </div>
              <div v-if="resume.profile.phone" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/ffffff/phone-disconnected.png"
                    alt="phone-disconnected"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.phone}}</p>
              </div>
              <div v-if="resume.profile.linkedin" style="display:flex; margin-bottom:15px; ">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/ffffff/linkedin--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.linkedin}}</p>
              </div>
              <div v-if="resume.profile.website" style="display:flex; margin-bottom:15px; ">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/glyph-neue/24/ffffff/globe--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.website}}</p>
              </div>
              <div v-if="resume.profile.twitter" style="display:flex; margin-bottom:15px; ">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/ffffff/twitter--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.twitter}}</p>
              </div>
              <div v-if="resume.currentstep!=17 && !(resume.profile.email)" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/ffffff/new-post--v1.png"
                    alt="new-post--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">suresh@email.com</p>
              </div>
              <div v-if="(resume.profile.email)" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/ffffff/new-post--v1.png"
                    alt="new-post--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.email}}</p>
              </div>
            </div>
          </div>
          <div v-if="resume.skills && resume.skills.length>0 " style>
            <div style="padding:20px 0">
              <div
                style=" color:#fff; padding:20px 0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Skills</div>

              <div style="display:flex; ">
                <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 15px;">
                  <li
                    v-for="(item,index) in resume.skills"
                    style="list-style:disc outside; font-size:15px; margin-bottom:5px;"
                  >
                    {{item.text}}
                    <div style="display:block;">
                      <span
                        v-for="(data,index) in item.rate"
                        style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                      ></span>
                      <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                      ></span>-->
                      <span
                        v-if="(5-item.rate)!=5"
                        v-for="(data,index) in (5-item.rate)"
                        style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                      ></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.skills && resume.skills.length>0 )" style>
            <div style="padding:20px 0">
              <div
                style=" color:#fff; padding:20px 0; font-size:18px; text-transform:uppercase; font-weight:500;"
              >Skills</div>

              <div style="display:flex; ">
                <ul style="margin:25px 0 0; font-size:15px; padding:0 0 0 0;">
                  <li                   
                    style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                  >
                    Accurate money handling
                  </li>
                  <li                   
                    style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                  >
                    Sales Enterprising
                  </li>
                  <li                   
                    style="list-style:disc outside; font-size:15px; margin-bottom:5px;color:grey;"
                  >
                    Store opening and closing
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 70%;padding: 30px;">
          <div
            style="background: #2a2e37; color: #fff; position: absolute;left: 0; margin-top: 0;width: 100%;padding: 120px 30px 30px calc(30% + 40px); box-sizing: border-box;top: 0;height: 270px; z-index: -1;"
          >
            <h1 v-if="resume.profile.firstname"
              style="display:block;word-wrap:break-word; text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
            >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
            <h1 v-if="resume.currentstep!=17 && !resume.profile.firstname"
              style="display:block; text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
            >Suresh Raina</h1>
            <span
              style="display:block; text-transform:uppercase; font-weight:500; letter-spacing:1px;"
            >{{resume.profile.profession}}</span>
          </div>
          <div style="margin-top:270px;">
            <div v-if="resume.education&&resume.education.length>0" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Education
              </div>
              <div style="margin-top:16px;" v-for="(item,index) in resume.education">
                <h3
                  style="text-transform:uppercase; margin:0; font-size:16px;font-weight:500;"
                >{{ item.school_name }}</h3>
                <span
                  style="font-style:italic; font-size:15px;color:#333;"
                >{{item.degree +" "+item.field }}, {{item.grad_startdate }} - {{item.grad_enddate}}</span>
              </div>
            </div>
            <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Education
              </div>
              <div style="margin-top:16px;" >
                <h3
                  style="text-transform:uppercase; margin:0; font-size:16px;font-weight:500;color:grey;"
                >Oxford University</h3>
                <span
                  style="font-style:italic; font-size:15px;color:grey;"
                >Msc Computer Science, 01/2013 - 03/2016</span>
              </div>
            </div>
            <div
              v-if="resume.workExperience&&resume.workExperience.length>0"
              style="margin-bottom:30px;"
            >
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Volunteer Experience
              </div>
              <div style="margin-top:16px;" v-for="(item,index) in resume.workExperience">
                <h3
                  style="text-transform:uppercase; margin:0; font-size:16px;font-weight:500;"
                >{{item.jobtitle}}</h3>
                <span
                  style="font-style:italic; font-size:15px;color:#333;"
                >{{item.startdate}} - {{item.enddate}}</span>

                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="item.description"
                ></span>
              </div>
            </div>
            <div
              v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)"
              style="margin-bottom:30px;"
            >
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Volunteer Experience
              </div>
              <div style="margin-top:16px;" >
                <h3
                  style="text-transform:uppercase; margin:0; font-size:16px;font-weight:500;color:grey;"
                >Software Engineer</h3>
                <span
                  style="font-style:italic; font-size:15px;color:grey;"
                >07/2017 - Current </span>

                <span
                  style="font-style:italic; font-size:15px;color:grey; display:block; margin:0 0 3px;"
                  
                >Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</span>
              </div>
            </div>
            <div v-if="resume.extraFields.accomplishments_text!='None'" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Accomplishment
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.accomplishments_text"
                ></span>
              </div>
            </div>
            <div v-if="resume.extraFields.affiliations_text!='None'" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Affiliation
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.affiliations_text"
                ></span>
              </div>
            </div>
            <div
              v-if="resume.extraFields.additional_information_text!='None'"
              style="margin-bottom:30px;"
            >
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>Additional Information
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.additional_information_text"
                ></span>
              </div>
            </div>
            <div v-if="resume.extraFields.software_text!='None'" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span> Software
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.software_text"
                ></span>
              </div>
            </div>
            <div v-if="resume.extraFields.languages_text!='None'" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span> Languages
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.languages_text"
                ></span>
              </div>
            </div>
            <div v-if="resume.extraFields.certifications_text!='None'" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span> Certification
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.certifications_text"
                ></span>
              </div>
            </div>
            <div v-if="resume.extraFields.interests_text!='None'" style="margin-bottom:30px;">
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span> Interest
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="resume.extraFields.interests_text"
                ></span>
              </div>
            </div>
            <div
              v-if="resume.extraFields.add_your_own_json.length>0"
              v-for="(data,index) in resume.extraFields.add_your_own_json"
              style="margin-bottom:30px;"
            >
              <div
                style="font-weight:600; display:flex; align-items:center;  color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
              >
                <span :style="{color:resume.profile.color}"
                  style="font-size: 24px;
                        margin-right: 15px;"
                >>></span>
                {{data.title}}
              </div>
              <div>
                <span
                  style="font-style:italic; font-size:15px;color:#333; display:block; margin:0 0 3px;"
                  v-html="data.body"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {},
  methods: {
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        Color:self.resume.color,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


