<template>
  <div style="max-width:100%;">
    <div class="signin-main">
      <div class="signin-container">
        <div class="signin-hold">
          <div >
 <div>
                <div class="logo-sgncontainer">
            <img src="../../assets/CC-Logo-Blue.svg" class="logo-fixed" />
          </div>
                <h3 class="mt-4 mb-4">Reset your Password</h3>
              </div>


            
            <form>
             
              

              <div class="mart-40">
                <div
                  class="form-group signin-icons disable-hold "
                  v-bind:class="!emailvalidation ? 'error-msg-hold' : ''"
                >
                  <span class="signin-user"></span>
                  <input
                    type="text"
                    autocomplete="off"
                    name="uname"
                    class="form-control"
                    v-model="email"
                  />
                  <!--<span class="erroe-message">Email address is invalid</span>-->
                  <label for="uname"><b>Enter your email address</b></label>
                  
                </div>
                <span v-show="!emailvalidation" class="help is-danger"
                    >Please enter a valid email</span
                  >
                <div
                  class="form-group signin-icons mart-40"
                  v-bind:class="!PasswrdPolicyValid ? 'error-msg-hold' : ''"
                >
                  <span class="singin-password"></span>
                  <input
                    type="password"
                    id="Password"
                    autocomplete="off"
                    name="psw"
                    class="password-field"
                    placeholder=" "
                    v-model="pass"
                  />
                  <div class="input-visible inline-eye" v-if="pass.length > 0">
                    <span
                      id="visibilityIconId"
                      data-toggle="tooltip"
                      title="Show"
                    >
                      <span
                        @click="ShowPassword"
                        v-bind:class="
                          pass.length > 0 && this.showHideFlag == false
                            ? 'signin-pass-visible'
                            : 'signin-pass-hidden'
                        "
                      ></span
                    ></span>
                  </div>
                  <div class="password-field input-visible">
                    <p>Password must contain :</p>
                    <ul>
                      <li
                        v-bind:class="
                          hasUpperCaseChar == true
                            ? 'password-valid'
                            : 'password-invalid'
                        "
                      >
                        At least 1 upper case letter ( A - Z )
                      </li>
                      <li
                        v-bind:class="
                          hasLowerCaseChar == true
                            ? 'password-valid'
                            : 'password-invalid'
                        "
                      >
                        At least 1 lower case letter ( a - z )
                      </li>
                      <li
                        v-bind:class="
                          hasNumberChar == true
                            ? 'password-valid'
                            : 'password-invalid'
                        "
                      >
                        At least 1 numeric character ( 0 - 9 )
                      </li>
                      <li
                        v-bind:class="
                          hasSpecialChar == true
                            ? 'password-valid'
                            : 'password-invalid'
                        "
                      >
                        At least 1 special case character
                      </li>
                      <li
                        v-bind:class="
                          hasPassLength == true
                            ? 'password-valid'
                            : 'password-invalid'
                        "
                      >
                        At least 8 characters
                      </li>
                    </ul>
                  </div>
                  <label for="psw"><b>Enter your new password</b></label>
                </div>
                <div class="row">
                  <div class="col-md-12"></div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group signin-icons mt-3">
                      <label
                        data-toggle="tooltip"
                        data-placement="top"
                        title=""
                        class="container customcheck mart-10"
                      >
                        I agree to all the terms and conditions
                        <input
                          type="checkbox"
                          v-model="termsconditionChecked"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span
                        v-if="!termsconditionCheckedValid"
                        class="alert-danger"
                        >Please agree to terms and conditions</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="signupt-btn-hold">
                <button
                  class="primary-btn ripple signupt-btn"
                  @click="ResetPassword()"
                >
                  Submit
                </button>

              </div> -->

              <div>
                <a
                  href="#"
                  class="btn-primary md-ink-ripple signin-btn"
                  :class="{
                    'global-disabled': submit_button,
                  }"
                  @click="ResetPassword()"
                  >Submit</a
                >
              </div>
            </form>
          </div>
          <!-- <div class="signup-image"><div class="image-holder"></div></div> -->
        </div>
      </div>
    </div>
    <div v-if="showloader">
      <div
        class="processing_popup upload full-spinner"
        style="visibility: visible"
      >
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api";
import { fas, faSmileWink } from "@fortawesome/free-solid-svg-icons";
export default {
  created() {
    this.Binddata();
  },
  components: {},
  mounted: {},
  watch: {
    pass: function (val, oldval) {
      debugger;
      var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      var numericformat = /[0-9]/;
      if (val.toUpperCase() != val) {
        this.hasLowerCaseChar = true;
      } else {
        this.hasLowerCaseChar = false;
      }
      if (val.toLowerCase() != val) {
        this.hasUpperCaseChar = true;
      } else {
        this.hasUpperCaseChar = false;
      }
      if (numericformat.test(val)) {
        this.hasNumberChar = true;
      } else {
        this.hasNumberChar = false;
      }
      if (format.test(val)) {
        this.hasSpecialChar = true;
      } else {
        this.hasSpecialChar = false;
      }
      if (val.length >= 8) {
        this.hasPassLength = true;
      } else {
        this.hasPassLength = false;
      }
      if (
        this.hasLowerCaseChar &&
        this.hasUpperCaseChar &&
        this.hasNumberChar &&
        this.hasSpecialChar &&
        this.hasPassLength
      ) {
        this.PasswrdPolicyValid = true;
      } else {
        this.PasswrdPolicyValid = false;
      }
      this.pswrdValid = true;
    },
    email: function (val, oldval) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (val && val.length > 0) {
        this.emailvalidation = re.test(val.toLowerCase());
      }
    },
  },
  updated: function () {
    if (this.pass && this.pass.length > 0) {
      if ($('[data-toggle="tooltip"]')) {
        $('[data-toggle="tooltip"]').tooltip({
          trigger: "hover",
        });
      }
    }
  },
  data() {
    return {
      submit_button: false,
      spinnerText: "Loading...",
      name: "",
      countrycode: "91",
      formattedCountryCode: "+" + this.countrycode,
      phoneAsUsername: "",
      email: "",
      pass: "",
      phone: "",
      error: null,
      show_errormessage: false,
      isaccountconfirm: false,
      alertpopupaction: "",
      parentproperty: this,
      PopupVisible_prop: false,

      //Validation Sign Up Form
      showFlag: true,
      hasPassLength: false,
      hasUpperCaseChar: false,
      hasLowerCaseChar: false,
      hasNumberChar: false,
      hasSpecialChar: false,
      PasswrdPolicyValid: true,
      cntryValid: true,
      pswrdValid: true,
      isphonempty: true,
      mobilevalidation: true,
      emailvalidation: true,
      termsconditionChecked: false,
      termsconditionCheckedValid: true,
      showPass: false,
      isAuthorize: false,
      tenantId: "",
      registeredCustomer: true,
      checkIfCognitoRequired: false,
      showHideFlag: false,
      makeDisable: false,

      Token: "",
      Email: "",
      Password: "",
      CnfPassword: "",
      showloader: false,
      Email_Error: "",
      Password_Error: "",
      CnfPassword_Error: "",
      MatchPassword_Error: "",
      token_error: "",
      Login_Page: false,
      Success_mssg: "",
    };
  },
  methods: {
    ShowPassword() {
      var type = document.getElementById("Password").getAttribute("type");
      if (type == "password") {
        document.getElementById("Password").setAttribute("type", "text");
        this.showHideFlag = true;
        var icontitle = document.getElementById("visibilityIconId");

        if (icontitle.getAttribute("data-original-title") == "Show") {
          document
            .getElementById("visibilityIconId")
            .setAttribute("data-original-title", "Hide");
        }
      } else {
        document.getElementById("Password").setAttribute("type", "password");
        this.showHideFlag = false;
        var icontitle = document.getElementById("visibilityIconId");
        if (icontitle.getAttribute("data-original-title") == "Hide") {
          document
            .getElementById("visibilityIconId")
            .setAttribute("data-original-title", "Show");
        }
      }
    },
    validateEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    showHidePasw() {
      //this.pass && this.pass.length > 0

      if (
        document.getElementById("pswdId") &&
        this.pass &&
        this.pass.length > 0
      ) {
        if (
          document.getElementById("pswdId").getAttribute("type") === "password"
        ) {
          document.getElementById("pswdId").setAttribute("type", "text");
          this.showFlag = false;
        } else {
          document.getElementById("pswdId").setAttribute("type", "password");
          this.showFlag = true;
        }
      }
    },
    Login() {
      this.$router.push({ path: "signin" });
    },

    Validation() {
      if (!this.PasswrdPolicyValid) {
        return false;
      }
      if (!this.termsconditionChecked) {
        this.termsconditionCheckedValid = false;
        return false;
      } else {
        this.termsconditionCheckedValid = true;
      }
      if (this.pass == "") {
        return false;
      }
      if (this.email == "") {
        return false;
      }
      if (this.token == "") {
        return false;
      }
      return true;
    },
    Binddata() {
      debugger;
      var self = this;
      self.token = this.$route.query.token;
      self.email = this.$route.query.email;
    },
    GetPackage() {
      debugger;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      Axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserPackage",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            var dataToStore = JSON.stringify(response.data);
            localStorage.setItem("UserPackageDetails", dataToStore);
            var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
            localStorage.setItem("accesstoken", uid.returnedToken);
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    ResetPassword() {
      debugger;
      var self = this;
      if (self.Validation()) {
        self.showloader = true;
        Axios({
          method: "POST",
          url: rest_service.baseURL + "/Authenticate/ResetPassword",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: {
            token: self.token,
            email: self.email,
            password: self.pass,
          },
        }).then(function (response) {
          debugger;
          if (response.data) {
            debugger;
            self.showloader = false;
            if (response.data.status == "200") {
              var dataToStore = JSON.stringify(response.data);
              self.Success_mssg = "Successfully changed password";
              self.submit_button = true;
              localStorage.setItem("accesstoken", dataToStore.returnedToken);
              localStorage.setItem("loggedinuserobj", dataToStore);
              self.GetPackage();
              self.$router.push({ path: "dashboard" });
            }
          } else {
            self.showloader = false;
            return null;
          }
        });
      } else {
      }
    },
  },
};
</script>
<style type="text/css">
.signin-pass-visible,
#Password:focus ~ .input-visible,
#Password:valid ~ .input-visible {
  display: block;
}
.disable-hold .container-comm input {
  background: #eaebed !important;
  pointer-events: none;
  cursor: default;
}

.disable-hold.signin-icons input {
  background: transparent !important;
  pointer-events: none;
  cursor: default;
}
.signin-icons {
  position: relative;
}

.signupt-user:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat -5px -5px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0px;
  top: 4px;
}

.singupt-password:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat -5px -57px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0px;
  top: 4px;
}

.signupt-mobile:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat -7px -200px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0px;
  top: 4px;
}

.signin-pass-visible,
.input-visible {
  display: none;
}

#Password:focus ~ .input-visible,
#Password:valid ~ .input-visible {
  display: block;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.eyehold {
  position: relative;
  top: -30px;
  float: right;
  width: 30px;
  height: 20px;
}

.signin-pass-hidden:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat 7px -151px;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 0px;
  top: 9px;
}

.signin-pass-visible:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat 2px -110px;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 0px;
  top: -5px;
}

.signupt-container input {
  display: block;
  width: 100%;
  background: #ffffff !important;
  height: 40px !important;
  border: none !important;
  border-bottom: solid 2px #d2d4d5 !important;
  padding-left: 30px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #1d1d1d !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.signupt-container input:focus,
.signupt-container input:valid {
  box-shadow: none;
  outline: none;
  border-color: #0056e0 !important;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.signupt-container input:focus ~ label,
.signupt-container input:valid ~ label {
  top: -15px;
  font-size: 13px;
  color: #0056e0;
  font-weight: lighter;
}

.signupt-container label {
  position: absolute;
  top: 8px;
  left: 30px;
  pointer-events: none;
  color: #606060;
  font-size: 14px;
  font-weight: lighter;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.signupt-container .material-icons {
  position: absolute;
  top: 5px;
  left: -2px;
  color: #bababa;
}

/*ERROR*/

.error-msg-hold input {
  display: block;
  width: 100% !important;
  background: #ffffff !important;
  height: 40px !important;
  border-color: #f3453a !important;
  padding-left: 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.signin-pt-hold .error-msg-hold input,
.signup-hold .error-msg-hold input {
  display: block;
  width: 100%;
  background: #ffffff !important;
  height: 45px !important;
  padding: 15px;
  border: none !important;
  border-bottom: solid 2px #f3453a !important;
  padding-left: 35px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #f3453a !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.signin-pt-hold .error-msg-hold label,
.signup-hold .error-msg-hold label {
  left: 35px;
}

.signin-pt-container .error-msg-hold input:focus ~ label,
.signin-pt-container .error-msg-hold input:valid ~ label,
.signup-hold .error-msg-hold input:focus ~ label,
.signup-hold .error-msg-hold input:valid ~ label {
  top: -15px;
  font-size: 13px !important;
  color: #f3453a;
  font-weight: lighter;
}

.error-msg-hold input:focus,
.error-msg-hold input:valid {
  box-shadow: none;
  outline: none;
  border-color: #f3453a;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.signin-pt-hold .error-msg-hold input:focus ~ label,
.signin-pt-hold .error-msg-hold input:valid ~ label,
.signup-hold .error-msg-hold input:focus ~ label,
.signup-hold .error-msg-hold input:valid ~ label {
  top: -10px;
  font-size: 12px;
  color: rgba(243, 69, 58, 1);
  font-weight: 400;
  background: #fff;
  left: 35px;
  padding: 0 5px;
}

.error-msg-hold input:focus ~ label,
.error-msg-hold input:valid ~ label {
  top: -10px;
  font-size: 12px;
  color: rgba(243, 69, 58, 1);
  font-weight: 400;
  background: #fff;
  left: 10px;
  padding: 0 5px;
}

.error-msg-hold label {
  position: absolute;
  top: 12px;
  left: 20px;
  pointer-events: none;
  color: rgba(243, 69, 58, 0.8);
  font-size: 14px;
  font-weight: 300 !important;
  font-weight: lighter;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.error-msg-hold .error {
  display: block;
  font-size: 12px;
  color: rgba(243, 69, 58, 0.8);
  font-weight: 500;
}

.error-msg-hold input:focus ~ span.error,
.error-msg-hold input:valid ~ span.error {
  display: block !important;
  font-size: 12px;
  color: rgba(243, 69, 58, 0.8);
  font-weight: 500;
  margin-top: 4px;
}

.error-msg-hold input:focus,
.error-msg-hold input:valid {
  border-color: rgba(243, 69, 58, 1) !important;
}

span.error {
  display: none;
}

.error-msg-hold span.error {
  color: rgba(243, 69, 58, 0.8);
  display: block !important;
}

.container.customcheck {
  float: left;
  width: auto;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: all;
  left: 0;
  color: #0056e0;
  font-weight: 500;
}

.container.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff !important;
  border-radius: 3px !important;
  border: solid 1px #c4c4c4;
}

.container.customcheck:hover input ~ .checkmark {
  background-color: #ffffff;
  border: solid 1px #c4c4c4;
}

.container.customcheck input:checked ~ .checkmark {
  background-color: #0056e0 !important;
  border: none !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container.customcheck input:checked ~ .checkmark:after {
  display: block;
}

.container.customcheck .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.signupt-hold {
  max-width: 475px;
  margin: 0;
  padding: 60px 80px;
}
.password-field ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.signin-pass-visible {
  display: block !important;
}
.password-field {
  margin-top: 15px;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.password-field p {
  text-align: left;
  color: #111111;
  font-weight: 500;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-top: 10px;
  position: relative;
}

.password-field p:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat -40px -2px;
  width: 35px;
  height: 30px;
  position: absolute;
  display: block;
  left: -15px;
  top: -5px;
}

.password-field ul li {
  font-weight: 500;
  color: #353b47;
  text-align: left;
  padding-left: 25px;
  position: relative;
  padding-top: 8px;
}

.password-field ul li::after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat -54px -93px;
  width: 35px;
  height: 30px;
  position: absolute;
  display: block;
  left: -1px;
  top: 2px;
}

.password-field ul li.password-valid {
  color: #0056e0;
}

.password-field ul li.password-valid::after {
  background-position: -54px -30px;
}

.password-field ul li.password-current {
  color: #111111;
}

.password-field ul li.password-current::after {
  background-position: -54px -59px;
}

.password-field ul li.password-invalid {
  color: #f44539;
}

.password-field ul li.password-invalid::after {
  background-position: -54px -125px;
}
.image-holder {
  background: url(../../assets/signup-img.jpg) no-repeat;
  width: 100%;
  background-size: cover;
  height: 100%;
}
.signup-hold {
  border: solid 1px #dddddd;
  max-width: 890px;
  margin: 30px auto 0 auto;
  position: relative;
  border-radius: 5px;
}

.signup-image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  min-height: 100%;
  bottom: 0;
  width: 50%;
}
.signup-hold {
  border: solid 1px #dddddd;
  max-width: 890px;
  margin: 30px auto 0 auto;
  position: relative;
  border-radius: 5px;
}
.signupt-container {
  margin-top: 140px;
  font-family: "Montserrat", sans-serif;
}
.signupt-container {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.signupt-main {
  width: 100%;
  height: 100vh;
  display: table;
  min-height: 100%;
}
/* .form-control {
  display: block;
  width: 200px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border-color: #01070e;
} */
</style>