<template>
  <div>
    <!--Start-->
    <Header :heading="'Credentials'"></Header>
    <div class="main-section">
      <Leftmenu></Leftmenu>
      <div class="right-section">
        <div class="section">
          <div class="border-radius-box account-details">
            <div class="account-details-inner">
              <dl v-if="name!='Profile'">
                <dt>Account Name</dt>
                <dd>RadhaKrishan</dd>
              </dl>
              <dl>
                <dt>Account Email</dt>
                <dd>{{email}}</dd>
              </dl>
              <dl>
                <dt>Password</dt>
                <dd>xxxxxxxxxx</dd>
              </dl>

              <a href="/changepassowrd" class="btn btn-primary"
                >Change Password</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Header from "@/components/Header.vue";
import Leftmenu from "@/components/Leftmenu.vue";

export default {
  components: {
    Header,
    Leftmenu,
  },
  data() {
    return {
      name:"",
      email: "",
      showPassword: false,
      password: "",
    };
  },
  created(){
    this.getdetails();
  },
  methods: {
    getdetails(){
      this.name=localStorage.getItem("fullName");
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      this.email = uid.user.email;
    },
  },
  mounted() {},
  name: "Security",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
