<template>
  <div id="app">
    <div id="nav">
      
    </div>
    <router-view/>
  </div>
</template>
 <script>
  
 var timeout;
 export default {
    components:{
    },
   data(){
     return{
      appConfig: {
        refreshIdle: () => {
          //if(Auth.checkAuthentication()){
            var self = this;
            var ts = Math.round(new Date().getTime() / 1000);
            localStorage.setItem("lastaccessdatetime", ts);
            clearTimeout(timeout);
            let logouttime = process.env.VUE_APP_AUTO_LOGOUT_TIME;
            if (window.location.pathname !== "/" || window.location.pathname !== "/signin" || window.location.pathname !== "/signout") {
              timeout = setTimeout(() => {
                //Log out the user if he is not in sign in or sign out page
                //console.log("IN APP.vue------refreshIdle()-->",this.$store.state.helper.autologout_time * 60 * 1000," ---" ,process.env.VUE_APP_SESSION_TIME_OUT);
               // self.$store.state.helper.isSessionExpired = true;
                window.location.href = "/signout";
              }, parseInt(logouttime) * 60 * 1000);
            }
          //}
        } 
      },
      isdisplaymsg:false,
      sucessmsgtext:"",
      iserrormsg:false,
      
     };
     },
     methods:{

         listenToEventsBeforeAutoLogOut() {
      document.addEventListener("click", this.appConfig.refreshIdle);
      document.addEventListener("load", this.appConfig.refreshIdle);
      document.addEventListener("scroll", this.appConfig.refreshIdle);
      document.addEventListener("keydown", this.appConfig.refreshIdle);
      document.addEventListener("keyup", this.appConfig.refreshIdle);
      document.addEventListener("contextmenu", this.appConfig.refreshIdle);
      document.addEventListener("dblclick", this.appConfig.refreshIdle);
      document.addEventListener("mouseenter", this.appConfig.refreshIdle);
      document.addEventListener("mouseleave", this.appConfig.refreshIdle);
      document.addEventListener("mousemove", this.appConfig.refreshIdle);
      document.addEventListener("mouseover", this.appConfig.refreshIdle);
      document.addEventListener("mouseout", this.appConfig.refreshIdle);
      document.addEventListener("select", this.appConfig.refreshIdle);
      document.addEventListener("wheel", this.appConfig.refreshIdle);
      document.addEventListener("drag", this.appConfig.refreshIdle);
      document.addEventListener("drop", this.appConfig.refreshIdle);
      document.addEventListener('error', this.appConfig.refreshIdle);
      document.addEventListener('online', this.appConfig.refreshIdle);
      document.addEventListener('fullscreenchange1', this.appConfig.refreshIdle);
    },
     },
  mounted() {
    //console.log("AUTO LOG OUT TIME>>>>>",1 * 60000);
    // this.appConfig.refreshIdle();
    // this.listenToEventsBeforeAutoLogOut();
    //For internet connectivity
    if (window.addEventListener) {
      /*
          Works well in Firefox and Opera with the 
          Work Offline option in the File menu.
          Pulling the ethernet cable doesn't seem to trigger it.
          Later Google Chrome and Safari seem to trigger it well
      */
      window.addEventListener("online", this.isOnline, false);
      window.addEventListener("offline", this.isOffline, false);
    }
    else {
      /*
        Works in IE with the Work Offline option in the 
        File menu and pulling the ethernet cable
      */
      document.body.ononline = isOnline;
      document.body.onoffline = isOffline;
    }
  },
  methods:{
    isOnline : function () {
      this.sucessmsgtext = "You are back online.";
      this.isdisplaymsg = true;
      this.iserrormsg=false;
      setTimeout((xy)=>{this.closeMessage();},3000);
      //console.log("YOU ARE ONLINE!!!");
    },
    isOffline: function () {
      this.sucessmsgtext = "It looks like you are offline.";
      this.isdisplaymsg = true;
      this.iserrormsg=true;
      //setTimeout((xy)=>{this.closeMessage();},this.$store.state.helper.msgDisplayTime);
      //console.log("YOU ARE OFFLINE!!!");
    },
    closeMessage(){
      this.isdisplaymsg=false;
    }
  }
 };
         $(function () {
      $('[data-toggle="tooltip"]').tooltip({
   container: 'body'
  });
      $('[data-toggle="tooltip"]').click(function () {
        $(".tooltip").fadeOut("fast", function () {
          $(".tooltip").remove();
        });
      });
      
    });
    </script>
<style>
#app {
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
