<template>
  <div>
    <!--Start-->
    <Header :heading="'Payment History'"></Header>
    <div class="main-section">
      <Leftmenu></Leftmenu>
      <div class="right-section">
        <div v-if="ActivePackages.length>0" class="section">
          <h3>Live Package(s)</h3>
          <div class="border-radius-box table-section">
            <div class="inner-sec">
              <table>
                <tr>
                  <th>S.No</th>
                  <th>Package Name</th>
                  <th>Purchase Date</th>
                  <th>Amount Paid</th>
                  <th>Payment Mode</th>
                </tr>
                <tr v-for="item in ActivePackages">
                  <td>{{ item.sno }}</td>
                  <td>{{ item.packageName }}</td>
                  <td>{{ formattedDateTime(new Date(item.purchasedDate)) }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.paymentMode }}</td>
                </tr>
              </table>
            </div>
          </div>

          
        </div>
        <div class="text-right mt-4">
            <a href="/CreateCv" class="btn btn-primary">Create New CV</a>
            <button @click.prevent="ChangePlan" class="btn-primary md-ink-ripple ml-3">
              Buy Package
            </button>
          </div>
        <div v-if="ExpiredPackages.length>0" class="section">
          <h3>Expired Package(s)</h3>
          <div class="border-radius-box table-section">
            <div class="inner-sec">
              <table>
                <tr>
                  <th>S.No</th>
                  <th>Package Name</th>
                  <th>Purchase Date</th>
                  <th>Amount Paid</th>
                  <th>Payment Mode</th>
                </tr>
                <tr v-for="item in ExpiredPackages">
                  <td>{{ item.sno }}</td>
                  <td>{{ item.packageName }}</td>
                  <td>{{ formattedDateTime(new Date(item.purchasedDate)) }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.paymentMode }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loader" class="template-loader-hiw hide">
      <div class="loader-overlay"></div>
      <div class="progress-bar-wrapper">
        <img
          src="../../assets/check-circle.svg"
          v-if="finished"
          alt="Check Circle"
          width="48"
          height="48"
          class="icon-check-circle hide"
        />
        <p class="progress-text step1">{{ loadermsg }}</p>
        <div v-if="!finished" class="progress-bar">
          <div class="progress-linear"></div>
          <div class="progress-gradient progress-animate"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Header from "@/components/Header.vue";
import Leftmenu from "@/components/Leftmenu.vue";
import rest_service from "@/api/rest_service_api.js";
import moment from "moment";

export default {
  components: {
    Header,
    Leftmenu,
  },
  created() {
    this.GetActivePackage();
  },
  data() {
    return {
      ActivePackages: null,
      ExpiredPackages: null,
      loader: false,
      finished: false,
      loadermsg: "",
    };
  },
  methods: {
    ChangePlan() {
      var self = this;
      this.$router.push({ path: "ChangePlan" });
    },
    formattedDateTime(date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // Use 12-hour format with AM/PM
      };
      return date.toLocaleDateString("en-GB", options); // Adjust the locale as needed
    },
    GetActivePackage() {
      debugger;
      var self = this;
      self.loader = true;
      self.loadermsg = "Loading";
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      Axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetPaymentHistory",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            var result = response.data.result;
            var activepackages = [];
            var activeno = 1;
            var ExpiredPackages = [];
            var Expiredno = 1;
            result.forEach((element) => {
              if (
                moment(new Date()) > element.purchasedDate ||
                element.isExpired != true
              ) {
                element.sno = activeno;
                activepackages.push(element);
                activeno = activeno + 1;
              } else {
                element.sno = Expiredno;
                ExpiredPackages.push(element);
                Expiredno = Expiredno + 1;
              }
            });
            self.loader = false;
            self.finished = true;
            self.loadermsg = "";
            self.ActivePackages = activepackages;
            self.ExpiredPackages = ExpiredPackages;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
          self.loader = false;
          self.finished = false;
          self.loadermsg = "";
          return error;
        });
    },
  },
  mounted() {},
  name: "PaymentHistory",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
