<template>
<div>
  {{showClosePopUp}}
  <div v-if="showmodal">
    <transition name="modal">
      <div class="modal-mask upl-holdNEW wdth-700" style="overflow-y: auto; display: block;">
        <div class="modal-wrapper">
          <div class="modal-container">
            <!--Modal header-->
            <div class="modal-header">
              <slot name="header" style="border:1px red;">
                <!-- <h5 class="modal-title">File List</h5> -->
                <h4 class="modal-title mt-2"></h4>
                <button
                  type="button"
                  @click.prevent="closePopUp('goBack')"
                  aria-label="Close"
                  class="close up-close"
                >×</button>
              </slot>
            </div>
            <!--Modal Body-->
            <div class="modal-body">
              <div id="resumesec">
                <body style="margin:0; padding:0;">
                  <div
                    class="main-container"
                    style="display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Montserrat, sans-serif;"
                  >
                    <div
                      class="left-container blue-bg"
                      style="width: 30%;color: #fff; background: #062f75;"
                    >
                      <div class="img-box" style="padding: 20px 20px 0;">
                          <img
                                          v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                                          style="max-width: 100%;width: 100%;object-fit: cover;"
                                          :src="resume.pic"
                                          alt="Smiley face"
                                        >
                        <img v-else
                          style="max-width: 100%;width: 100%;object-fit: cover;"
                          src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                          alt
                        >
                      </div>
                      <div class="" style="padding: 20px;">
                        <h1 style="font-size: 36px; margin:0;">{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
                        <span style="display: block;opacity: 0.8;margin: 5px 0 0;">{{resume.profile.profession}}</span>
                      </div>
                      <div
                        class="heading-strip darkblue-bg"
                        style="padding: 10px 20px;background: #05265e;"
                      >
                        <h4 style="font-size: 20px;margin:0;">Contact</h4>
                      </div>
                      <div class="resume-block" style="padding: 20px;">
                        <p style="margin:0;">
                          <b style="font-weight: 600;">Email:</b> {{resume.profile.email}}
                        </p>
                      </div>
                      <div
                        class="heading-strip darkblue-bg"
                        style="padding: 10px 20px;background: #05265e;"
                        v-if="resume.skills"
                      >
                        <h4 style="font-size: 20px;margin:0;">Skills</h4>
                      </div>
                      <div v-if="resume.skills" class="resume-block" style="padding: 20px;">
                        <ul v-html="resume.skills" style="list-style: disc;padding-left: 15px; margin:0;">
                          
                        </ul>
                      </div>
                    </div>

                    <div class="right-container" style="width: 70%;padding: 20px;">
                      <p class="big-text" v-html="resume.summary" style="font-size: 18px;line-height: 1.3;margin:0;" v-if="resume.summary">
                        
                      </p>
                      <div
                        v-if="resume.workExperience"
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                      >
                        <h3
                          style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;"
                        >Work History</h3>
                      </div>
                      <div class="resume-box" v-if="resume.workExperience">
                        <div v-for="(item,index) in resume.workExperience" style="display: flex;margin-bottom: 25px;">
                          <span
                            style="font-weight: 600;display: block;margin: 5px 30px 0 0;"
                          >{{item.startdate}} - {{item.enddate}}</span>
                          <div>
                            <h2
                              style="font-size: 26px;font-weight: 600;margin: 0 0 5px;"
                            >{{item.jobtitle}}</h2>
                            <span
                              style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;"
                            >{{item.employer}} {{item.city +" " + item.country}}</span>
                            <ul style="list-style: disc;padding-left: 15px; margin:0;" v-html="item.description">
                              
                            </ul>
                          </div>
                        </div>

                      </div>

                      <div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.education"
                      >
                        <h3
                          style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;"
                        >Education</h3>
                      </div>
                      <div class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;" v-for="(item,index) in resume.education">
                          <span
                            style="font-weight: 600;display: block;margin: 5px 30px 0 0;"
                          >{{item.grad_startdate }} - {{item.grad_enddate}}</span>
                          <div>
                            <h2 style="font-size: 26px;font-weight: 600;margin: 0 0 5px;">{{item.degree +" "+item.field }}</h2>
                            <span
                              style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;"
                            >{{ item.school_name }} - {{ item.school_location }}</span>
                            <ul style="list-style: disc;padding-left: 15px; margin:0;" v-html="item.description">
                              
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.accomplishments_text!='None'"
                      >
                        <h3
                          style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;"
                        >Accomplishments</h3>
                      </div>
                      <div class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <ul style="list-style: disc;padding-left: 15px; margin:0;" v-html="resume.extraFields.accomplishments_text">
                            
                          </ul>
                        </div>
                      </div>

                      <div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.affiliations_text!='None'"
                      >
                        <h3 style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;">Affiliation</h3>
                      </div>
                      <div v-if="resume.extraFields.affiliations_text!='None'" class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <p v-html="resume.extraFields.affiliations_text" style="margin:0;"></p>
                        </div>
                      </div>
                      <div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.additional_information_text!='None'"
                      >
                        <h3 style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;">Additional Information</h3>
                      </div>
                      <div v-if="resume.extraFields.additional_information_text!='None'" class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <p v-html="resume.extraFields.additional_information_text" style="margin:0;"></p>
                        </div>
                      </div>
                      <div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.software_text!='None'"
                      >
                        <h3 style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;">Software</h3>
                      </div>
                      <div v-if="resume.extraFields.software_text!='None'" class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <p v-html="resume.extraFields.software_text" style="margin:0;"></p>
                        </div>
                      </div>
                      <div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.languages_text!='None'"
                      >
                        <h3 style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;">Language</h3>
                      </div>
                      <div v-if="resume.extraFields.languages_text!='None'" class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <p v-html="resume.extraFields.languages_text" style="margin:0;"></p>
                        </div>
                      </div><div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.certifications_text!='None'"
                      >
                        <h3 style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;">Certification</h3>
                      </div>
                      <div v-if="resume.extraFields.certifications_text!='None'" class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <p v-html="resume.extraFields.certifications_text" style="margin:0;"></p>
                        </div>
                      </div><div
                        class="heading"
                        style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
                        v-if="resume.extraFields.interests_text!='None'"
                      >
                        <h3 style="font-size: 22px;font-weight: 600;color: #0e57d5;margin:0;">Interests</h3>
                      </div>
                      <div v-if="resume.extraFields.interests_text!='None'" class="resume-box">
                        <div style="display: flex;margin-bottom: 25px;">
                          <p v-html="resume.extraFields.interests_text" style="margin:0;"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </body>
              </div>
              <div v-if="final">
                <div style="text-align: right" class="signin-btn-hold">
                  <a href="#" class="primary-btn ripple signin-btn" @click.prevent="saveResume">Save</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :iserror="iserrormsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {
    showClosePopUp() {
      var self = this;
      self.showmodal = self.showlist;
    }
  },
  methods: {
    closePopUp: function(item) {
      if (item === "goBack") {
        if (this.parentObj.$data.showResumetwo) {
          this.parentObj.$data.showResumetwo = false;
        }
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.Skills),
        Summary: JSON.stringify(self.resume.Summary),
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cvfilename: self.resume.profile.firstname+"_"+self.resume.profile.surname+"_"
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      // let formData = new FormData();
      // const pdfBlob = await html2pdf().from(pdfContent).outputPdf();
      // const blobPartArray = await new Response(pdfBlob).arrayBuffer(); // Convert Blob to BlobPart array

      // const formData = new FormData();
      // formData.append('file', new Blob([blobPartArray]), 'output.pdf');

      // formData.append('pdfFile', pdfBlob, 'output.pdf');
      axios({
        method: "post",
          url: rest_service.baseURL + Url,
       // url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            self.closePopUp("goBack");
            self.$router.push({
              path: "/dashboard"
            });
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
      //const pdfBuffer =  pdfBlob.arrayBuffer();
      //   const s3 = new AWS.S3({
      //   accessKeyId: 'AKIA5BLF7Z4HNIYBATMF',
      //   secretAccessKey: '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',
      //   region: 'us-east-1',
      // });

      // // Define S3 upload parameters
      // const params = {
      //   Bucket: 'documents-gen-ehr-dev',
      //   Key: 'shri@mailinator.com/document.pdf', // Desired name for the uploaded file
      //   Body: pdfBlob,
      //   ContentType: 'application/pdf',
      //   ACL: 'private',
      // };

      // // Upload to S3
      // s3.upload(params, (error, data) => {
      //   if (error) {
      //     console.error('Error uploading to S3:', error);
      //   } else {
      //     console.log('PDF uploaded to S3:', data.Location);
      //   }
      // });
      //  html2pdf(document.getElementById("resumesec"), {
      // 		margin: 1,
      // 	filename: "i-was-html.pdf",
      // 	});
    }
  }
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease-in-out;
}
/* .modal-wrapper {
  display: table-cell;
  vertical-align: middle;
} */
.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 50px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: opacity 0.5s ease-in-out;
}
.modal-mask.upl-holdNEW.wdth-700 .modal-container {
  width: 700px;
}

.modal-mask:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

.modal-wrapper {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

