<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <a href="/Dashboard">
            <img src="/img/CC-Logo-White.1144d714.svg" alt />
          </a>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        v-if="currentstep == 'selecttemplatesection'"
        class="cover-letter-sec"
      >
        <div class="heading-box">
          <h3>Let’s start with your template</h3>
        </div>
        <div class="row mt-5">
          <div class="col-md-3">
            <div
              class="form-group placeholder-wrap bold-Your First Name is-valid"
            >
              <label class="control-label" for="fName">
                First Name
                <span></span>
              </label>
              <input
                type="text"
                width="25%"
                v-model="First_Name"
                name="Your First Name"
                placeholder="e.g. John"
                maxlength="35"
                class="form-control"
                id="fName"
                aria-label="First Name"
                aria-required="false"
                value
              />
            </div>
            <div
              class="form-group placeholder-wrap bold-Your Last Name is-valid"
            >
              <label class="control-label" for="lName">
                Last Name
                <span></span>
              </label>
              <input
                v-model="Last_Name"
                width="25%"
                type="text"
                name="Your Last Name"
                placeholder="e.g. Smith"
                maxlength="35"
                class="form-control"
                id="lName"
                aria-label="Last Name"
                aria-required="false"
                value
              />
            </div>

            <div
              class="form-group placeholder-wrap bold-Your Last Name is-valid"
            >
              <button class="btn-primary md-ink-ripple" @click="Dashboard()">
                Back
              </button>
            </div>
          </div>
          <div class="col-md-9">
            <div class="template-sec">
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_1
                    :resume="resumeobj"
                    ref="Template1"
                  ></CoverLetter_1>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_1')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_2
                    :resume="resumeobj"
                    ref="Template2"
                  ></CoverLetter_2>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_2')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_3
                    :resume="resumeobj"
                    ref="Template3"
                  ></CoverLetter_3>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_3')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_4
                    :resume="resumeobj"
                    ref="Template4"
                  ></CoverLetter_4>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_4')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_5
                    :resume="resumeobj"
                    ref="Template5"
                  ></CoverLetter_5>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_5')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_6
                    :resume="resumeobj"
                    ref="Template6"
                  ></CoverLetter_6>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_6')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_7
                    :resume="resumeobj"
                    ref="Template7"
                  ></CoverLetter_7>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_7')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_8
                    :resume="resumeobj"
                    ref="Template8"
                  ></CoverLetter_8>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_8')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_9
                    :resume="resumeobj"
                    ref="Template9"
                  ></CoverLetter_9>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_9')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div class="template">
                <div class="inner-coverletter">
                  <CoverLetter_10
                    :resume="resumeobj"
                    ref="Template10"
                  ></CoverLetter_10>
                  <button
                    class="btn-select-template"
                    @click="Selecttemplate('CoverLetter_10')"
                  >
                    Select Template
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentstep == 'selectresume'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>How do you want to start your cover letter?</h3>
        </div>
        <div style="text-align: center" class="row">
          <div class="col-md-6">
            <a class="resume-block" @click="Isjdallowed('New')">
              <span>
                <i class="material-icons">add</i>
              </span>
              <h4>Create a New Letter</h4>
              <p class="option-text">We'll start from the beginning</p>
            </a>
          </div>
          <div class="col-md-6">
            <a class="resume-block" @click="Isjdallowed('Exists')">
              <span>
                <i class="material-icons">upload</i>
              </span>
              <h4>Use Your Resume/Cover letter</h4>
              <p class="option-text">
                We’ll import the information from your built resume
              </p>
            </a>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('selecttemplatesection')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('specificjobsection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'Existingcoverletter'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>How do you want to start your cover letter?</h3>
        </div>

        <!-- <div style="text-align: center">
          <label for="file-5">
            <a class="resume-block">
              <h4>Upload My Resume</h4>
              <i class="material-icons">upload</i>

              <input
                type="file"
                name="file-5"
                id="file-5"
                @change="validateResumeFile"
                class="inputfile inputfile-4 primary-btn"
              />
              <br />
              <strong v-if="filename != ''">{{ filename }}</strong>
              <p class="option-text">
                Acceptable file types: DOC, DOCX, PDF, HTM, RTF, TXT
              </p>
            </a>
          </label>
        </div>-->

        <a class="resume-block" @click="SelectExistsoption('Existsfiles')">
          <span>
            <i class="material-icons">upload</i>
          </span>
          <h4>Select Resume or Cover Letter or Upload</h4>
          <p class="option-text">
            We’ll import the information from your built cover letter
          </p>
          <strong v-if="filename != ''">{{ filename }}</strong>
        </a>

        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('selectresume')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('reviewsection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'specificjobsection'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>Do you have a specific job in mind?</h3>

          <h4>
            It’s ok if you don’t. We’ll give you a letter you can customize
            later.
          </h4>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-center">
              <button
                :class="{ selected: specific_job === 'Yes' }"
                class="button-boxspe"
                @click="specificjob('Yes')"
              >
                Yes
              </button>
              <button
                :class="{ selected: specific_job === 'NO' }"
                class="button-boxspe"
                @click="specificjob('NO')"
              >
                Not Yet
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('selectresume')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('postionsection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'postionsection'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>What job are you applying for?</h3>
        </div>
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <div
              class="form-group placeholder-wrap bold-Your First Name is-valid"
            >
              <label class="control-label" for="fName">
                Desired Position
                <span></span>
              </label>
              <div>
                <Multiselect
                  width="100%"
                  v-model="Postion"
                  :options="jobtitlelist"
                  :multiple="false"
                  :taggable="true"
                  tag-placeholder="Enter"
                  @tag="onTag($event)"
                ></Multiselect>

                <div
                  class="form-group placeholder-wrap bold-Your mt-4 Last Name is-valid"
                >
                  <label class="control-label" for="lName">
                    Desired Company
                    <span></span>
                  </label>
                  <input
                    v-model="Company"
                    width="25%"
                    type="text"
                    name="ACME Technolgies"
                    placeholder="e.g. ACME Technolgies"
                    class="form-control"
                    aria-label="Company"
                    aria-required="false"
                    value
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('specificjobsection')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('experiencesection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'experiencesection'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>How many years of experience do you have?*</h3>
          <h5>
            You can include internships, unofficial jobs or volunteer work.
          </h5>
        </div>
        <div class="custom-radio-block">
          <div class="custom-radio">
            <input
              type="radio"
              id="Lessthanone"
              v-model="selectedexp"
              name="selectedexp"
              value="Less than one"
            />
            <label for="Lessthanone">Less than one</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="1"
              v-model="selectedexp"
              name="selectedexp"
              value="1"
            />
            <label for="1">1</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="2"
              v-model="selectedexp"
              name="selectedexp"
              value="2"
            />
            <label for="2">2</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="3"
              v-model="selectedexp"
              name="selectedexp"
              value="3"
            />
            <label for="3">3</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="4"
              v-model="selectedexp"
              name="selectedexp"
              value="4"
            />
            <label for="4">4</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="5"
              v-model="selectedexp"
              name="selectedexp"
              value="5"
            />
            <label for="5">5</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="6"
              v-model="selectedexp"
              name="selectedexp"
              value="6"
            />
            <label for="6">6</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="7"
              v-model="selectedexp"
              name="selectedexp"
              value="7"
            />
            <label for="7">7</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="8"
              v-model="selectedexp"
              name="selectedexp"
              value="8"
            />
            <label for="8">8</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="9"
              v-model="selectedexp"
              name="selectedexp"
              value="9"
            />
            <label for="9">9</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              id="10"
              v-model="selectedexp"
              name="selectedexp"
              value="10"
            />
            <label for="10">10+</label>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('postionsection')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('currentjobtitle')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'currentjobtitle'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>What’s your most recent job title?</h3>
          <h5>Include your current employment or last job.</h5>
        </div>
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <Multiselect
              v-model="Job_Title"
              :options="jobtitlelist"
              :multiple="false"
              :taggable="true"
              tag-placeholder="Enter"
              @tag="Jobtitletag($event)"
            ></Multiselect>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('experiencesection')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('strengthsection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'strengthsection'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>Choose your top 3 strengths.</h3>
          <h5>
            We’ll highlight these in your cover letter to help match your
            strengths to the desired position.
          </h5>
        </div>
        <div
          v-if="selectedstrengths.length > 0"
          class="d-flex flex-wrap justify-content-center"
        >
          <ul class="active" v-for="strength in selectedstrengths">
            <li class="Skill-list" @click="UnSelect(item)">
              {{ strength }}
              <i class="material-icons">done</i>
            </li>
          </ul>
        </div>

        <div class="mt-5 d-flex flex-wrap justify-content-center">
          <button
            v-for="(item, index) in strengths"
            :key="index"
            class="Skill-list"
            :class="{ selected: isSelected(item) }"
            @click="togglestrength(item)"
          >
            {{ item }}
            <i class="material-icons">add</i>
          </button>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('currentjobtitle')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('skillsection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'skillsection'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>Choose your top 3 skills.</h3>
          <h5>
            We’ll highlight these in your cover letter to help match your skills
            to the desired position.
          </h5>
        </div>
        <div
          v-if="selectedskills.length > 0"
          class="d-flex flex-wrap justify-content-center"
        >
          <ul class="active" v-for="skill in selectedskills">
            <li class="Skill-list" @click="UnSelectskill(item)">
              {{ skill }}
              <i class="material-icons">done</i>
            </li>
          </ul>
        </div>

        <div class="mt-5 d-flex flex-wrap justify-content-center">
          <button
            v-for="(item, index) in skills"
            :key="index"
            class="Skill-list"
            :class="{ selected: isSelectedskill(item) }"
            @click="toggleskill(item)"
          >
            {{ item }}
            <i class="material-icons">add</i>
          </button>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('strengthsection')"
          >
            Back
          </button>
          <button
            v-if="jdaccess"
            class="btn-primary md-ink-ripple"
            @click="Next('jobdecsection')"
          >
            Continue
          </button>
          <button
            v-else
            class="btn-primary md-ink-ripple"
            @click="Next('workingstylesection')"
          >
            Continue
          </button>
        </div>
      </div>
      <!-- <div
        v-if="currentstep == 'jobdescconfirmsection'"
        class="cover-letter-sec"
      >
        <div class="heading-box">
          <h3>Do have job description?</h3>
          <h5>This helps us personalize the tone of your letter.</h5>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-center">
                <button
                  :class="{ selected: specific_job_Desc === 'Yes' }"
                  class="button-boxspe"
                  @click="specificjobdesc('Yes')"
                >
                  Yes
                </button>
                <button
                  :class="{ selected: specific_job_Desc === 'No' }"
                  class="button-boxspe"
                  @click="specificjobdesc('No')"
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-between">
            <button
              class="btn-primary md-ink-ripple"
              @click="Back('strengthsection')"
            >
              Back
            </button>
            <button
              class="btn-primary md-ink-ripple"
              @click="Next('jobdecsection')"
            >
              Continue
            </button>
          </div>
        </div>
      </div>-->
      <div
        v-if="currentstep == 'jobdecsection' && jdaccess"
        class="cover-letter-sec"
      >
        <div class="heading-box">
          <h3>Enter your job description</h3>
        </div>
        <!-- <div style="align-items: center">
          <textarea
            id="w3review"
            v-model="Job_Desc"
            name="w3review"
            rows="8"
            cols="80"
          ></textarea>
        </div>-->

        <div class="jd-job-desc enter-jd-job">
          <div class="job-desc">
            <textarea
              id="w3review"
              v-model="Job_Desc"
              name="w3review"
              rows="8"
              cols="80"
            ></textarea>
          </div>
        </div>

        <div class="mt-5 d-flex justify-content-between">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('skillsection')"
          >
            Back
          </button>
          <button
            class="btn-primary md-ink-ripple"
            @click="Next('workingstylesection')"
          >
            Continue
          </button>
        </div>
      </div>
      <div v-if="currentstep == 'workingstylesection'" class="cover-letter-sec">
        <div class="heading-box">
          <h3>What’s your working style?</h3>
          <h5>This helps us personalize the tone of your letter.</h5>
        </div>
        <div class="row">
          <div class="col-md-8 offset-md-2 mt-5">
            <div class="row">
              <div class="col-md-4">
                <button
                  :class="{ selected: selected_workstyle === 'Artistic' }"
                  class="button-box"
                  @click="specificworkingstyle('Artistic')"
                >
                  <b>Artistic</b>
                  You thrive in dynamic environments driven by innovation and
                  creativity.
                </button>
              </div>
              <div class="col-md-4">
                <button
                  :class="{ selected: selected_workstyle === 'Enterprising' }"
                  class="button-box"
                  @click="specificworkingstyle('Enterprising')"
                >
                  <b>Enterprising</b>
                  You’re accustomed to leading teams with empowering and
                  decisive task delegation.
                </button>
              </div>
              <div class="col-md-4">
                <button
                  :class="{ selected: selected_workstyle === 'Investigative' }"
                  class="button-box"
                  @click="specificworkingstyle('Investigative')"
                >
                  <b>Investigative</b>
                  You bring a resourceful approach with a knack for
                  problem-solving.
                </button>
              </div>
              <div class="col-md-4">
                <button
                  :class="{ selected: selected_workstyle === 'Organized' }"
                  class="button-box"
                  @click="specificworkingstyle('Organized')"
                >
                  <b>Organized</b>
                  You bring structure and focus to streamline tasks.
                </button>
              </div>
              <div class="col-md-4">
                <button
                  :class="{ selected: selected_workstyle === 'Practical' }"
                  class="button-box"
                  @click="specificworkingstyle('Practical')"
                >
                  <b>Practical</b>
                  You go above and beyond to meet goals and ensure timely task
                  completion..
                </button>
              </div>
              <div class="col-md-4">
                <button
                  :class="{
                    selected: selected_workstyle === 'Service-Oriented',
                  }"
                  class="button-box"
                  @click="specificworkingstyle('Service-Oriented')"
                >
                  <b>Service-Oriented</b>
                  You excel in collaborative situations and enjoy helping
                  others.
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <button
            class="btn-primary md-ink-ripple"
            @click="Back('strengthsection')"
          >
            Back
          </button>
          <button class="btn-primary md-ink-ripple" @click="Coverletterfinal()">
            Continue
          </button>
        </div>
      </div>
    </div>
    <div v-if="currentstep == 'reviewsection'">
      <Header :heading="'Manage CV'"></Header>
      <div class="main-section">
        <Leftmenu></Leftmenu>
        <div class="right-section">
          <div class="review-section">
            <div class="row">
              <div class="col-lg-8">
                <div>
                  <input type="hidden" value="1" id="hiddenreviewsection" />
                </div>
                <div class="review-inner">
                  <div class="review-inner-block">
                    <h3>SKILLS AND STRENGTHS</h3>
                    <div class="review-inner-box">
                      <h5>
                        Desired Position & Company
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <div class="heading-box">
                          <h3>What job are you applying for?</h3>
                        </div>
                        <div class="row">
                          <div class="col-md-6 offset-md-3">
                            <div
                              class="form-group placeholder-wrap bold-Your First Name is-valid"
                            >
                              <label class="control-label" for="fName">
                                Desired Position
                                <span></span>
                              </label>
                              <div>
                                <Multiselect
                                  width="100%"
                                  v-model="Postion"
                                  :options="jobtitlelist"
                                  :multiple="false"
                                  :taggable="true"
                                  tag-placeholder="Enter"
                                  @tag="onTag($event)"
                                ></Multiselect>

                                <div
                                  class="form-group placeholder-wrap bold-Your mt-4 Last Name is-valid"
                                >
                                  <label class="control-label" for="lName">
                                    Desired Company
                                    <span></span>
                                  </label>
                                  <input
                                    v-model="Company"
                                    width="25%"
                                    type="text"
                                    name="ACME Technolgies"
                                    placeholder="e.g. ACME Technolgies"
                                    class="form-control"
                                    aria-label="Company"
                                    aria-required="false"
                                    value
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('specificjobreview')"
              >
                Save
              </button>
                  </div>-->
                    </div>

                    <div class="review-inner-box">
                      <h5>
                        Top Strengths
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <div class="heading-box">
                          <h3>Choose your top 3 strengths.</h3>
                          <h6>
                            We’ll highlight these in your cover letter to help
                            match your strengths to the desired position.
                          </h6>
                        </div>
                        <div
                          v-if="selectedstrengths.length > 0"
                          class="d-flex flex-wrap justify-content-center"
                        >
                          <ul
                            class="active"
                            v-for="strength in selectedstrengths"
                          >
                            <li class="Skill-list" @click="UnSelect(item)">
                              {{ strength }}
                              <i class="material-icons">done</i>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="mt-5 d-flex flex-wrap justify-content-center"
                        >
                          <button
                            v-for="(item, index) in strengths"
                            :key="index"
                            class="Skill-list"
                            :class="{ selected: isSelected(item) }"
                            @click="togglestrength(item)"
                          >
                            {{ item }}
                            <i class="material-icons">add</i>
                          </button>
                        </div>
                      </div>

                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('strengthsreview')"
              >
                Save
              </button>
                  </div>-->
                    </div>
                    <div class="review-inner-box">
                      <h5>
                        Skills
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <div class="heading-box">
                          <h3>Choose your skills</h3>
                          <h6>
                            We’ll highlight these in your cover letter to help
                            match your skills.
                          </h6>
                        </div>
                        <div
                          v-if="selectedskills.length > 0"
                          class="d-flex flex-wrap justify-content-center"
                        >
                          <ul class="active" v-for="skill in selectedskills">
                            <li
                              class="Skill-list"
                              @click="UnSelectskill(skill)"
                            >
                              {{ skill }}
                              <i class="material-icons">done</i>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="mt-5 d-flex flex-wrap justify-content-center"
                        >
                          <button
                            v-for="(item, index) in skills"
                            :key="index"
                            class="Skill-list"
                            :class="{ selected: isSelectedskill(item) }"
                            @click="toggleskillforrevirew(item)"
                          >
                            {{ item }}
                            <i class="material-icons">add</i>
                          </button>
                        </div>
                      </div>

                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('strengthsreview')"
              >
                Save
              </button>
                  </div>-->
                    </div>
                  </div>

                  <div class="review-inner-block">
                    <h3>EXPERIENCE</h3>
                    <div class="review-inner-box">
                      <h5>
                        Years of Experience
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <div class="heading-box">
                          <h3>How many years of experience do you have?*</h3>
                          <h6>
                            You can include internships, unofficial jobs or
                            volunteer work.
                          </h6>
                        </div>
                        <div class="custom-radio-block">
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="Lessthanone"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="Less than one"
                            />
                            <label for="Lessthanone">Less than one</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="1"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="1"
                            />
                            <label for="1">1</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="2"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="2"
                            />
                            <label for="2">2</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="3"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="3"
                            />
                            <label for="3">3</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="4"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="4"
                            />
                            <label for="4">4</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="5"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="5"
                            />
                            <label for="5">5</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="6"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="6"
                            />
                            <label for="6">6</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="7"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="7"
                            />
                            <label for="7">7</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="8"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="8"
                            />
                            <label for="8">8</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="9"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="9"
                            />
                            <label for="9">9</label>
                          </div>
                          <div class="custom-radio">
                            <input
                              type="radio"
                              id="10"
                              v-model="selectedexp"
                              name="selectedexp"
                              value="10"
                            />
                            <label for="10">10+</label>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('Experiencereview')"
              >
                Save
              </button>
                  </div>-->
                    </div>
                    <div class="review-inner-box">
                      <h5>
                        Previous Job Title?
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <div class="heading-box">
                          <h3>What’s your most recent job title?</h3>
                          <h6>Include your current employment or last job.</h6>
                        </div>
                        <div class="row">
                          <div class="col-md-5 offset-md-4">
                            <Multiselect
                              v-model="Job_Title"
                              :options="jobtitlelist"
                              :multiple="false"
                              :taggable="true"
                              tag-placeholder="Enter"
                              @tag="Jobtitletag($event)"
                            ></Multiselect>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('Recentjobreview')"
              >
                Save
              </button>
                  </div>-->
                    </div>
                    <!-- <div class="review-inner-box">
            <h5>Are you in school?</h5>
            <div class="review-middle-sec">
              <div class="heading-box">
                <h1>Test</h1>
              </div>
            </div>
            <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button class="btn-primary md-ink-ripple">Save</button>
            </div>
                </div>-->
                    <!-- <div class="review-inner-box">
            <h5>Did you graduate from college?</h5>
            <div lass="review-inner-box">
              <div class="review-middle-sec">
                <div class="heading-box">
                  <h1>Test</h1>
                </div>
              </div>
              <div class="review-btn-sec">
                <button class="btn-primary md-ink-ripple">Cancel</button>
                <button class="btn-primary md-ink-ripple">Save</button>
              </div>
            </div>
                </div>-->
                  </div>

                  <div
                    class="review-inner-block"
                    ref="workingstylereviewsection"
                  >
                    <h3>PERSONALIZATION</h3>
                    <div class="review-inner-box">
                      <h5>
                        Working Style
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <div class="heading-box">
                          <h3>What’s your working style?</h3>
                          <h6>
                            This helps us personalize the tone of your letter.
                          </h6>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <button
                                  :class="{
                                    selected: selected_workstyle === 'Artistic',
                                  }"
                                  class="button-box"
                                  @click="specificworkingstyle('Artistic')"
                                >
                                  <b>Artistic</b>
                                  You thrive in dynamic environments driven by
                                  innovation and creativity.
                                </button>
                              </div>
                              <div class="col-md-4">
                                <button
                                  :class="{
                                    selected:
                                      selected_workstyle === 'Enterprising',
                                  }"
                                  class="button-box"
                                  @click="specificworkingstyle('Enterprising')"
                                >
                                  <b>Enterprising</b>
                                  You’re accustomed to leading teams with
                                  empowering and decisive task delegation.
                                </button>
                              </div>
                              <div class="col-md-4">
                                <button
                                  :class="{
                                    selected:
                                      selected_workstyle === 'Investigative',
                                  }"
                                  class="button-box"
                                  @click="specificworkingstyle('Investigative')"
                                >
                                  <b>Investigative</b>
                                  You bring a resourceful approach with a knack
                                  for problem-solving.
                                </button>
                              </div>
                              <div class="col-md-4">
                                <button
                                  :class="{
                                    selected:
                                      selected_workstyle === 'Organized',
                                  }"
                                  class="button-box"
                                  @click="specificworkingstyle('Organized')"
                                >
                                  <b>Organized</b>
                                  You bring structure and focus to streamline
                                  tasks.
                                </button>
                              </div>
                              <div class="col-md-4">
                                <button
                                  :class="{
                                    selected:
                                      selected_workstyle === 'Practical',
                                  }"
                                  class="button-box"
                                  @click="specificworkingstyle('Practical')"
                                >
                                  <b>Practical</b>
                                  You go above and beyond to meet goals and
                                  ensure timely task completion..
                                </button>
                              </div>
                              <div class="col-md-4">
                                <button
                                  :class="{
                                    selected:
                                      selected_workstyle === 'Service-Oriented',
                                  }"
                                  class="button-box"
                                  @click="
                                    specificworkingstyle('Service-Oriented')
                                  "
                                >
                                  <b>Service-Oriented</b>
                                  You excel in collaborative situations and
                                  enjoy helping others.
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('Workingstylereview')"
              >
                Save
              </button>
                  </div>-->
                    </div>
                  </div>
                  <div v-if="jdaccess" class="review-inner-block">
                    <h3>Job Description</h3>
                    <div class="review-inner-box">
                      <h5>
                        Job Description
                        <i class="fa fa-angle-down"></i>
                      </h5>
                      <div class="review-middle-sec">
                        <!-- <div class="heading-box">
                <h3>Enter your job Description</h3>
                    </div>-->
                        <!-- <div style="align-items: center">
                <textarea
                  id="w3review"
                  v-model="Job_Desc"
                  name="w3review"
                  rows="8"
                  cols="80"
                ></textarea>
                    </div>-->

                        <div class="jd-job-desc enter-jd-job">
                          <div class="job-desc">
                            <h4>Enter your job Description</h4>
                            <textarea
                              id="w3review"
                              v-model="Job_Desc"
                              name="w3review"
                              rows="8"
                              cols="80"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="review-btn-sec">
              <button class="btn-primary md-ink-ripple">Cancel</button>
              <button
                class="btn-primary md-ink-ripple"
                @click="SaveReview('Experiencereview')"
              >
                Save
              </button>
                  </div>-->
                    </div>
                  </div>
                </div>
                <div class="review-bottom">
                  <div class="review-top-inner">
                    <button
                      class="btn-primary md-ink-ripple"
                      @click="FinalReview()"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="review-top">
                  <div class="review-top-inner">
                    <div>
                      <h2>Let's review your answers</h2>
                      <h5>
                        You can fill in missing information or edit your
                        previous answers.
                      </h5>
                    </div>
                    <button
                      class="btn-primary md-ink-ripple"
                      @click="FinalReview()"
                    >
                      Skip & Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="Existsfiles"
      role="dialog"
      tabindex="-1"
      class="modal fade"
      aria-modal="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" data-dismiss="modal" class="close">×</button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-8">
                <div class="ExistingBox">
                  <h3>
                    Select Existing Resume
                    <i class="material-icons">keyboard_arrow_down</i>
                  </h3>
                  <div class="Existing-resume">
                    <div class="filesview-container">
                      <div v-for="item in cvlist" class="filesview">
                        <div
                          :class="{
                            selected_item: selected_exisfile === item.uniqueId,
                          }"
                          class="template_samples"
                          @click="Selectfilelist(item.uniqueId, 'CV')"
                        >
                          <pdf
                            :src="item.pdffile"
                            :page="1"
                            :rotation="0"
                            :show-all="false"
                            :autoresize="true"
                          ></pdf>
                        </div>
                        <div class="thumb-letter-details">
                          <h5 class="thumb-letter-name">
                            {{ item.cvFileName }}
                          </h5>
                          <p class="thumb-letter-date" title>
                            Modified:
                            {{ formattedDateTime(new Date(item.createdDate)) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ExistingBox">
                  <h3>
                    Select Existing Cover Letter
                    <i class="material-icons">keyboard_arrow_down</i>
                  </h3>

                  <div class="Existing-resume">
                    <div class="filesview-container">
                      <div v-for="item in coverletterlist" class="filesview">
                        <div
                          :class="{
                            selected_item: selected_exisfile === item.uniqueId,
                          }"
                          class="template_samples"
                          @click="Selectfilelist(item.uniqueId, 'Coverletter')"
                        >
                          <pdf
                            :src="item.pdfurl"
                            :page="1"
                            :rotation="0"
                            :show-all="false"
                            :autoresize="true"
                          ></pdf>
                        </div>
                        <div class="thumb-letter-details">
                          <h5 class="thumb-letter-name">
                            {{ item.pdfFileName }}
                          </h5>
                          <p class="thumb-letter-date" title>
                            Modified:
                            {{ formattedDateTime(new Date(item.updatedDate)) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="cover-letter-sec">
                  <div style="text-align: center">
                    <label for="file-5">
                      <a class="resume-block">
                        <img src="../../assets/upload-icon.png" alt />
                        <h4>Upload My Resume/Cover letter</h4>
                        <input
                          type="file"
                          name="file-5"
                          id="file-5"
                          @change="validateResumeFile"
                          class="inputfile inputfile-4 primary-btn"
                        />

                        <strong v-if="filenamepopup != ''">
                          {{ filenamepopup }}
                        </strong>
                        <p class="option-text">
                          Acceptable file types: DOC, DOCX, PDF, HTM, RTF, TXT
                        </p>
                      </a>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-3">
              <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                Cancel
              </button>
              <button
                class="btn-primary md-ink-ripple"
                @click="Selectfile(selected_exisfile)"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showloader">
      <div
        class="processing_popup upload full-spinner"
        style="visibility: visible"
      >
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
    <div v-if="loader" class="template-loader-hiw hide">
      <div class="loader-overlay"></div>
      <div class="progress-bar-wrapper">
        <img
          src="../../assets/check-circle.svg"
          v-if="finished"
          alt="Check Circle"
          width="48"
          height="48"
          class="icon-check-circle hide"
        />
        <p class="progress-text step1">{{ loadermsg }}</p>
        <div v-if="!finished" class="progress-bar">
          <div class="progress-linear"></div>
          <div class="progress-gradient progress-animate"></div>
        </div>
      </div>
    </div>
    <ConfirmBox
      ref="confirmcomp"
      :showmodal="showCnfrmPopUp"
      :actionmessage="actionMsg"
      @cancelAction="NO()"
      @confirmAction="Delete()"
    ></ConfirmBox>
  </div>
</template>
<script>
import Axios from "axios";
import pdf from "vue-pdf";
import rest_service from "@/api/rest_service_api.js";
import CoverLetter_1 from "@/components/CoverLetterTemplates/CoverLetter_1.vue";
import CoverLetter_2 from "@/components/CoverLetterTemplates/CoverLetter_2.vue";
import CoverLetter_3 from "@/components/CoverLetterTemplates/CoverLetter_3.vue";
import CoverLetter_4 from "@/components/CoverLetterTemplates/CoverLetter_4.vue";
import CoverLetter_5 from "@/components/CoverLetterTemplates/CoverLetter_5.vue";
import CoverLetter_6 from "@/components/CoverLetterTemplates/CoverLetter_6.vue";
import CoverLetter_7 from "@/components/CoverLetterTemplates/CoverLetter_7.vue";
import CoverLetter_8 from "@/components/CoverLetterTemplates/CoverLetter_8.vue";
import CoverLetter_9 from "@/components/CoverLetterTemplates/CoverLetter_9.vue";
import CoverLetter_10 from "@/components/CoverLetterTemplates/CoverLetter_10.vue";
import Multiselect from "vue-multiselect";
import Autocomplete from "v-autocomplete";
import moment from "moment";
import Header from "@/components/Header.vue";
import Leftmenu from "@/components/Leftmenu.vue";

import ConfirmBox from "@/components/MessagePopup/ConfirmPopup";
export default {
  components: {
    Header,
    Leftmenu,
    pdf,
    Multiselect,
    CoverLetter_1,
    CoverLetter_2,
    CoverLetter_3,
    CoverLetter_4,
    CoverLetter_5,
    CoverLetter_6,
    CoverLetter_7,
    CoverLetter_8,
    CoverLetter_9,
    CoverLetter_10,
    Autocomplete,
    ConfirmBox,
  },
  created() {
    if (localStorage.getItem("UserPackageDetails")) {
      var pckinfo = [];
      pckinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
      var access = false;
      pckinfo.forEach((t) => {
        if (t.coverLetter) {
          if (parseInt(t.noCoverLetter) > parseInt(t.usedNoCoverLetter)) {
            access = true;
            return;
          }
        }
      });
      if (access == false) {
        this.$router.push({ path: "ChangePlan" });
      }
    } else {
      this.$router.push({ path: "ChangePlan" });
    }
    if (localStorage.getItem("UserPackageDetails")) {
      var pckinfo = [];
      pckinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
      pckinfo.forEach((t) => {
        if (t.coverLetter) {
          if (
            parseInt(t.maxNoCoverLetterGenerate) >
            parseInt(t.usedNoCoverLetterGenerate)
          ) {
            this.jdaccess = true;
            this.remainingCoverLetterGenerate =
              parseInt(t.maxNoCoverLetterGenerate) -
              parseInt(t.usedNoCoverLetterGenerate);
            return;
          }
        }
      });
    }

    if (localStorage.getItem("local_currentstep")) {
      var currentinfo = JSON.parse(localStorage.getItem("local_currentstep"));
      this.currentstep = currentinfo.currentstep;
    }
    if (localStorage.getItem("resumeobj")) {
      var currentinfo = JSON.parse(localStorage.getItem("resumeobj"));
    }
    this.GetJobTitle();
  },
  updated() {
    $(document).ready(function () {
      var count = $("#hiddenreviewsection").val();
      if (count == "1") {
        $(".review-inner-box h5").click(function () {
          $(this).siblings(".review-middle-sec").toggle();
        });

        $("#hiddenreviewsection").val("2");
      }

      $(".ExistingBox h3").click(function () {
        $(this).siblings(".Existing-resume").toggle();
      });
    });
  },
  computed: {
    //  filteredSuggestions() {
    // debugger;
    // var self=this;
    //   return self.suggestions.filter(suggestion =>
    //     suggestion.toLowerCase().includes(self.Postion.toLowerCase())
    //   );
    // },
  },
  data() {
    return {
      selected_exisfile: "",
      boxes: ["Box 1", "Box 2", "Box 3", "Box 4"],
      numPages: undefined,
      showexistingfiles: false,
      Job_Desc: "",
      existcoverlettr: false,
      showloader: false,
      Job_Title: "",
      Experience: ["Less than one", "1", "2"],
      selectedexp: "",
      combinedValue: "",
      filteredSuggestions: [],
      selectedItem: "",
      suggestions: ["Apple", "Banana", "Cherry", "Date"],
      inputValue: "",
      showSuggestions: false,
      suggestions: [],
      jobtitlelist: [],
      strengths: [],
      skills: [],
      resumeobj: { User_Profile: {}, Recipient_Details: {} },
      Postion: "",
      Company: "",
      selectedButton: null,
      specific_job: "Yes",
      specific_job_Desc: "No",
      First_Name: "",
      Last_Name: "",
      currentstep: "selecttemplatesection",
      local_currentstep: null,
      step0: true,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      options: ["Option 1", "Option 2", "Option 3"],
      sele: [],
      selectedSkills: [],
      selectedstrengths: [],
      selectedskills: [],
      selected_workstyle: "",
      ResumeTemplate: "",
      filename: "",
      filenamepopup: "",
      loader: false,
      finished: false,
      loadermsg: "",
      coverletterlist: [],
      cvlist: [],
      SelectedCv: [],
      SelectedCoverletter: [],
      filetype: "",
      jdaccess: false,
      remainingCoverLetterGenerate: "",
      actionMsg: "",
      delete_what: "",
      showCnfrmPopUp: false,
    };
  },
  watch: {
    Postion(val) {
      debugger;
      var self = this;
      self.filteredSuggestions = self.suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(self.Postion.toLowerCase())
      );
    },
    First_Name(value) {
      this.ResumeBind();
    },
    Last_Name(value) {
      this.ResumeBind();
    },
  },
  comments: {},
  methods: {
    tryParseDate(dateString, format) {
      try {
        // Split the date string to handle different formats
        const dateParts = dateString.split("/");
        var formattedDate;
        if (format == "DD/MM/YYYY") {
          formattedDate =
            dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
        } else if (format == "MMM YYYY") {
          formattedDate = "01" + "/" + dateParts[0] + "/" + dateParts[1];
        }
        let parsedDate = new Date(formattedDate);
        let options = {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        };
        return parsedDate.toLocaleDateString("en-IN", options);
      } catch (error) {
        return null;
      }
    },
    calculateYearsDifference(startDate, endDate) {
      var startdateformat1;
      var startdateformat2;
      var enddateformat1;
      var enddateformat2;
      startdateformat1 = this.tryParseDate(startDate, "DD/MM/YYYY");
      if (
        startdateformat1 == null ||
        startdateformat1 == undefined ||
        startdateformat1 == "Invalid Date"
      ) {
        startdateformat2 = this.tryParseDate(startDate, "MMM YYYY");
      }
      if (endDate == "Current" || endDate == "Present") {
        enddateformat1 = moment().format("DD/MM/YYYY");
      } else {
        enddateformat1 = this.tryParseDate(endDate, "DD/MM/YYYY");
        if (
          enddateformat1 == null ||
          enddateformat1 == undefined ||
          enddateformat1 == "Invalid Date"
        ) {
          enddateformat2 = this.tryParseDate(endDate, "MMM YYYY");
        }
      }
      var start;
      var end;
      if (
        startdateformat1 != null &&
        startdateformat1 != undefined &&
        startdateformat1 != "Invalid Date"
      ) {
        const dateParts = startdateformat1.split("/");
        var formattedDate =
          dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
        start = new Date(formattedDate);
      } else if (
        startdateformat2 != null &&
        startdateformat2 != undefined &&
        startdateformat2 != "Invalid Date"
      ) {
        const dateParts = startdateformat2.split("/");
        var formattedDate =
          dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
        start = new Date(formattedDate);
      }
      if (
        enddateformat1 != null &&
        enddateformat1 != undefined &&
        enddateformat1 != "Invalid Date"
      ) {
        const dateParts = enddateformat1.split("/");
        var formattedDate =
          dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
        end = new Date(formattedDate);
      } else if (
        enddateformat2 != null &&
        enddateformat2 != undefined &&
        enddateformat2 != "Invalid Date"
      ) {
        const dateParts = enddateformat2.split("/");
        var formattedDate =
          dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
        end = new Date(formattedDate);
      }
      const timeDiff = Math.abs(end - start);
      return timeDiff;
    },
    Delete() {
      this.showCnfrmPopUp = false;
      if (this.delete_what == "New") {
        this.SelectResume(this.delete_what);
      }
      if (this.delete_what == "Exists") {
        this.SelectResume(this.delete_what);
      }
    },
    NO() {
      this.showCnfrmPopUp = false;
      this.$router.push({
        path: "/dashboard",
      });
    },
    Isjdallowed(item) {
      var self = this;
      if (self.jdaccess) {
        self.actionMsg =
          "Do you want to use 1 credit out of remaining " +
          self.remainingCoverLetterGenerate +
          " credit(s)?";
        self.delete_what = item;
        self.showCnfrmPopUp = true;
      } else {
        self.actionMsg =
          "You have exhausted all credits. You will not be able to personalize your cover letter. Do you want to proceed?";
        self.delete_what = item;
        self.showCnfrmPopUp = true;
      }
    },
    Selectfile(item) {
      var self = this;
      if (self.filetype == "CV") {
        if (self.cvlist.length > 0) {
          self.cvlist.forEach((element) => {
            if (element.uniqueId == item) {
              self.filename = element.cvFileName;
              self.SelectedCv = element;
            }
          });
        }
      }
      if (self.filetype == "Coverletter") {
        if (self.coverletterlist.length > 0) {
          self.coverletterlist.forEach((element) => {
            if (element.uniqueId == item) {
              self.filename = element.pdfFileName;
              self.SelectedCoverletter = element;
            }
          });
        }
      }
      if (self.filetype == "Local") {
        self.filename = self.filenamepopup;
      }
      $("#Existsfiles").modal("hide");
    },
    formattedDateTime(date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",

        // hour12: true, // Use 12-hour format with AM/PM
      };
      return date.toLocaleDateString("en-GB", options); // Adjust the locale as needed
    },
    Selectfilelist(item, type) {
      this.filenamepopup = "";
      this.filetype = type;
      this.selected_exisfile = item;
    },
    GetCvlist() {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      self.loadermsg = "Loading";
      self.loader = true;
      self.finished = false;
      Axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/CV/GetCVwithfiles?userid=" +
          uid.user.uniqueId,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
      })
        .then(function (response) {
          if (response.data.result.length > 0) {
            debugger;
            self.cvlist = response.data.result;
          } else {
            // return null;
          }
          setTimeout((xy) => {
            self.loadermsg = "Loaded";
            self.finished = true;
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
          }, 5000);
        })
        .catch(function (error) {
          console.log(error);
          setTimeout((xy) => {
            self.closeloader();
          }, 3000);
          return error;
        });
    },
    GetCoversletters() {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      Axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/CoverLetter/GetCoverLetterswithfiles?userid=" +
          uid.user.uniqueId,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
      })
        .then(function (response) {
          if (response.data.result.length > 0) {
            debugger;
            self.coverletterlist = response.data.result;
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    SelectExistsoption() {
      if (this.coverletterlist.length > 0) {
      } else {
        this.GetCoversletters();
      }
      if (this.cvlist.length > 0) {
      } else {
        this.GetCvlist();
      }

      $("#Existsfiles").modal("show");
    },
    FinalReview() {
      var self = this;
      if (self.selected_workstyle == "") {
        // const targetDiv = this.$refs.workingstylereviewsection;
        // targetDiv.scrollIntoView({ behavior: "smooth" });
        // return false;
        self.selected_workstyle = "Organized";
      }
      self.resumeobj.specificjob = self.specific_job;
      self.resumeobj.Postion = self.Postion;
      self.resumeobj.Company = self.Company;
      self.resumeobj.strengths = self.selectedstrengths;
      self.resumeobj.Experience = self.selectedexp;
      self.resumeobj.Job_Title = self.Job_Title;
      self.resumeobj.Workingstyle = self.selected_workstyle;
      self.resumeobj.workingstylebinded = false;
      self.resumeobj.coverletterdate = moment().format("MMMM DD, YYYY");
      self.resumeobj.skills = self.selectedskills;

      // self.resumeobj.jobdescription = self.Job_Desc;
      self.resumeobj.inputjobdescription = self.Job_Desc;
      self.resumeobj.Workingstyle = self.selected_workstyle;
      self.resumeobj.workingstylebinded = false;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.$router.push({ path: "CoverLetterFinal" });
    },
    SaveReview(val) {
      var self = this;
      if ((val = "specificjobreview")) {
        self.resumeobj.specificjob = self.specific_job;
        self.resumeobj.Postion = self.Postion;
        self.resumeobj.Company = self.Company;
      }
      if ((val = "strengthsreview")) {
        self.resumeobj.strengths = self.selectedstrengths;
      }
      if ((val = "Experiencereview")) {
        self.resumeobj.Experience = self.selectedexp;
      }
      if ((val = "Recentjobreview")) {
        self.resumeobj.Job_Title = self.Job_Title;
      }
      if ((val = "Workingstylereview")) {
        self.resumeobj.Workingstyle = self.selected_workstyle;
        self.resumeobj.workingstylebinded = false;
      }
      self.resumeobj.coverletterdate = moment().format("MMMM DD, YYYY");
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
    },
    closeloader() {
      var self = this;
      self.loader = false;
      self.finished = false;
    },
    validateResumeFile() {
      debugger;
      var fileName = document.getElementById("file-5").value;
      var tempFile = document.getElementById("file-5").files[0].name;
      var dot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(dot, fileName.length).toLowerCase();
      var tempFiles1 = document.getElementById("file-5").files;
      if (!/\.(doc|pdf|rtf|txt|docx|htm|html)$/i.test(fileName)) {
        $("#file-5").val("");
        this.filenamepopup = "";
        return prevent();
      } else {
        this.filetype = "Local";
        this.selected_exisfile = "";
        this.filenamepopup = tempFile;
      }
    },
    Coverletterfinal() {
      var self = this;
      if (self.currentstep == "workingstylesection") {
        self.resumeobj.Workingstyle = self.selected_workstyle;
      }
      self.resumeobj.coverletterdate = moment().format("MMMM DD, YYYY");
      self.resumeobj.workingstylebinded = false;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.$router.push({ path: "CoverLetterFinal" });
    },
    UnSelect(item) {
      if (this.selectedstrengths.length > 0) {
        const index = this.selectedstrengths.indexOf(item);
        this.selectedstrengths.splice(index, 1);
        // this.selectedstrengths.forEach(element => {
        //   debugger;
        //   if(item==element)
        //   {
        //     this.selectedstrengths
        //   }
        //   var test="test";

        // });
      }
      return;
    },
    UnSelectskill(item) {
      debugger;
      if (this.selectedskills.length > 0) {
        const index = this.selectedskills.indexOf(item);
        this.selectedskills.splice(index, 1);
      }
      return;
    },
    GetSkillsv2(job) {
      var self = this;
      self.skills = [];
      Axios({
        method: "get",
        url: rest_service.PythonAPI + "/search_skills",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          job_title: job,
        },
      })
        .then(function (response) {
          if (response.data) {
            response.data.forEach((e) => {
              self.skills.push(e);
            });
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    GetSkills() {
      var self = this;
      self.skills = [];
      Axios({
        method: "get",
        url: rest_service.PythonAPI + "/search_skills",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          job_title: self.Job_Title,
        },
      })
        .then(function (response) {
          if (response.data) {
            response.data.forEach((e) => {
              self.skills.push(e);
            });
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    GetStrengths() {
      var self = this;
      self.showloader = true;
      self.strengths = [];
      Axios({
        method: "get",
        url: rest_service.PythonAPI + "/search_strengths",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          job_title: self.Job_Title,
        },
      })
        .then(function (response) {
          if (response.data) {
            self.showloader = false;
            response.data.forEach((e) => {
              self.strengths.push(e);
            });
          } else {
            self.showloader = false;
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          self.showloader = false;
          return error;
        });
    },
    GetStrengthsV2(job) {
      var self = this;
      self.strengths = [];
      Axios({
        method: "get",
        url: rest_service.PythonAPI + "/search_strengths",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          job_title: job,
        },
      })
        .then(function (response) {
          if (response.data) {
            response.data.forEach((e) => {
              self.strengths.push(e);
            });
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    Jobtitletag(new_tag) {
      this.Job_Title = new_tag;
    },
    onTag(new_tag) {
      this.Postion = new_tag;
    },
    onSelect(item) {
      this.selectedItem = item;
    },
    selectSuggestion(suggestion) {
      this.inputValue = suggestion;
      this.Postion = suggestion;
      this.showSuggestions = false;
    },
    GetJobTitle() {
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      var self = this;
      Axios({
        method: "get",
        url: rest_service.baseURL + "/CV/GetAllJobtitle",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
      })
        .then(function (response) {
          if (response.data.result) {
            response.data.result.forEach((e) => {
              self.jobtitlelist.push(e.title);
            });
            self.suggestions = self.jobtitlelist;
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);

          return error;
        });
    },
    isSelected(option) {
      return this.selectedstrengths.includes(option);
    },
    isSelectedskill(option) {
      return this.selectedskills.includes(option);
    },

    toggleskill(item) {
      if (this.isSelectedskill(item)) {
        const index = this.selectedskills.indexOf(item);
        this.selectedskills.splice(index, 1);
      } else {
        if (this.selectedskills.length < 3) {
          this.selectedskills.push(item);
        }
      }
    },
    toggleskillforrevirew(item) {
      if (this.isSelectedskill(item)) {
        const index = this.selectedskills.indexOf(item);
        this.selectedskills.splice(index, 1);
      } else {
        this.selectedskills.push(item);
      }
    },
    togglestrength(item) {
      if (this.isSelected(item)) {
        const index = this.selectedstrengths.indexOf(item);
        this.selectedstrengths.splice(index, 1);
      } else {
        if (this.selectedstrengths.length < 3) {
          this.selectedstrengths.push(item);
        }
      }
    },
    ResumeBind() {
      var self = this;
      self.resumeobj.First_Name = self.First_Name;
      self.resumeobj.Last_Name = self.Last_Name;
      self.resumeobj.User_Profile.First_Name = self.First_Name;
      self.resumeobj.User_Profile.Last_Name = self.Last_Name;
      self.$refs.Template1.Bindfortemplates(self.resumeobj);
      self.$refs.Template2.Bindfortemplates(self.resumeobj);
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
    },
    specificjob(val) {
      var self = this;
      self.specific_job = val;
    },
    specificjobdesc(val) {
      var self = this;
      self.specific_job_Desc = val;
    },
    specificworkingstyle(val) {
      var self = this;
      self.selected_workstyle = val;
    },
    GetExtractExistsResume() {
      var self = this;
      var tempFiles1 = document.getElementById("file-5").files[0];
      self.loadermsg = "Analyzing resume";
      self.loader = true;
      self.finished = false;
      const formData = new FormData();
      formData.append("file", tempFiles1);
      Axios({
        method: "post",
        url: rest_service.PythonAPI + "/analyze_coverletter_api",
        headers: {
          Accept: "multipart/form-data",
        },
        data: formData,
      })
        .then(function (response) {
          if (response.data) {
            var extractinfo = response.data;
            self.resumeobj.Job_Title = extractinfo.desired_position;
            self.Job_Title = extractinfo.desired_position;
            debugger;
            if(extractinfo.work_experience!=null && extractinfo.work_experience!="")
            {
              var afterround = Math.round(extractinfo.work_experience);
              if(afterround!=null &&  afterround!="")
              {
                self.selectedexp=afterround.toString();
                self.resumeobj.experience=afterround.toString();
              }
            }
            if (extractinfo.skills != null && extractinfo.skills != "") {
              var skillstemp = extractinfo.skills.split(", ");
              skillstemp.forEach((element) => {
                self.skills.push(element);
                if (self.isSelectedskill(element)) {
                  const index = self.selectedskills.indexOf(element);
                  self.selectedskills.splice(index, 1);
                } else {
                  self.selectedskills.push(element);
                }
              });
              if (self.selectedskills != null && self.selectedskills != "") {
                self.resumeobj.skills = self.selectedskills;
              }
            }
            if (
              self.Job_Title != "" &&
              (self.resumeobj.skills == null ||
                self.resumeobj.skills == "" ||
                self.resumeobj.skills == undefined)
            ) {
              self.GetSkills();
            } else if (
              self.resumeobj.skills == null ||
              self.resumeobj.skills == "" ||
              self.resumeobj.skills == undefined
            ) {
              self.GetSkillsv2("Profession");
            }
            if (extractinfo.strengths != null && extractinfo.strengths != "") {
              var strengthstemp = extractinfo.strengths.split(", ");
              strengthstemp.forEach((element) => {
                self.strengths.push(element);
                if (self.isSelected(element)) {
                  const index = self.selectedstrengths.indexOf(element);
                  self.selectedstrengths.splice(index, 1);
                } else {
                  self.selectedstrengths.push(element);
                }
              });
              if (
                self.selectedstrengths != null &&
                self.selectedstrengths != ""
              ) {
                self.resumeobj.strengths = self.selectedstrengths;
              }
            }
            if (
              self.Job_Title != "" &&
              (self.resumeobj.strengths == null ||
                self.resumeobj.strengths == "" ||
                self.resumeobj.strengths == undefined)
            ) {
              self.GetStrengthsV2(self.Job_Title);
            } else {
              self.GetStrengthsV2("Profession");
            }

            localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
            self.loadermsg = "Finished";
            self.finished = true;
            setTimeout((xy) => {
              self.closeloader();
            }, 3000);
            self.currentstep = "reviewsection";

            if (localStorage.getItem("local_currentstep")) {
              var currentinfo = JSON.parse(
                localStorage.getItem("local_currentstep")
              );
              currentinfo.currentstep = self.currentstep;
              localStorage.setItem(
                "local_currentstep",
                JSON.stringify(currentinfo)
              );
            } else {
              self.local_currentstep = {
                currentstep: self.currentstep,
              };
              localStorage.setItem(
                "local_currentstep",
                JSON.stringify(self.local_currentstep)
              );
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          self.loader = false;
          return error;
        });
    },
    Next(val) {
      debugger;
      var self = this;
      if (self.currentstep == "Existingcoverletter") {
        // self.GetExtractExistsResume();
        if (self.filetype == "CV") {
          var profile = JSON.parse(self.SelectedCv.profile);
          if (profile != null) {
            self.resumeobj.User_Profile.First_Name = profile.firstname;
            self.resumeobj.User_Profile.Last_Name = profile.surname;
            self.resumeobj.User_Profile.Profession = profile.profession;
            self.resumeobj.User_Profile.City = profile.city;
            //  self.resumeobj.User_Profile.State;
            self.resumeobj.User_Profile.Zip = profile.pincode;
            self.resumeobj.User_Profile.PhoneNumber = profile.phone;
            self.resumeobj.User_Profile.Emaill = profile.email;
            self.Job_Title = profile.profession;
            self.resumeobj.Job_Title = profile.profession;
          }
          var education = JSON.parse(self.SelectedCv.education);
          if (education != null) {
            var degree = "";
            education.forEach((item) => {
              degree = item.degree;
            });
            self.resumeobj.degree = degree;
          }
          debugger;
          var workExperience = JSON.parse(self.SelectedCv.workExperience);
          if (workExperience != "" && workExperience != null) {
            var Totalexp = 0;
            var totaltime = 0;
            workExperience.forEach((item) => {
              if (
                item.startdate != null &&
                item.startdate != "" &&
                item.enddate != null &&
                item.enddate != ""
              ) {
                var years = self.calculateYearsDifference(
                  item.startdate,
                  item.enddate
                );
                if (years != null && years != undefined) {
                  totaltime = totaltime + years;
                }
              }
            });
            Totalexp = totaltime / (1000 * 60 * 60 * 24 * 365.25);
            if (Totalexp != 0) {
              var afterround = Math.round(Totalexp);
              if (afterround != null) {
                if (afterround === 0) {
                  self.resumeobj.Experience = "Less than one";
                  self.selectedexp = "Less than one";
                } else {
                  self.resumeobj.Experience = afterround.toString();
                  self.selectedexp = afterround.toString();
                }
              }
            }
          }
          if (self.Job_Title != "") {
            this.GetStrengthsV2(self.Job_Title);
            this.GetSkills();
          } else {
            this.GetStrengthsV2("Profession");
            this.GetSkills();
          }
          if (self.SelectedCv.skills != "" && self.SelectedCv.skills != null) {
            var skillstemp = JSON.parse(self.SelectedCv.skills);
            var type = typeof skillstemp;
            if(type != "string"){
            skillstemp.forEach((element) => {
              self.skills.push(element.text);
              if (self.isSelectedskill(element.text)) {
                const index = self.selectedskills.indexOf(element.text);
                self.selectedskills.splice(index, 1);
              } else {
                self.selectedskills.push(element.text);
              }
            });
            }
            else{
              var tempskill = JSON.parse(skillstemp);
              tempskill.forEach((element) => {
              self.skills.push(element.text);
              if (self.isSelectedskill(element.text)) {
                const index = self.selectedskills.indexOf(element.text);
                self.selectedskills.splice(index, 1);
              } else {
                self.selectedskills.push(element.text);
              }
            });
            }
          }
        }
        if (self.filetype == "Coverletter") {
          var coverletter = self.SelectedCoverletter;
          if (coverletter != "") {
            self.resumeobj.User_Profile = JSON.parse(coverletter.userprofile);
            self.resumeobj.Recipient_Details = JSON.parse(
              coverletter.recipientDetails
            );
            self.resumeobj.Job_Title = coverletter.jobTitle;
            self.Job_Title = coverletter.jobTitle;
            self.resumeobj.working_style = coverletter.workingStyle;
            self.selected_workstyle = coverletter.workingStyle;
            self.resumeobj.subject = coverletter.subject;
            self.resumeobj.greeting = coverletter.greeting;
            self.resumeobj.opening = coverletter.opening;
            self.resumeobj.letterbody = coverletter.letterBody;
            self.resumeobj.calltoaction = coverletter.callToAction;
            self.resumeobj.closing = coverletter.closing;
            self.resumeobj.coverletterdate = coverletter.coverLetterDate;
            self.resumeobj.Experience = coverletter.experience;
            self.selectedexp = coverletter.experience;
            self.resumeobj.strengths = JSON.parse(coverletter.strengths);
            self.selectedstrengths = JSON.parse(coverletter.strengths);
            self.strengths = JSON.parse(coverletter.strengths);
            // self.skills = JSON.parse(coverletter.skills);
            // self.selectedSkills = JSON.parse(coverletter.skills);
            var skillstemp = JSON.parse(coverletter.skills);
            skillstemp.forEach((element) => {
              self.skills.push(element);
              if (self.isSelectedskill(element)) {
                const index = self.selectedskills.indexOf(element);
                self.selectedskills.splice(index, 1);
              } else {
                self.selectedskills.push(element);
              }
            });
            self.resumeobj.skills = JSON.parse(coverletter.skills);
            self.Job_Desc = coverletter.inputjobdescription;
            self.resumeobj.jobdescription = coverletter.jobdescription;
            self.resumeobj.inputjobdescription =
              coverletter.inputjobdescription;
          }
          self.resumeobj.User_Profile;
        }
        if (self.filetype == "Local") {
          self.GetExtractExistsResume();
          return true;
        }
        if (
          self.filetype == "" ||
          self.filetype == null ||
          self.filetype == undefined
        ) {
          return false;
        }
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.currentstep = "reviewsection";
      }
      if (self.currentstep == "specificjobsection") {
        if (self.specific_job == "Yes") {
          self.resumeobj.specificjob = self.specific_job;
        } else {
          self.resumeobj.specificjob = "";
          val = "experiencesection";
        }
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      }
      if (self.currentstep == "postionsection") {
        self.resumeobj.Postion = self.Postion;
        self.resumeobj.Company = self.Company;
        self.resumeobj.Recipient_Details.Recipient_Company = self.Company;
        self.resumeobj.Recipient_Details.Postion = self.Postion;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      }
      if (self.currentstep == "currentjobtitle") {
        if (self.Job_Title != "") {
          self.resumeobj.Job_Title = self.Job_Title;
          localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
          self.GetStrengths();
          self.GetSkills();
        } else {
          return false;
        }
      }
      if (self.currentstep == "strengthsection") {
        self.resumeobj.strengths = self.selectedstrengths;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      }
      if (self.currentstep == "skillsection") {
        self.resumeobj.skills = self.selectedskills;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      }
      if (self.currentstep == "experiencesection") {
        self.resumeobj.Experience = self.selectedexp;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      }

      if (self.currentstep == "jobdecsection") {
        if (self.Job_Desc != "") {
          //self.resumeobj.jobdescription = self.Job_Desc;
          self.resumeobj.inputjobdescription = self.Job_Desc;
          val = "workingstylesection";
          localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        }
        // else {
        //   return false;
        // }
      }

      // if (self.currentstep == "jobdescconfirmsection") {
      //   if (self.specific_job_Desc == "Yes") {
      //     self.resumeobj.specificjobdesc = self.specific_job_Desc;
      //   } else {
      //     val = "workingstylesection";
      //   }
      //   localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      // }

      // if (self.currentstep == "workingstylesection")
      // {
      //   self.resumeobj.Workingstyle=self.selected_workstyle;
      //   localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      // }
      //final step

      self.currentstep = val;
      if (localStorage.getItem("local_currentstep")) {
        var currentinfo = JSON.parse(localStorage.getItem("local_currentstep"));
        currentinfo.currentstep = self.currentstep;
        localStorage.setItem("local_currentstep", JSON.stringify(currentinfo));
      } else {
        self.local_currentstep = {
          currentstep: self.currentstep,
        };
        localStorage.setItem(
          "local_currentstep",
          JSON.stringify(self.local_currentstep)
        );
      }
    },
    Dashboard() {
      this.$router.push({ path: "dashboard" });
    },
    Back(val) {
      debugger;
      var self = this;
      if (self.currentstep == "strengthsection") {
        if (self.specific_job == "NO") {
          val = "specificjobsection";
        }
      }
      self.currentstep = val;
      if (localStorage.getItem("local_currentstep")) {
        var currentinfo = JSON.parse(localStorage.getItem("local_currentstep"));
        currentinfo.currentstep = self.currentstep;
        localStorage.setItem("local_currentstep", JSON.stringify(currentinfo));
      } else {
        self.local_currentstep = {
          currentstep: self.currentstep,
        };
        localStorage.setItem(
          "local_currentstep",
          JSON.stringify(self.local_currentstep)
        );
      }
    },
    Selecttemplate(val) {
      debugger;
      var self = this;
      self.ResumeTemplate = val;
      self.resumeobj.ResumeTemplate = self.ResumeTemplate;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.currentstep = "selectresume";
      self.local_currentstep = {
        currentstep: self.currentstep,
      };
      localStorage.setItem(
        "local_currentstep",
        JSON.stringify(self.local_currentstep)
      );
    },
    SelectResume(val) {
      debugger;
      if (val == "New") {
        this.currentstep = "specificjobsection";
      }
      if (val == "Exists") {
        this.currentstep = "Existingcoverletter";
      }
    },
  },
};
</script>
<style type="text/css">
/* .form-control {
  display: block;
  width: 25%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */
/* .template {
    height: 378px;
    width: 25%;
} */
.template-sec {
  display: flex;
  flex-wrap: wrap;
}
.template {
  width: 300px; /* Set the desired width */
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  height: 365px;
  overflow: hidden;
  margin: 0 20px 20px 0;
}
.template {
  font-size: 10px;
  line-height: 1.4;
}
.inner-coverletter > div {
  transform-origin: 0 0;
  transform: scale(0.43);
}
.inner-coverletter .main-container {
  width: 650px !important;
}
.selected {
  background-color: #1f85cd; /* Set your desired selected color */
  color: white; /* Set the text color for better visibility */
}
.Skill-list {
  border-radius: 4px;
  height: 40px;
  padding: 11px 20px;
  margin: 0 7px 16px;
  border: 1px solid #e7e9eb;
  background: none;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  display: flex;
  align-items: center;
}
.Skill-list i {
  margin-left: 8px;
  font-size: 20px;
}
.Skill-list:hover,
.active .Skill-list {
  background-color: #1f85cd;
  color: #fff;
  border-color: #1f85cd;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  width: 100%;
}
.button-box {
  border: 1px solid #d7dde2;
  border-radius: 26px;
  background: #fff;
  cursor: pointer;
  height: 135px;
  margin-bottom: 2.7rem;
  padding: 1rem;
  position: relative;
  user-select: none;
  -ms-user-select: none;
  text-align: center;
  font-size: 14px;
}
.button-box b {
  display: block;
  text-align: center;
  margin: 0 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}
button.button-box.selected {
  border: 2px solid #1f85cd;
  color: #000;
  background: #f6f8fa;
}
.button-boxspe {
  border: 1px solid #d7dde2;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
  padding: 15px 1rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 160px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  color: #333;
  cursor: pointer;
}
button.button-boxspe:hover,
button.button-boxspe.selected {
  border-color: #1f85cd;
  color: #333;
  border-width: 2px;
  box-shadow: 0 0 3px rgba(58, 150, 240, 0.2);
  background-color: #f6f8fa;
}
.cover-letter-sec {
  padding: 50px 0;
}
.cover-letter-sec h3 {
  color: #1f85cd;
  font-size: 36px;
}
.btn-primary {
  cursor: pointer;
}
.cover-letter-sec a.resume-block {
  border: 3px solid #e5dfde;
  border-radius: 15px;
  display: block;
  max-width: 420px;
  margin: 0 auto;
  padding: 40px 20px;
  cursor: pointer;
  text-align: center;
  background: #fff;
}
.cover-letter-sec a.resume-block:hover,
.cover-letter-sec a.resume-block:active,
.cover-letter-sec a.resume-block:focus {
  border-color: #1f85cd;
}
.cover-letter-sec a.resume-block > span i {
  font-size: 24px;
  background: #1f85cd;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}
.cover-letter-sec a.resume-block p.option-text {
  margin: 10px 0 0;
  font-size: 13px;
}
.cover-letter-sec label.control-label {
  margin-bottom: 5px;
}
.custom-radio-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px 0 0;
}
.custom-radio {
  margin: 0 1.5rem 1.5rem 0;
  position: relative;
}
.custom-radio input[type="radio"] {
  opacity: 0;
  left: 0;
}
.custom-radio [type="radio"]:not(:checked) {
  padding-left: 0;
  text-align: center;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-weight: 600;
  min-width: 50px;
  border-radius: 50%;
}
.custom-radio input[type="radio"] + label:after,
.custom-radio input[type="radio"] + label:before {
  display: none;
}
.custom-radio:first-child input[type="radio"] + label {
  width: 138px;
  border-radius: 2.5rem;
  text-transform: uppercase;
  line-height: normal;
  font-size: 14px;
  letter-spacing: normal;
}
.custom-radio input[type="radio"] + label {
  padding-left: 0;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  min-width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d7dde2;
  font-size: 16px;
  color: #666;
}
.custom-radio [type="radio"]:checked + label {
  font-weight: 700;
  border: 2px solid #1f85cd;
  color: #666;
}
.heading-box {
  text-align: center;
  margin-bottom: 50px;
}
.cover-letter-sec .heading-box h4,
.cover-letter-sec .heading-box h5 {
  margin-top: 10px;
  font-size: 16px;
}
.error {
  border-color: red;
}
.multiselect__option--highlight {
  background: #1f85cd !important;
}
.multiselect__option--highlight:after {
  background: #1f85cd !important;
}
.coverfinalletter .dropdown span {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.coverfinalletter .dropdown span:hover {
  color: #1f85cd;
}
.coverfinalletter .dropdown .dropdown-menu {
  padding: 5px 0;
  margin: 0;
}
.coverfinalletter .dropdown .dropdown-menu li {
  padding: 3px 10px;
  font-size: 14px;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.review-section .review-top {
    min-height: 100px;
    background: #eef6f9;
    padding: 47px 30px;
    border: 1px solid #cecdd3;
    border-radius: 10px;
}
.review-top-inner {
    display: block;
    text-align: center;
}
.review-top-inner h2, .review-top-inner h5 {
    font-size: 14px;
    font-weight: 600;
    margin: 1px 0;
}
.review-top-inner button {
    margin: 20px 0 0;
}
.review-inner-box h5 {
  background: #eef6f9;
  padding:15px 30px 15px 15px;
  border: 1px solid #cecdd3;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}
.review-inner-box h5 i {
  position: absolute;
  right: 15px;
  font-size: 24px;
}
.review-inner-box .review-middle-sec {
  padding: 15px;
  border: 1px solid #cecdd3;
  border-radius:10px;
  display: none;
}
.review-btn-sec {
  padding: 20px 15px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eee;
}
.review-inner-block {
  margin-bottom: 30px;
}
.review-inner-block h3 {
  margin-bottom: 10px;
  font-size: 17px;
}
.review-middle-sec .heading-box {
  margin: 10px 0 30px;
}
.review-middle-sec textarea {
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  color: #000;
}
.review-inner-box {
  margin-bottom: 10px;
}

.filesview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filesview {
  width: 170px; /* Set the width of your boxes */
  height: 200px; /* Set the height of your boxes */
  background-color: #fff;
  color: #ffffff;
  text-align: center;
  line-height: 100px;
  border: 1px solid #d7dde2;
  cursor: pointer;
}
.filesview .template_samples.selected_item {
  border: 1px solid #1f85cd;
  color: #000;
  background: #fff;
}
.filesview .template_samples canvas {
  max-height: 200px;
  padding: 10px;
}
.filesview .thumb-letter-details {
  margin-top: 3px;
  padding: 10px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.07);
  position: relative;
  bottom: 70px;
  text-align: left;
  background: #fff;
}
.filesview .thumb-letter-details .thumb-letter-name {
  color: #46464e;
  font-size: 14px;
  line-height: 2.3rem;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.filesview .thumb-letter-details .thumb-letter-date {
  font-weight: 500;
  font-size: 14px;
  color: #9b9b9b;
  margin: 0;
  line-height: initial;
}
textarea#w3review {
  margin: 0 auto;
}

#Existsfiles h3 {
  font-size: 20px;
  font-weight: 600;
}
#Existsfiles .modal-body {
  padding: 25px;
}
.review-inner-block .jd-job-desc.enter-jd-job {
  margin-top: 0;
}
.review-inner-block .jd-job-desc.enter-jd-job .job-desc h4 {
    font-size: 18px;
}
.review-inner-block .jd-job-desc.enter-jd-job .job-desc {
    margin-top:0;
}
.review-bottom .review-top-inner {
    text-align: right;
}
.review-bottom .review-top-inner button {
    margin: 0;
}

.ExistingBox h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background: #fff;
}
.Existing-resume {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
}
#Existsfiles .modal-dialog.modal-lg {
  max-width: 900px;
}
#Existsfiles .cover-letter-sec a.resume-block {
  background: #f5f5f5;
}
#Existsfiles .modal-header {
  margin: 0;
  padding: 0;
  border: none;
  min-height: inherit;
}
#Existsfiles .modal-header button.close {
  position: absolute;
  right: 8px;
  top: 5px;
  opacity: 1;
  font-size: 24px;
  margin: 0;
  padding: 0;
  z-index: 9;
  cursor: pointer;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>