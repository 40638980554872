<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Montserrat, sans-serif;"
      >
        <div class="left-container blue-bg" :style="{background:resume.profile.color}" style="width: 30%;color: #fff; padding:0 15px;">
          <div class="img-box" style="padding: 20px 0 0;">
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 150px;width: 100%;object-fit: cover;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 150px;width: 100%;object-fit: cover;"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              >
            </div>
          <div class style="padding: 20px 0;">
            <h1
              v-if="(resume.profile.firstname)"
              style="font-size: 30px; margin:0;word-wrap:break-word;    line-height: normal;"
            >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
            <h1
              v-if="resume.currentstep!=17 && !(resume.profile.firstname)"
              style="font-size: 30px; margin:0; word-wrap:break-word;   line-height: normal;"
            >Suresh Raina</h1>
            <span style="display: block;opacity: 0.8;margin: 5px 0 0;">{{resume.profile.profession}}</span>
          </div>
          <div   style="filter: brightness(0.8);padding: 10px 20px;" :style="{background:resume.profile.color}">
            <h4 style="font-size: 20px;margin:0;">Contact</h4>
          </div>
          <div class="resume-block" style="padding: 20px 0;">
            <p style="margin:0 0 5px;">
              <b v-if="(resume.profile.email)" style="font-weight: 600;">Email:</b>
              {{resume.profile.email}}
              <b
                v-if="resume.currentstep!=17 && !(resume.profile.email)"
                style="font-weight: 600;"
              >Email:</b><span v-if="resume.currentstep!=17 && !(resume.profile.email)">
              suresh@email.com</span>
            </p>
            <p v-if="resume.profile.phone" style="margin:0 0 5px;">
              <b style="font-weight: 600;">Phone:</b>
              {{resume.profile.phone}}
            </p>
            <p v-if="resume.profile.address" style="margin:0 0 5px;">
              <b style="font-weight: 600;">Address:</b>
              {{resume.profile.address}}
            </p>
            <p v-if="resume.profile.linkedin" style="margin:0 0 5px;">
              <b style="font-weight: 600;">LinkedIn:</b>
              {{resume.profile.linkedin}}
            </p>
            <p v-if="resume.profile.website" style="margin:0 0 5px;">
              <b style="font-weight: 600;">Website:</b>
              {{resume.profile.website}}
            </p>
            <p v-if="resume.profile.twitter" style="margin:0 0 5px;">
              <b style="font-weight: 600;">Twitter:</b>
              {{resume.profile.twitter}}
            </p>
          </div>

          <div
            class="heading-strip darkblue-bg" :style="{background:resume.profile.color}"
            style="filter: brightness(0.8);padding: 10px 20px;background: #05265e;"
            v-if="resume.skills"
          >
            <h4 style="font-size: 20px;margin:0;">Skills</h4>
          </div>
          <div v-if="resume.skills" class="resume-block" style="padding: 20px 0;">
            <ul
              v-for="(item,index) in resume.skills"
              style="list-style: none;padding-left: 0; margin:0;"
            >
              <li>
                {{item.text}}
                <div style="display:block; margin-bottom: 5px;">
                  <span
                    v-for="(data,index) in item.rate"
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>-->
                  <span
                    v-if="(5-item.rate)!=5"
                    v-for="(data,index) in (5-item.rate)"
                    style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                  ></span>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="heading-strip darkblue-bg" :style="{background:resume.profile.color}"
            style="filter: brightness(0.8);padding: 10px 20px;background: #05265e;"
            v-if="resume.currentstep!=17 && !(resume.skills)"
          >
            <h4 style="font-size: 20px;margin:0;">Skills</h4>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.skills)" class="resume-block" style="padding: 20px 0;color:grey;">
            <ul
              style="list-style: none;padding-left: 0; margin:0;"
            >
              <li style="color:grey;">
                Accurate money handling
              </li>
              <li style="color:grey;">
                Sales Enterprising
              </li>
              <li style="color:grey;">
                Store opening and closing
              </li>
            </ul>
          </div>
        </div>

        <div class="right-container" style="width: 70%;padding: 20px;">
          <p
            class="big-text"
            v-html="resume.summary"
            style="font-size: 18px;line-height: 1.3;margin:0;"
            v-if="resume.summary"
          ></p>
          <p
            class="big-text"
           v-if="resume.currentstep!=17 && !(resume.summary)"
            style="font-size: 18px;line-height: 1.3;margin:0;color:grey;"
            
          >Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.</p>
          <div
            v-if="resume.workExperience&&resume.workExperience.length>0"
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
          >
            <h3 style="font-size: 22px;font-weight: 600;margin:0;" :style="{color:resume.profile.color}">Work History</h3>
          </div>
          
          <div class="resume-box" v-if="resume.workExperience&&resume.workExperience.length>0">
            <div
              v-for="(item,index) in resume.workExperience"
              style="display: flex;margin-bottom: 25px;"
            >
              <span
                style="font-weight: 600;max-width:87px; width:100%;display: block;margin: 5px 30px 0 0;"
              >{{item.startdate}} - {{item.enddate}}</span>
              <div style="word-wrap:break-word;max-width: calc(100% - 120px);">
                <h2 style="font-size: 20px;font-weight: 600;margin: 0 0 5px;">{{item.jobtitle}}</h2>
                <span
                  style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;"
                >{{item.employer}} {{item.city +" " + item.country}}</span>
                <ul
                  style="list-style: disc;word-wrap:break-word;padding-left: 15px; margin:0;"
                  v-html="item.description"
                ></ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.currentstep!=17 &&!(resume.workExperience&&resume.workExperience.length>0)"
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
          >
            <h3 style="font-size: 22px;font-weight: 600;margin:0;" :style="{color:resume.profile.color}">Work History</h3>
          </div>
          
          <div class="resume-box" v-if="resume.currentstep!=17 &&!(resume.workExperience&&resume.workExperience.length>0)">
            <div
              style="display: flex;margin-bottom: 25px;"
            >
              <span
                style="font-weight: 600;max-width:87px; width:100%;display: block;margin: 5px 30px 0 0;color:grey;"
              >07/2017 - Current</span>
              <div>
                <h2 style="font-size: 20px;font-weight: 600;margin: 0 0 5px;color:grey;">Software Engineer</h2>
                <span
                  style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;color:grey;"
                >Compubotics Banglore India</span>
                <ul
                  style="list-style: disc;padding-left: 15px; margin:0;color:grey;"
                  
                >Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</ul>
              </div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.education&&resume.education.length>0"
          >
            <h3 style="font-size: 22px;font-weight: 600;margin:0;" :style="{color:resume.profile.color}">Education</h3>
          </div>
          <div v-if="resume.education&&resume.education.length>0" class="resume-box">
            <div
              style="display: flex;margin-bottom: 25px;"
              v-for="(item,index) in resume.education"
            >
              <span
                style="font-weight: 600;max-width:87px;width:100%;display: block;margin: 5px 30px 0 0;"
              >{{item.grad_startdate }} - {{item.grad_enddate}}</span>
              <div style="max-width: calc(100% - 120px); word-wrap:break-word;">
                <h2
                  style="font-size: 20px;font-weight: 600;margin: 0 0 5px;"
                >{{item.degree +" "+item.field }}</h2>
                <span
                  style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;"
                >{{ item.school_name }} - {{ item.school_location }}</span>
                <ul
                  style="list-style: disc;word-wrap:break-word;padding-left: 15px; margin:0;"
                  v-html="item.description"
                ></ul>
              </div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.currentstep!=17 &&!(resume.education&&resume.education.length>0)"
          >
            <h3 style="font-size: 22px;font-weight: 600;margin:0;" :style="{color:resume.profile.color}">Education</h3>
          </div>
          <div v-if="resume.currentstep!=17 &&!(resume.education&&resume.education.length>0)" class="resume-box">
            <div
              style="display: flex;margin-bottom: 25px;"
             
            >
              <span
                style="font-weight: 600;max-width:87px; width:100%;display: block;margin: 5px 30px 0 0;color:grey;"
              >01/2013 - 03/2016</span>
              <div>
                <h2
                  style="font-size: 20px;font-weight: 600;margin: 0 0 5px;color:grey;"
                >Msc - Computer Science</h2>
                <span
                  style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;color:grey;"
                >Oxford University - Mumbai,India</span>
                <ul
                  style="list-style: disc;padding-left: 15px; margin:0;color:grey;"
                  
                >Dean's list for the 2015–2016 academic year</ul>
              </div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.accomplishments_text!='None'"
          >
            <h3  style="font-size: 22px;font-weight: 600;margin:0;" :style="{color:resume.profile.color}">Accomplishments</h3>
          </div>
          <div class="resume-box" v-if="resume.extraFields.accomplishments_text!='None'">
            <div style="display: flex;margin-bottom: 25px;">
              <ul
                style="list-style: disc;padding-left: 15px; margin:0;"
                v-html="resume.extraFields.accomplishments_text"
              ></ul>
            </div>
          </div>

          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.affiliations_text!='None'"
          >
            <h3 style="font-size: 22px;font-weight: 600;margin:0;" :style="{color:resume.profile.color}">Affiliation</h3>
          </div>
          <div class="resume-box" v-if="resume.extraFields.affiliations_text!='None'">
            <div style="display: flex;margin-bottom: 25px;">
              <ul
                style="list-style: disc;padding-left: 15px; margin:0;"
                v-html="resume.extraFields.affiliations_text"
              ></ul>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.additional_information_text!='None'"
          >
            <h3 :style="{color:resume.profile.color}"
              style="font-size: 22px;font-weight: 600;margin:0;"
            >Additional Information</h3>
          </div>
                    <div v-if="resume.extraFields.additional_information_text!='None'" class="resume-box">
            <div style="display: flex;margin-bottom: 25px;">
              <div v-html="resume.extraFields.additional_information_text" style="margin:0;"></div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="border-top: 1px solid #0e57d5;border-bottom: 1px solid #0e57d5;font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.software_text!='None'"
          >
            <h3 :style="{color:resume.profile.color}" style="font-size: 22px;font-weight: 600;margin:0;">Software</h3>
          </div>
          <div v-if="resume.extraFields.software_text!='None'" class="resume-box">
            <div style="display: flex;margin-bottom: 25px;">
              <div v-html="resume.extraFields.software_text" style="margin:0;"></div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.languages_text!='None'"
          >
            <h3 :style="{color:resume.profile.color}" style="font-size: 22px;font-weight: 600;margin:0;">Language</h3>
          </div>
          <div v-if="resume.extraFields.languages_text!='None'" class="resume-box">
            <div style="display: flex;margin-bottom: 25px;">
              <div v-html="resume.extraFields.languages_text" style="margin:0;"></div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.certifications_text!='None'"
          >
            <h3 :style="{color:resume.profile.color}" style="font-size: 22px;font-weight: 600;margin:0;">Certification</h3>
          </div>
          <div v-if="resume.extraFields.certifications_text!='None'" class="resume-box">
            <div style="display: flex;margin-bottom: 25px;">
              <div v-html="resume.extraFields.certifications_text" style="margin:0;"></div>
            </div>
          </div>
          <div
            class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
            style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            v-if="resume.extraFields.interests_text!='None'"
          >
            <h3 :style="{color:resume.profile.color}" style="font-size: 22px;font-weight: 600;margin:0;">Interests</h3>
          </div>
          <div v-if="resume.extraFields.interests_text!='None'" class="resume-box">
            <div style="display: flex;margin-bottom: 25px;">
              <div v-html="resume.extraFields.interests_text" style="margin:0;"></div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.add_your_own_json.length>0"
            v-for="(data,index) in resume.extraFields.add_your_own_json"
          >
            <div
              class="heading" :style="{borderTop:1+'px '+'solid '+ resume.profile.color,borderBottom:1+'px '+'solid '+resume.profile.color }"
              style="font-size: 24px;font-weight: 600;padding: 10px 0;margin: 20px 0;"
            >
              <h3 :style="{color:resume.profile.color}" style="font-size: 22px;font-weight: 600;margin:0;">{{data.title}}</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <div v-html="data.body" style="margin:0;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {
    showClosePopUp() {
      var self = this;
      self.showmodal = self.showlist;
    }
  },
  methods: {
    closePopUp: function(item) {
      if (item === "goBack") {
        if (this.parentObj.$data.showResumetwo) {
          this.parentObj.$data.showResumetwo = false;
        }
      }
    },
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Color:self.resume.color,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      // let formData = new FormData();
      // const pdfBlob = await html2pdf().from(pdfContent).outputPdf();
      // const blobPartArray = await new Response(pdfBlob).arrayBuffer(); // Convert Blob to BlobPart array

      // const formData = new FormData();
      // formData.append('file', new Blob([blobPartArray]), 'output.pdf');

      // formData.append('pdfFile', pdfBlob, 'output.pdf');
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        // url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
      //const pdfBuffer =  pdfBlob.arrayBuffer();
      //   const s3 = new AWS.S3({
      //   accessKeyId: 'AKIA5BLF7Z4HNIYBATMF',
      //   secretAccessKey: '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',
      //   region: 'us-east-1',
      // });

      // // Define S3 upload parameters
      // const params = {
      //   Bucket: 'documents-gen-ehr-dev',
      //   Key: 'shri@mailinator.com/document.pdf', // Desired name for the uploaded file
      //   Body: pdfBlob,
      //   ContentType: 'application/pdf',
      //   ACL: 'private',
      // };

      // // Upload to S3
      // s3.upload(params, (error, data) => {
      //   if (error) {
      //     console.error('Error uploading to S3:', error);
      //   } else {
      //     console.log('PDF uploaded to S3:', data.Location);
      //   }
      // });
      //  html2pdf(document.getElementById("resumesec"), {
      // 		margin: 1,
      // 	filename: "i-was-html.pdf",
      // 	});
    }
  }
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease-in-out;
}
/* .modal-wrapper {
  display: table-cell;
  vertical-align: middle;
} */
.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 50px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: opacity 0.5s ease-in-out;
}
.modal-mask.upl-holdNEW.wdth-700 .modal-container {
  width: 700px;
}

.modal-mask:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

.modal-wrapper {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

