<template>
  <div>
    <div><input type="hidden" value="1" id="hiddenmenusection" /></div>
    <div class="left-nav">
    <ul class="main-menu">
      
      <li :class="{ 'active': gotowhere=='javascript:void(0);' }"><a :href="gotowhere" ><span><img src="../assets/dashboard.png" alt=""/></span> Dashboard <i class="material-icons">keyboard_arrow_down</i></a>
         <ul class="submenu">
           <li ><a href="#CVDetails"><span><img src="../assets/manage-cv.png" alt=""/></span> Manage CV </a>
              <!-- <ul class="sub-inner-menu">
                <li><a href="javascript:void(0);"><span><img src="../assets/cv-writer.png" alt=""/></span> CV Writer </a></li>
                <li><a href="javascript:void(0);"><span><img src="../assets/professional-cv-writer.png" alt=""/></span> Professional CV Writer </a></li>
              </ul> -->
           </li>
           <li><a href="#CoverLetter"><span><img src="../assets/manage-cover-letter.png" alt=""/></span>Manage Cover Letter </a></li>
           <!-- <li><a href="javascript:void(0);">Career Counselling <i class="material-icons">keyboard_arrow_down</i></a>
              <ul class="sub-inner-menu">
                <li><a href="javascript:void(0);"><span><img src="../assets/interview.png" alt=""/></span> Interview 1 </a></li>
                <li><a href="javascript:void(0);"><span><img src="../assets/interview.png" alt=""/></span> Interview 2 </a></li>
              </ul>
           </li> -->
         </ul>
      </li>
      <li><a @click.prevent="gotoPackages()"><span data-toggle="tooltip" title="" data-placement="right" data-original-title="Packages"><img   src="../assets/packages.png" alt=""/></span> Packages</a></li>
      <li><a @click.prevent="gotoPaymentHistory()" href="javascript:void(0);"><span data-toggle="tooltip" title="" data-placement="right" data-original-title="Payment History"><img   src="../assets/payment-history.png" alt=""/></span> Payment History</a></li>
      <li><a @click.prevent="gotoSecurity()"><span data-toggle="tooltip" title="" data-placement="right" data-original-title="Security Credentials"><img  src="../assets/security.png" alt=""/></span>  Credentials</a></li>
    </ul>
  </div> 
  </div>
</template>

<script>
 import Axios from "axios";
export default {
    props: ["heading"],
  data(){
    return{
      email:"",
      showPassword: false,
      password:"",
      gotowhere:"javascript:void(0);"
    }
  },
  methods: {

      gotoPackages() {
            this.$router.push({
                path: '/purchasedpackages'
            })
        },
        gotoSecurity() {
            this.$router.push({
                path: '/security'
            })
        },
        gotoPaymentHistory() {
            this.$router.push({
                path: '/paymenthistory'
            })
        },
  },
   mounted() {
     debugger;

     $(document).ready(function(){
     
       var count = $("#hiddenmenusection").val();
      if (count == "1") {
       $('.left-nav ul.main-menu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
        $('.left-nav ul.main-menu .submenu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
        $("#hiddenmenusection").val("2");
}

        

    });
    $(".menu-toggle").click(function(){
          $(".main-section").toggleClass("menu-active");
        });
    debugger;
    var currentroute = this.$router.currentRoute.name;
    if(currentroute=='Dashboard'){
      this.gotowhere="javascript:void(0);"
    }
    else{
      this.gotowhere="/Dashboard";
    }
    $('[data-toggle="tooltip"]').tooltip();

    
  },
  updated(){
    debugger;

    $('[data-toggle="tooltip"]').tooltip();
    $(document).ready(function(){
     
     var count = $("#hiddenmenusection").val();
      if (count == "1") {
       $('.left-nav ul.main-menu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
        $('.left-nav ul.main-menu .submenu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
         $("#hiddenmenusection").val("2");
}
        

    });
    $(".menu-toggle").click(function(){
          $(".main-section").toggleClass("menu-active");
        });
    
  },
  name: 'Leftmenu'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
