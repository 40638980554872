import rest_service from "@/api/rest_service_api";
import axios from "axios";

const state={
	isLoggedIn:false,
	patient_s3upload_Folders: {
		latReportFolder: "/lab-test-result/",
		scanReportFolder: "/scan-result/",
		otherReportFolder: "/other-result/",
		reportimageFolder:"/reports-images/",
		latReportDownloadPath: "/documents/lab-test-result/",
		scanReportDownloadPath: "/documents/scan-result/",
		otherReportDownloadPath: "/documents/other-result/",
		signatureDownloadPath: "/documents/signatures/",
		UploadedImagePath: "/documents/reports-images/"
	},
	authorizeRoute: true,
	currentRoute: null,
	prescription_global_count:0,
	searchHighlightColor:"",
	uninvoicedPrescriptionCount:null,
};

const actions={

};

const mutations = {

};

const getters = {

};

export default{
    state,
	actions,
	mutations,
	getters
};
