<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <a href="/Dashboard"
            ><img src="/img/CC-Logo-White.1144d714.svg" alt=""
          /></a>
        </div>
      </div>
    </div>
    <br>
    <h3 class="text-center" v-if="Package_Name">
      You are currently on the {{ Package_Name }}.
    </h3>
    <h3 v-else class="text-center">You haven't subscribed to any plans.</h3>
    <div class="pricing-section" id="pricing">
      <div class="container">
        <div class="col-md-12 verticalscroll-hold">
          <div class="row pricing-details-hold">
            <div
              v-for="item in Allpacakges"
              class="inner-tabs tab-resolution pricehold col-lg-4 col-sm-12 col-md-4 col-12"
            >
              <div class="pricing-wrapper diamond-hold">
                <img
                  v-if="item.packageName === 'Basic Plan'"
                  src="../../assets/silver-icon.png"
                  title="Silver Plan"
                  alt=""
                />
                <img
                  v-if="item.packageName === 'Silver Plan'"
                  src="../../assets/diamond-icon.png"
                  title="Diamond Plan"
                  alt=""
                />
                <img
                  v-if="item.packageName === 'Gold Plan'"
                  src="../../assets/gold-icon.png"
                  title="Gold Plan"
                  alt=""
                />
                <div class="plan-header">
                  <div class="price diamond">
                    <h2 class="pricing-header">{{ item.packageName }}</h2>
                    <p class="value">
                      <span class="ind-rup">₹</span>{{ item.indAmount }} (<span class="ind-rup">$</span>{{item.otherAmount}})
                    </p>
                    <!-- <span class="details" id="pro-detail">billed monthly</span> -->
                  </div>
                  <div><h4>{{ item.expiryDays }} Days</h4></div>
                  <div class="no-tick btn">
                    <a
                      v-if="Current_Plan_Uniqe_id == item.uniqueId"
                      href="javascript:void(0);"
                      class="btn-secondary md-ink-ripple cls-start-subscription disabled global-disabled"
                      alt=" Contact Us"
                      @click="Buyplan(item.uniqueId)"
                      >Buy Now</a
                    >
                    <a
                      v-else
                      href="javascript:void(0);"
                      class="btn-secondary md-ink-ripple cls-start-subscription"
                      alt=" Contact Us"
                      @click="Buyplan(item.uniqueId)"
                      >Buy Now</a
                    >
                  </div>
                </div>
                <ul class="pricing-body">
                  <li class="is-visible" data-type="monthly"></li>
                  <li>
                    <span></span>
                  </li>
                  <li
                    v-for="dec in item.pkgDescription"
                    :class="{ tick: dec.available, cross: !dec.available }"
                  >
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      class="d-inline-block"
                      data-original-title="E.H.R Charting"
                      >{{ dec.packageDesc }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <input type="hidden" id="amount" v-model="" /> -->
    <form
      type="hidden"
      id="nonseamless"
      method="post"
      name="redirect"
      :action="checkoutUrl"
    >
      <input
        type="hidden"
        v-model="encRequest"
        id="encRequest"
        name="encRequest"
        value=""
      />
      <input
        type="hidden"
        v-model="access_code"
        id="access_code"
        name="access_code"
        value="@access_code"
      />
      <input
        type="hidden"
        v-model="merchant_id"
        id="merchant_id"
        name="merchant_id"
        value=""
      />
    </form>
    <div v-if="showloader">
      <div
        class="processing_popup upload full-spinner"
        style="visibility: visible"
      >
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import myWorker from "@/config";
export default {
  created() {
    if (window.localStorage.getItem("accesstoken")) {
      this.authenticationToken = window.localStorage.getItem("accesstoken");
    }
    this.GetPackages();
    this.Pckageinfo();
  },
  components: {},
  data() {
    return {
      showloader: false,
      Basic_Plan_Amount: "",
      Basic_Plan_Id: "",
      Silver_Plan_Amount: "",
      Silver_Plan_ID: "",
      Gold_Plan_Amount: "",
      Gold_Plan_Id: "",
      Pacakges_info: [],
      encRequest: "",
      access_code: "",
      merchant_id: "",
      checkoutUrl: "",
      Email_For_Buy_Plan: "",
      vuejsurl: process.env.VUE_Js,
      Package_Name: "",
      authenticationToken: "",
      Current_Plan_Uniqe_id: "",
      Allpacakges: [],
    };
  },
  methods: {
    Pckageinfo() {
      var pckinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
      this.Package_Name = pckinfo[0].packageName;
      this.Current_Plan_Uniqe_id = pckinfo[0].packageUniqueId;
    },
    Buyplan(id) {
      var self = this;
      self.showloader = true;
      if (localStorage.getItem("loggedinuserobj")) {
        var userData = JSON.parse(localStorage.getItem("loggedinuserobj"));
        self.Email_For_Buy_Plan = userData.user.email;
      } else {
        self.Email_For_Buy_Plan = this.$route.query.email;
      }
      localStorage.setItem("Email", self.Email_For_Buy_Plan);
      Axios({
        method: "POST",
        url: rest_service.baseURL + "/User/Checkout",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.returnedToken,
        },

        data: {
          Amount: "",
          Package_Id: id,
          Package_Name: "",
          RedirectUrl:
            rest_service.baseURL + "/Anonymous/ExistingUserPaymentResponse",
          CancelUrl:
            rest_service.baseURL + "/Anonymous/ExistingCancelPaymentResponse",
          BillingEmail: self.Email_For_Buy_Plan,
          Currency: "INR",
        },
      }).then(function (response) {
        if (response.data) {
          var data = response.data.result.data;
          self.encRequest = data.strEncRequest;
          self.merchant_id = data.merchantId;
          self.access_code = data.accessCode;
          self.checkoutUrl = data.url;
          setTimeout(() => {
            $("#nonseamless").submit();
            self.showloader = false;
          }, 1000);
        } else {
          self.showloader = false;
          return null;
        }
      });
    },
    BindPackage() {
      var self = this;
      self.showloader=true;
      if (localStorage.getItem("AllPackagesinfo")) {
        var Packages = JSON.parse(localStorage.getItem("AllPackagesinfo"));
        self.Allpacakges = JSON.parse(localStorage.getItem("AllPackagesinfo"));
        Packages.forEach((element) => {
          if (element.packageName == "Basic Plan") {
            self.Basic_Plan_Amount = element.indAmount;
            self.Basic_Plan_Id = element.uniqueId;
          } else if (element.packageName == "Silver Plan") {
            self.Silver_Plan_Amount = element.indAmount;
            self.Silver_Plan_ID = element.uniqueId;
          } else if (element.packageName == "Gold Plan") {
            self.Gold_Plan_Amount = element.indAmount;
            self.Gold_Plan_Id = element.uniqueId;
          }
        });
      }
      self.showloader=false;
    },
    GetPackages() {
      debugger;
      var self = this;
      self.showloader=true;
      if (localStorage.getItem("AllPackagesinfo")) {
        self.BindPackage();
      } else {
        Axios({
          method: "get",
          url: rest_service.baseURL + "/Anonymous/GetPackagesV2",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            if (response.data) {
              var dataToStore = JSON.stringify(response.data.result);
              localStorage.setItem("AllPackagesinfo", dataToStore);
              self.BindPackage();
              self.showloader=false;
            } else {
              self.showloader=false;
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            self.showloader=false;
            return error;
          });
      }
    },
  },
};
</script>
  <style type="text/css">
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: #333;
}

.btn-primary.md-ink-ripple {
  background: #1f85cd;
  color: #fff;
  padding: 3px 30px;
  line-height: 40px;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-transform: initial;
  display: inline-block;
  border: 2px solid #1f85cd;
  min-width: 150px;
  position: relative;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 0;
}

.btn-primary.md-ink-ripple:hover {
  text-decoration: none;
  background: #0651c9;
  color: #0651c9;
}

.btn-primary.md-ink-ripple:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
  top: 0;
  width: 0;
  left: 0;
  height: 100%;
}

.btn-primary.md-ink-ripple:hover:after {
  background: #e9f1ff;
  transition-delay: 0.05s;
  border-radius: 35px;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.pricing-section {
  padding: 20px 0;
}

.pricing-section h2.pricing-header {
  font-size: 28px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  position: relative;
  margin-bottom: 20px;
}

.pricing-wrapper {
  background: #fff;
  padding: 0;
  width: 100%;
  text-align: center;
}

.plan-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.pricing-wrapper {
  background: #ffffff;
  padding: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  /* height: calc(100%+50px); */
  margin-top: 0;
  z-index: 10;
  padding-top: 20px;
  border-radius: 5px;
  -webkit-transition: all 0.05s ease;
  transition: all 0.05s ease;
  border: 2px solid #fff;
  margin-top:40px;
}

.pricing-wrapper:hover {
  top: 0;
  border-color: #1f85cd;
  transition: ease-in-out 1s;
}

.pricing-wrapper img {
  max-height: 103px;
}

.pricing-header {
  font-size: 20px;
  text-align: center;
  margin: 0 0 10px;
  padding-top: 20px;
  font-weight: 600;
}

.free-hold .buy-now,
.professional-hold .buy-now,
.premium-hold .buy-now,
.enterprice-hold .buy-now {
  text-decoration: none;
  padding: 7px 30px;
  border-radius: 5px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  min-width: 155px;
}

.enterprice-hold .price.enterprice p {
  font-size: 34px;
  font-weight: 600;
  padding: 0 20px;
  line-height: 111%;
}

.pricedetail .enterprice-hold .price.enterprice p {
  font-size: 18px;
  color: #0d0e0e;
  font-weight: 400;
}

.pricing-wrapper .price.free,
.pricing-wrapper .price.professional,
.pricing-wrapper .price.premium,
.pricing-wrapper .price.enterprice,
.pricing-wrapper .price.silver,
.pricing-wrapper .price.gold,
.pricing-wrapper .price.diamond {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  min-height: 140px;
}

.pricing-wrapper .value {
  font-size: 28px;
  font-weight: 500;
  padding: 0 20px;
  line-height: 110%;
  margin-bottom: 45px;
}

.pricing-wrapper h3 {
  color: #0d0e0e;
  font-size: 22px;
}

.pricing-wrapper .price .value,
.pricing-wrapper .price .details {
  width: 100%;
  float: left;
  color: #1f85cd;
}

.pricing-wrapper .price .value {
  margin: 0 0 5px;
}

.pricing-wrapper .price .details {
  font-size: 16px;
  margin-bottom: 10px;
}

.pricing-wrapper li {
  width: 100%;
  font-size: 13px;
  display: none;
}

.pricing-body li:nth-child(-n + 11),
.pricing-body li:last-child {
  display: block;
}

.pricedetail .pricing-wrapper li {
  width: 50%;
  float: left;
  font-size: 14px;
}

.pricing-wrapper .pricing-body .tooltip-icon {
  position: relative;
  width: 22px;
  display: inline-block;
  height: 14px;
}

.pricing-wrapper .pricing-body .tooltip-icon:before {
  content: "";
  background: url(/assets/information-icon.png) no-repeat;
  background-position: right;
  width: 22px;
  height: 22px;
  position: absolute;
  top: -2px;
  left: 0;
  cursor: default;
  display: inline-block;
}

li.bottom-btn a {
  background: #ebeff8;
  color: #1f85cd;
  display: block;
  padding: 14px 15px;
  font-size: 14px;
  border-radius: 5px;
  text-align: left;
  min-height: 68px;
  display: flex;
  align-items: center;
  white-space: normal;
}

.pricing-body {
  background: #fff;
  color: #353b47;
  margin-top: 10px;
  padding: 15px;
  /*min-height: 650px;*/
  position: relative;
  padding-bottom: 15px;
}

li.bottom-btn {
  position: absolute;
  bottom: 15px;
  width: calc(100% - 40px);
}

.pricedetail .pricing-body {
  max-width: 820px;
  margin: 40px auto auto;
  padding: 0;
}

.pricing-body .tick,
.pricing-body .cross {
  line-height: 140%;
  padding-bottom: 8px;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  text-align: left;
  font-size: 15px;
}

.pricing-body .tick span,
.pricing-body .cross span {
  word-break: break-word;
  display: block;
  white-space: normal;
}

.top-border {
  border-top: 1px solid #f1f1f1;
  padding-bottom: 5px;
}

.pricing-body .star {
  position: relative;
  padding-left: 10px;
  padding-top: 10px;
}

.pricing-body .star:before {
  content: "*";
  position: absolute;
  left: -5px;
  font-size: 20px;
}

.silver-hold .pricing-body,
.gold-hold .pricing-body,
.diamond-hold .pricing-body {
  min-height: 250px;
}

.free-hold .pricing-body .tick:before,
.professional-hold .pricing-body .tick:before,
.premium-hold .pricing-body .tick:before,
.enterprice-hold .pricing-body .tick:before,
.silver-hold .pricing-body .tick:before,
.gold-hold .pricing-body .tick:before,
.diamond-hold .pricing-body .tick:before,
.pricing-wrapper .pricing-body .cross::before {
  content: "";
  height: 22px;
  width: 22px;
  display: inline-block;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.gold-hold .pricing-body li:nth-child(2),
.diamond-hold .pricing-body li:nth-child(2) {
  padding-left: 0;
  font-size: 16px;
}

.gold-hold .pricing-body li:nth-child(2):before,
.diamond-hold .pricing-body li:nth-child(2):before {
  display: none;
}

.pricing-body .tick.minus {
  color: #b0b5b8;
}

.pricing-body .tick.minus:before {
  border-radius: 3px;
  content: "";
  height: 3px;
  width: 16px;
  background-color: #b0b5b8;
  margin-right: 4px;
  margin-top: 9px;
  left: 4px;
}

.pricing-body .tick:before {
  background: url(../../assets/arrowblue.png) no-repeat;
  background-size: 28px auto;
}

.pricing-wrapper .pricing-body .cross::before {
  content: "x";
  background: none;
  color: red;
  font-weight: bold;
  left: 8px;
  top: -1px;
}

.pricing-wrapper .pricing-body .cross span {
  text-decoration: line-through;
  opacity: 0.5;
}

.switch {
  position: relative;
  display: block;
  height: 50px;
  z-index: 10;
  top: 0;
  width: 230px;
  margin: 0 auto;
}

.pricing-page .switch {
  top: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 150, 243, 0.15);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  width: 70px;
  margin: 0 80px;
  height: 25px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: #1f85cd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: rgba(33, 150, 243, 0.15);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #1f85cd;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(41px);
  -ms-transform: translateX(41px);
  transform: translateX(41px);
}

.slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch .switch-text1,
.switch .switch-text2 {
  position: absolute;
  display: inline-block;
  top: 6px;
  left: 0;
}

.switch .switch-text2 {
  position: absolute;
  display: inline-block;
  left: 160px;
}

.switch .switch-text1 {
  color: #1f85cd;
}

.quick_links li {
  width: 50%;
  float: left;
}

a.see-feature {
  font-size: 17px;
  display: inline-block;
  color: #1f85cd;
}

li.see-featureHold {
  text-align: left;
  padding: 0 0px;
}

a.see-feature .material-icons {
  position: relative;
  top: 6px;
  left: -4px;
}

.btn-secondary.md-ink-ripple {
  background: #ffffff;
  color: #1f85cd;
  padding: 4px 30px;
  line-height: 40px;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 500;
  font-family: Roboto;
  text-transform: initial;
  display: inline-block;
  border: 2px solid #1f85cd;
  text-decoration: none !important;
  min-width: 150px;
  z-index: 0;
}

.btn-secondary.md-ink-ripple {
  background: none;
}

.btn-secondary.md-ink-ripple {
  position: relative;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
}

.btn-secondary.md-ink-ripple:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.btn-secondary.md-ink-ripple:hover {
  transition-delay: 0.1s;
}

.btn-secondary.md-ink-ripple:hover:after {
  background: #e9f7ff;
  transition-delay: 0.05s;
  border-radius: 35px;
}

.btn-secondary.md-ink-ripple:after {
  top: 0;
  width: 0;
  height: 100%;
}

.btn-secondary.md-ink-ripple:after {
  left: 0;
  border-radius: 20px 0 0 20px;
}

.btn-secondary.md-ink-ripple:hover:after {
  width: 100%;
}

.pricing-wrapper .btn-secondary:hover {
  box-shadow: 0 4px 12px rgba(51, 51, 51, 0.24);
}

.no-tick.btn:active {
  box-shadow: none;
}

@media (max-width: 991px) {
  .switch {
    height: 100px;
    top: 55px;
  }

  .pricehold {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .switch {
    height: 60px;
    top: 5px;
  }
}
</style>4