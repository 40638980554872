var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"heading":'Change password'}}),_c('div',{staticClass:"main-section"},[_c('Leftmenu'),_c('div',{staticClass:"right-section"},[_c('div',{staticClass:"changepw-hold"},[_c('div',{staticClass:"border-radius-box"},[_c('h3',[_vm._v("Change Password")]),(_vm.oldpasserror)?_c('div',{staticClass:"alert alert-danger"},[(_vm.oldpasserror)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.oldpasserror))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mart-40"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldpass),expression:"oldpass"}],staticClass:"password-field form-control",attrs:{"type":"password","name":"psw","id":"Password","autocomplete":"off","required":"","maxlength":_vm.maxpswdCharLen,"placeholder":" "},domProps:{"value":(_vm.oldpass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.oldpass=$event.target.value}}}),_c('div',{staticClass:"input-visible"},[_c('span',{class:{
                    'signin-pass-visible':
                      !_vm.showHideFlag && _vm.oldpass.length > 0,
                    'signin-pass-hidden': _vm.showHideFlag && _vm.oldpass.length > 0,
                  },on:{"click":_vm.ShowPassword}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.isOldPswdFormatValid),expression:"errors.isOldPswdFormatValid"}],staticClass:"error-message"},[_vm._v("Please input a valid password")])]),_c('div',{staticClass:"form-group changepw-icons"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newpass),expression:"newpass"}],staticClass:"password-field form-control",attrs:{"type":"password","id":"Password2","name":"psw","autocomplete":"off","required":"","maxlength":_vm.maxpswdCharLen,"placeholder":" "},domProps:{"value":(_vm.newpass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newpass=$event.target.value}}}),_c('div',{staticClass:"input-visible"},[_c('span',{class:{
                    'signin-pass-visible':
                      !_vm.showHideFlag2 && _vm.newpass.length > 0,
                    'signin-pass-hidden': _vm.showHideFlag2 && _vm.newpass.length > 0,
                  },on:{"click":_vm.ShowPassword2}})]),_c('div',{staticClass:"password-field input-visible"},[_c('p',[_vm._v("Password must contain :")]),_c('ul',[_c('li',{class:{
                      'password-valid': _vm.hasUpperCaseChar,
                      'password-invalid': !_vm.hasUpperCaseChar,
                    }},[_vm._v(" At least 1 upper case letter ( A - Z ) ")]),_c('li',{class:{
                      'password-valid': _vm.hasLowerCaseChar,
                      'password-invalid': !_vm.hasLowerCaseChar,
                    }},[_vm._v(" At least 1 lower case letter ( a - z ) ")]),_c('li',{class:{
                      'password-valid': _vm.hasNumberChar,
                      'password-invalid': !_vm.hasNumberChar,
                    }},[_vm._v(" At least 1 numeric character ( 0 - 9 ) ")]),_c('li',{class:{
                      'password-valid': _vm.hasSpecialChar,
                      'password-invalid': !_vm.hasSpecialChar,
                    }},[_vm._v(" At least 1 special case character ")]),_c('li',{class:{
                      'password-valid': _vm.hasPassLength,
                      'password-invalid': !_vm.hasPassLength,
                    }},[_vm._v(" At least 8 characters ")])])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.isNewPswdFormatValid),expression:"errors.isNewPswdFormatValid"}],staticClass:"error-message"},[_vm._v("Please input a valid password")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.isNewPswdLengthFormatValid),expression:"errors.isNewPswdLengthFormatValid"}],staticClass:"error-message"},[_vm._v(" Maximumm password length reached. "),_c('br'),_vm._v(" It should be between 8 and "+_vm._s(_vm.maxpswdCharLen)+" ")])]),_c('div',{staticClass:"form-group changepw-icons",class:{
                'error-msg-hold': _vm.confirmpass && _vm.confirmpass !== _vm.newpass,
              }},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmpass),expression:"confirmpass"}],staticClass:"password-field form-control",attrs:{"type":"password","id":"Password1","autocomplete":"off","name":"psw","required":""},domProps:{"value":(_vm.confirmpass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmpass=$event.target.value}}}),_c('div',{staticClass:"input-visible"},[_c('span',{class:{
                    'signin-pass-visible':
                      !_vm.showHideFlag1 && _vm.confirmpass.length > 0,
                    'signin-pass-hidden':
                      _vm.showHideFlag1 && _vm.confirmpass.length > 0,
                  },on:{"click":_vm.ShowPassword1}})]),(_vm.confirmpass && _vm.newpass != _vm.confirmpass)?_c('span',{staticClass:"error"},[_vm._v("New password and confirm password does not match")]):_vm._e()]),_c('div',{staticClass:"changepw-btn-hold row"},[_c('div',{staticClass:"col-md-12"},[_c('a',{staticClass:"btn btn-primary changepw-btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.passwordChange()}}},[_vm._v("Confirm")])])])])])])])],1),_c('transition',{attrs:{"name":"fade"}},[_c('Messages',{attrs:{"datamessage":_vm.sucessmsgtext,"iserror":_vm.iserrormsg,"isVisible":_vm.isdisplaymsg},on:{"closemsg":_vm.closeMessage}})],1),(_vm.loader)?_c('div',{staticClass:"template-loader-hiw hide"},[_c('div',{staticClass:"loader-overlay"}),_c('div',{staticClass:"progress-bar-wrapper"},[(_vm.finished)?_c('img',{staticClass:"icon-check-circle hide",attrs:{"src":require("../../assets/check-circle.svg"),"alt":"Check Circle","width":"48","height":"48"}}):_vm._e(),_c('p',{staticClass:"progress-text step1"},[_vm._v(_vm._s(_vm.loadermsg))]),(!_vm.finished)?_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-linear"}),_c('div',{staticClass:"progress-gradient progress-animate"})]):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"psw"}},[_c('b',[_vm._v("Enter your current password")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"psw"}},[_c('b',[_vm._v("New password")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"psw"}},[_c('b',[_vm._v("Confirm password")])])}]

export { render, staticRenderFns }