import Vue from 'vue'
import Vuex from 'vuex'
import prescription_store from '@/store/prescription_store'
import helper_store from '@/store/helper_store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    prescription_store,
    helper_store
  }
})
