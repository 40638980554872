<template>

  <div>

<!--Start-->
<Header :heading="'Dashboard'"></Header>
<div class="main-section">
  <Leftmenu></Leftmenu>
  <div class="right-section">
    <div class="section">
      <div class="top-heading-sec">
      <h3>Live Package(s)</h3>
      </div>
      <!-- <div class="border-radius-box">
        <div v-for="(item,index) in ActivePackage" class="inner-sec">
          <div>
            <dl><dt>Start Date</dt><dd>: {{formatDate(new Date(item.createddate))}}</dd></dl>
            <dl><dt>End Date</dt><dd>: {{formatDate(new Date(item.expirydate))}}</dd></dl>
          </div>
          <div>
            <dl><dt>CV Limit</dt><dd>:{{item.noCvs}}</dd></dl>
            <dl><dt>CV Used</dt><dd>:{{item.noCv}}</dd></dl>
          </div>
          <div>
            <dl><dt>Cover Letter Limit</dt><dd>:{{item.noCoverLetter}}</dd></dl>
            <dl><dt>Cover Letter Used</dt><dd>:{{item.usedNoCoverLetter}}</dd></dl>
          </div>
          <div>
            <dl><dt>Interview Limit</dt><dd>:5</dd></dl>
            <dl><dt>Interview Used</dt><dd>:5</dd></dl>
          </div>
          <div>
            <dl><dt>Professional CV Writer Limit</dt><dd>:0</dd></dl>
            <dl><dt>Professional CV Writer Used</dt><dd>:0</dd></dl>
          </div>
          <div>
            <dl><dt>Professional Interviewer Limit</dt><dd>:0</dd></dl>
            <dl><dt>Professional Interviewer Used</dt><dd>:0</dd></dl>
          </div>
        </div>
      </div> -->
      <div class="border-radius-box">
        <div v-if="masterchart.length>0" v-for="(item,index) in masterchart" class="chart-block">
          <div v-for="(data) in item" class="chart-box">
            <h4>{{data.chart_title}}</h4>
              <div id="donutchart">
                <GChart type="PieChart" :data="data.appointmentcountarrvar" :options="data.chartOption" :resizeDebounce="500" />
              </div>
              <div class="chart-count" :class="data.style">{{data.used_value}}/{{data.total_value}}</div>
          </div>
          <!-- <div class="chart-box">
            <h4>Cover Letter</h4>
              <div id="donutchart">
                <GChart type="PieChart" :data="chartData" :options="chartOptions" :resizeDebounce="500"/>
              </div>
              <div class="chart-count cl-count">15/20</div>
          </div>
          <div class="chart-box">
            <h4>Interview</h4>
              <div id="donutchart">
                <GChart type="PieChart" :data="chartData" :options="chartOptions" :resizeDebounce="500"/>
              </div>
              <div class="chart-count i-count">15/20</div>
          </div>
          <div class="chart-box">
            <h4>Professional CV Writer</h4>
              <div id="donutchart" >
                <GChart type="PieChart" :data="chartData" :options="chartOptions" :resizeDebounce="500"/>
              </div>
              <div class="chart-count pcv-count">15/20</div>
          </div>
          <div class="chart-box">
            <h4>Professional Interviewer</h4>
              <div id="donutchart">
                <GChart type="PieChart" :data="chartData" :options="chartOptions" :resizeDebounce="500"/>
              </div>
              <div class="chart-count pi-count">15/20</div>
          </div> -->


        </div>
      <div class="no-packages" v-else>
        No Package added as yet
        </div>
        
      </div>

    </div>

    <div class="section" id="CVDetails">
      <div class="top-heading-sec">
        <h3>CV Details</h3>
        <button class="btn btn-primary" v-if="Allow_create" @click.prevent="createcv()">Create Your CV</button>
        </div>
        <div class="border-radius-box table-section">
            <div class="inner-sec">
               <table>
                 <tr v-if="cvlist.length>0" v-for="item in cvlist">
                   <td>
                     <div>
                       <img  class="r-asmple" src="../../assets/resume-sample.png" alt=""/> 
                       <span v-if="item.cvFileName!=null">{{item.cvFileName}}</span>
                       <span v-else>{{item.resumeAsitis}}</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <img data-toggle="tooltip" data-placement="top" title="View Resume" src="../../assets/view-icon.png" @click.prevent="getcv(item)" alt=""/>
                       <!-- <span>View Resume </span> -->
                     </div>
                   </td>
                   <td>
                     <div>
                       <img data-toggle="tooltip" data-placement="top" title="Edit Resume" src="../../assets/edit-icon.png" @click.prevent="editcv(item)" alt=""/>
                       <!-- <span>Edit Resume </span> -->
                     </div>
                   </td>
                   <td>
                     <!-- <div>
                       <img data-toggle="tooltip" data-placement="top" title="Download" src="../../assets/download-icon.png" @click.prevent="downloadcv(item)" alt=""/>
                       
                     </div> -->
                     <div class="dropdown">
                        <span type="button" data-toggle="dropdown">
                            <img data-toggle="tooltip" data-placement="top" title="Download" src="../../assets/download-icon.png"  alt=""/>
                        </span>
                        <ul class="dropdown-menu">
                          <li v-if="item.resume!=null"><a href="javascript:void(0);" @click.prevent="downloadwordcv(item)"><i class="fa fa-file-word-o"></i> Download Word</a></li>
                          <li v-if="item.cvFileName!=null"><a href="javascript:void(0);" @click.prevent="downloadcv(item)"><i class="fa fa-file-pdf-o"></i> Download Pdf</a></li>
                          <li v-if="item.resumeAsitis!=null"><a href="javascript:void(0);" @click.prevent="downloadcvasitis(item)">Download file</a></li>
                        </ul>
                      </div>
                   </td>
                   <!-- <td>
                     <div>
                       <img data-toggle="tooltip" data-placement="top" title="Download(word)" src="../../assets/download-icon.png" @click.prevent="downloadwordcv(item)"  alt=""/>
                     </div>
                   </td>  -->
                   <td v-if="Allow_cvjd_Match">
                     <div>
                       <img data-toggle="tooltip" data-placement="top" title="Cv-Jd Match" src="../../assets/cvjd.png" @click.prevent="cvjdmatch(item)" alt=""/>
                       <!-- <span>Edit Resume </span> -->
                     </div>
                   </td>
                   <td>
                     <div>                      
                       <span>{{formattedDateTime(new Date(item.createdDate))}}</span>
                      </div>
                   </td>
                   
                 </tr>
                 <tr v-if="cvlist.length==0">
                                      <td colspan="7" style="height:190px;" class="no-content-added">No Cv(s) created</td>
                                    </tr>  
               </table>
            </div>
            
        </div>

        
    </div>

    <div class="section" id="CoverLetter">
      <div class="top-heading-sec">
        <h3>Cover Letter</h3>
        <button class="btn btn-primary" v-if="Allow_create_CoverLetter" @click.prevent="createcoverletter()">Create your cover letter</button>
      </div>
        <div  class="border-radius-box table-section">
            <div  class="inner-sec">
               <table>
                 <tr v-if="coverletterlist.length>0" v-for="item in coverletterlist">
                   <td>
                     <div>
                       <img  class="r-asmple" src="../../assets/resume-sample.png" alt=""/> 
                       <span>{{item.pdfFileName}}</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <img data-toggle="tooltip" data-placement="top" title="View cover letter" src="../../assets/view-icon.png" @click.prevent="ViewCoverLetter(item)" alt=""/>
                       <!-- <span>View Resume </span> -->
                     </div>
                   </td>
                   <td>
                     <div>
                       <img data-toggle="tooltip" data-placement="top" title="Edit cover letter" src="../../assets/edit-icon.png" @click.prevent="editcoverletter(item)" alt=""/>
                       <!-- <span>Edit Resume </span> -->
                     </div>
                   </td>
                   <td>
                    <div class="dropdown">
                        <span type="button" data-toggle="dropdown">
                            <img data-toggle="tooltip" data-placement="top" title="Download" src="../../assets/download-icon.png"  alt=""/>
                        </span>
                        <ul class="dropdown-menu">
                          <li><a href="javascript:void(0);" @click.prevent="downloadwordcoverletter(item)"><i class="fa fa-file-word-o"></i> Download Word</a></li>
                          <li><a href="javascript:void(0);" @click.prevent="downloadcoverletter(item)"><i class="fa fa-file-pdf-o"></i> Download Pdf</a></li>
                        </ul>
                      </div>
                    </td>
                    <td> <span>{{formattedDateTime(new Date(item.updatedDate))}}</span></td>
                 </tr>
                 <tr v-if="coverletterlist.length==0">
                                      <td colspan="7" style="height:190px;" class="no-content-added">No Coverletter(s) created</td>
                                    </tr>  
               </table>
            </div>
            
        </div>
        

        

    </div>

    <!-- <div class="section">
      <div class="top-heading-sec">
        <h3>Interview Details</h3>
        <button class="btn btn-primary">Create Your Interview</button>
        </div>
        <div class="border-radius-box table-section">
            <div class="inner-sec">
               <table>
                 <tr>
                   <td>
                     <div>
                       <span>1</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <span>Interview 1</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/date.png" alt=""/>
                       <span>01-09-2023</span>
                     </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/view-icon.png" alt=""/>
                       <span>View Results : 0</span>
                     </div>
                   </td>
                   
                   
                 </tr>
                
               </table>
            </div>
        </div>

        
    </div> -->

    <!-- <div class="section">
      <div class="top-heading-sec">
        <h3>Professional CV Writer</h3>
        </div>
        <div class="border-radius-box table-section">
            <div class="inner-sec">
               <table>
                 <tr>
                    <td>
                     <div>
                      <span>1</span>
                      </div>
                   </td>
                   <td>
                     <div>
                      <span>Resume Sample 1</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/view-icon.png" alt=""/>
                       <span>View Resume : 0</span>
                     </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/edit-icon.png" alt=""/>
                       <span>Edit Resume : 0</span>
                     </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/download-icon.png" alt=""/>
                       <span>Download : 0</span>
                     </div>
                   </td>
                 </tr>
                
               </table>
            </div>
        </div>

       
    </div>

    <div class="section">
      <div class="top-heading-sec">
        <h3>Professional Interviewer</h3>
      </div>
        <div class="border-radius-box table-section">
            <div class="inner-sec">
               <table>
                 <tr>
                   <td>
                     <div>
                       <span>1</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <span>Interviewer 1</span>
                      </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/date.png" alt=""/>
                       <span>01-09-2023</span>
                     </div>
                   </td>
                   <td>
                     <div>
                       <img src="../../assets/view-icon.png" alt=""/>
                       <span>View Results : 0</span>
                     </div>
                   </td>
                   
                   
                 </tr>
                
               </table>
            </div>
        </div>

    </div> -->

    

    

    
    
    <div
      id="viewbalance"
      role="dialog"
      tabindex="-1"
      class="modal fade imgPreview"
      aria-modal="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title mb-4">Preview</h3>
            <button type="button" data-dismiss="modal" @click.prevent="pdfclose" class="close">×</button>
          </div>
          <div class="modal-body">
            <div v-if="pdfUrl!=''">
              <pdf v-for="i in numPages"
			:key="i"
			:src="pdfUrl"
			:page="i"
			 :rotation="0" :autoresize="true"></pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmBox
    ref="confirmcomp"
    :showmodal="showCnfrmPopUp"
    :actionmessage="actionMsg"
    @cancelAction="
    showCnfrmPopUp = false;
  "
    @confirmAction="Delete()"
  ></ConfirmBox>
    <div
      id="upgradeprompt"
      role="dialog"
      tabindex="-1"
      class="modal fade imgPreview"
      aria-modal="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title mb-4"></h3>
            <button type="button" data-dismiss="modal" class="close">×</button>
          </div>
          <div class="modal-body">
            <h3>{{popupmessage}}</h3>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<!--End-->




    
   
  </div>
  <!---->
</template>

<script>
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import pdf from "vue-pdf";
import { GChart } from "vue-google-charts";
import moment from "moment";
import Header from "@/components/Header.vue";
import Leftmenu from "@/components/Leftmenu.vue";
import ConfirmBox from "@/components/MessagePopup/ConfirmPopup";

export default {
  components: {
    pdf,
    Header,
    Leftmenu,
    GChart,
    ConfirmBox
  },
  data() {
    return {
      numPages: undefined,
      masterchart:[],
      chartData: [
        ["Used", "Total"],
          ["Used", 2],
          [
            "Total",
            5,
          ],
      ],
      chartOptions: {
        title: "",
        pieHole: 0.7,
        legend: "none",
        pieSliceText: "none",
        height:150,
        //  width: 250,
        is3D: false,
        tooltip: { textStyle: { fontSize: 14 }, isHtml: true },
      },
     
      coverletterlist:[],
      Cv_Used_Count:"",
      Balance_Cv_Count:"",
      loggeedin_user: null,
      loggedin_user_id: null,
      role_unique_id: "",
      loggedin_user_unique_id: "",
      authenticationToken: "",
      cvlist: [],
      pdfUrl: "",
      pdfdownloadUrl: "",
      Package_Name:"",
      Amount:"",
      expiryDate:"",
      buydate:"",
      totalcv:"",
      ActivePackage:[],
      Allow_create:false,
      Allow_create_CoverLetter:false,
      Allow_cvjd_Match:false,
      delete_what: "",
      actionMsg: "",
      showCnfrmPopUp: false,
      delete_item:null,
      popupmessage:""
    };
  },
  created() {
    this.listcvs();
  //  this.Usageinfo();
    this.Pckageinfo();
    this.GetCoversletters();
    
  },
  mounted() {
    // $('[data-toggle="tooltip"]').tooltip();
   
    

    // $(function () {
    //    $('[data-toggle="tooltip"]').tooltip({
    //        trigger : 'hover'
    //    });
     
    //    $('[data-toggle="tooltip"]').on('click', function () {
    //      $(this).tooltip('hide')
    //    });
    // });

    $(document).ready(function(){
        // $(function() {
        //   $(document).scrollTop( $("#CoverLetter").offset().top + 120);  
        // });
        // $(function() {
        //     $(document).scrollTop( $("#CVDetails").offset().top + 120);  
        // });

    //  setTimeout(function () {
    //         var movetarget = $('#CVDetails').offset().top - 100;
    //         var movetarget = $('#CoverLetter').offset().top - 100;
    //         $('html, body').animate({
    //             scrollTop: movetarget
    //         }, 500);
    //     }, 800);


      //  $('.left-nav ul.main-menu > li > a').click(function () {
      //       $(this).parent().toggleClass('active');
      //   });
      //   $('.left-nav ul.main-menu .submenu > li > a').click(function () {
      //       $(this).parent().toggleClass('active');
      //   });

        

    });
    // $(".menu-toggle").click(function(){
    //       $(".main-section").toggleClass("menu-active");
    //     });
    

  },
  computed: {},
  methods: {
    async downloadFile(item,nameoffile) {
      // Replace 'YOUR_S3_FILE_URL' with your actual S3 file URL
      const s3FileURL = item;

      try {
        const response = await fetch(s3FileURL);
        const blob = await response.blob();
        debugger;
        // Extract the filename from the S3 URL
        const filename = nameoffile;

        // Create an <a> element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = filename; // Set the filename for the downloaded file
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
        // Handle the error as needed
      }
    },
    pdfclose(){
      this.pdfUrl="";
      $("#viewbalance").modal("hide");
    },
    CVjdMatch(item){
      if(localStorage.getItem("cvjd_package")){
        var cv = JSON.parse(localStorage.getItem("cvjd_package"));
          var number =parseInt(cv.maxNocvjdMatch)-parseInt(cv.usedNoCvjdMatch);
      this.actionMsg = "Do you want to use 1 credit out of remaining "+number+" credit(s)?";
      this.delete_item = item;
      this.delete_what = "CVJDMatch";
      this.showCnfrmPopUp = true;  
      }
      else{
        this.popupmessage="You don't have any live packages which provides the feature";
        $("#upgradeprompt").modal("show");
      }       
    },
    Preparechart(){
      var self = this;      
      self.ActivePackage.forEach(t=>{
        var chart=[];
        if(t.noCvs){
          var appointmentcountOptionsvar = {
        title: "",
        pieHole: 0.7,
        legend: "none",
        colors: ["#6852e2", "#c9c7d4"],
        pieSliceText: "none",
        height: 150,
        //  width: 250,
        is3D: false,
        tooltip: { textStyle: { fontSize: 14 }, isHtml: true },
      };
      var count_title_mainobj = {
        chart_title: "CV",
        used_value: t.noCv,
        total_value: t.noCvs,
        style:"cv-count",
        chartOption: appointmentcountOptionsvar,
        appointmentcountarrvar: [
          ["Used", "Total"],
          ["Used", parseInt(t.noCv)],
          [
            "Total",
            parseInt(t.noCvs) - parseInt(t.noCv),
          ],
        ],
      };
        chart.push(count_title_mainobj);
        }
        if(t.noCoverLetter){
          var appointmentcountOptionsvar = {
        title: "",
        pieHole: 0.7,
        legend: "none",
        colors: ["#27da90", "#c9c7d4"],
        pieSliceText: "none",
        height: 150,
        //  width: 250,
        is3D: false,
        tooltip: { textStyle: { fontSize: 14 }, isHtml: true },
      };
      var count_title_mainobj = {
        chart_title: "Coverletter",
        used_value: t.usedNoCoverLetter,
        total_value: t.noCoverLetter,
        chartOption: appointmentcountOptionsvar,
        style:"cl-count",
        appointmentcountarrvar: [
          ["Used", "Total"],
          ["Used", parseInt(t.usedNoCoverLetter)],
          [
            "Total",
            parseInt(t.noCoverLetter) - parseInt(t.usedNoCoverLetter),
          ],
        ],
      };
        chart.push(count_title_mainobj);
        }
         var appointmentcountOptionsvar = {
        title: "",
        pieHole: 0.7,
        legend: "none",
        colors: ["#f46262", "#c9c7d4"],
        pieSliceText: "none",
        height: 150,
        //  width: 250,
        is3D: false,
        tooltip: { textStyle: { fontSize: 14 }, isHtml: true },
      };
      var count_title_mainobj = {
        chart_title: "CV-JD Match",
        used_value: parseInt(t.usedNoCvjdMatch),
        total_value: parseInt(t.maxNocvjdMatch),
        style:"i-count",
        chartOption: appointmentcountOptionsvar,
        appointmentcountarrvar: [
          ["Used", "Total"],
          ["Used", parseInt(t.usedNoCvjdMatch)],
          [
            "Total",
           parseInt(t.maxNocvjdMatch)-parseInt(t.usedNoCvjdMatch),
          ],
        ],
      };
        chart.push(count_title_mainobj);
         var appointmentcountOptionsvar = {
        title: "",
        pieHole: 0.7,
        legend: "none",
        colors: ["#6852e2", "#c9c7d4"],
        pieSliceText: "none",
        height: 150,
        //  width: 250,
        is3D: false,
        tooltip: { textStyle: { fontSize: 14 }, isHtml: true },
      };
      var count_title_mainobj = {
        chart_title: "CV Personalize",
        used_value: parseInt(t.usedNoCvGenerate),
        total_value: parseInt(t.maxNoCvGenerate),
        style:"pcv-count",
        chartOption: appointmentcountOptionsvar,
        appointmentcountarrvar: [
          ["Used", "Total"],
          ["Used", parseInt(t.usedNoCvGenerate)],
          [
            "Total",
            parseInt(t.maxNoCvGenerate)-parseInt(t.usedNoCvGenerate),
          ],
        ],
      };
        chart.push(count_title_mainobj);
         var appointmentcountOptionsvar = {
        title: "",
        pieHole: 0.7,
        legend: "none",
        colors: ["#6852e2", "#c9c7d4"],
        pieSliceText: "none",
        height: 150,
        //  width: 250,
        is3D: false,
        tooltip: { textStyle: { fontSize: 14 }, isHtml: true },
      };
      var count_title_mainobj = {
        chart_title: "Cover Letter Personalize",
        used_value: parseInt(t.usedNoCoverLetterGenerate),
        total_value: parseInt(t.maxNoCoverLetterGenerate),
        style:"pi-count",
        chartOption: appointmentcountOptionsvar,
        appointmentcountarrvar: [
          ["Used", "Total"],
          ["Used", parseInt(t.usedNoCoverLetterGenerate)],
          [
            "Total",
            parseInt(t.maxNoCoverLetterGenerate)-parseInt(t.usedNoCoverLetterGenerate),
          ],
        ],
      };
        chart.push(count_title_mainobj);
        self.masterchart.push(chart);
      });
      

    },
    IsCoverletterAllowed(){
      var self = this;
      localStorage.removeItem("cl_package");
      self.ActivePackage.forEach(t=>{
          debugger;
          if(t.coverLetter)
            {
              if( (parseInt(t.noCoverLetter))>(parseInt(t.usedNoCoverLetter)) ){
              self.Allow_create_CoverLetter=true;
              localStorage.setItem("cl_package", JSON.stringify(t)); 
              localStorage.setItem("package_uniqueid_cl", t.purchasedPackageUniqueId);     
              }
            }
        })
    },
    IsCvAllowed(){
      var self = this;
      localStorage.removeItem("package_uniqueid");
      localStorage.removeItem("cv_package");
      self.ActivePackage.forEach(e=>{
          debugger;
          if(e.saveCv){
            if( (parseInt(e.noCvs))>(parseInt(e.noCv)) ){
              self.Allow_create = true;  
              localStorage.setItem("package_uniqueid", e.purchasedPackageUniqueId);  
              localStorage.setItem("cv_package",JSON.stringify(e));    
              return;      
            }     
                  
          }
        })
    },
    IsCvjdMatchAllowed(){
      var self = this;
      localStorage.removeItem("cvjd_package");
      self.ActivePackage.forEach(e=>{
          debugger;
          if(e.matchJobDescriptionCv){
            if( (parseInt(e.maxNocvjdMatch))>(parseInt(e.usedNoCvjdMatch)) ){
              self.Allow_cvjd_Match = true;  
              localStorage.setItem("cvjd_package",JSON.stringify(e));    
              return;      
            }     
                  
          }
        })
    },
    Delete() {
      this.showCnfrmPopUp = false;
      if (this.delete_what == "CVJDMatch") {
        this.cvjdmatch(this.delete_item);      
      }
    },
    CheckCoverletterPackage() {
      var self = this;
      var access=false;
      var Packinfo=[];
      if (localStorage.getItem("UserPackageDetails")) {
        Packinfo = JSON.parse(
          localStorage.getItem("UserPackageDetails")
        );
        if (Packinfo == null ||Packinfo == "" ||Packinfo==undefined) {
          self.$router.push({ path: "ChangePlan" });
          return false;
        } else { 
          Packinfo.forEach(element => {
            if(element.coverLetter)
            {
              access=true;
              return;
            }
          });
          if(access==true)
          {
            return true;
          }
          else
          {
            self.$router.push({ path: "ChangePlan" });
            return false;
          }
         
        }
      }
      else
      {
        self.$router.push({ path: "ChangePlan" });
        return false;
      }
    },
    ViewCoverLetter(item) {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.email +
          "/" +
          item.pdfFileName,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            debugger;
            self.pdfUrl="";
            //window.open(response.data.result, "_blank", "channelmode=1");
            // self.pdfUrl = response.data.result;
            var loadingTask = pdf.createLoadingTask(response.data.result);
              self.pdfUrl = loadingTask;
            self.pdfUrl.promise.then(pdf => {

			self.numPages = pdf.numPages;
		});
            $("#viewbalance").modal("show");
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    downloadcoverletter(item)
    {
      var self = this;
     if(self.CheckCoverletterPackage()){
      debugger;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.email +
          "/" +
          item.pdfFileName,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            // window.open(response.data.result, "_blank", "channelmode=1");
            self.downloadFile(response.data.result,item.pdfFileName);
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
      }
    },
    downloadwordcoverletter(item)
    {
      var self = this;
     if(self.CheckCoverletterPackage()){
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
     
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.email +
          "/" +
          item.docFileName,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            debugger;
            // window.open(response.data.result, "_blank", "channelmode=1");
            self.downloadFile(response.data.result,item.docFileName);
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
      }
    },
    ChangePlan() {
      var self = this;
      this.$router.push({ path: "ChangePlan" });
    },
    Usageinfo()
    {
      var self=this;
      if (localStorage.getItem("userusage")) {
        self.BindPackage();
      } else {
        self.GetUsage();
      }
    },
    Pckageinfo()
    {
      debugger;
      var self=this;
      self.GetPackage();
      // if (localStorage.getItem("UserPackageDetails")) {
      //   self.BindUsage();
      //   self.Balance_Cv_Count = parseFloat(self.totalcv)-parseFloat(self.Cv_Used_Count);
      // } else {
      //   self.GetPackage();
      // }
    
    },
    BindPackage()
    {
      var pckinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
      this.Package_Name=pckinfo.packageName;
      this.totalcv = pckinfo.noCvs;
      this.expiryDate=moment(pckinfo.expiryDate).format("DD-MM-YYYY hh:mm A");
      this.buydate=moment(pckinfo.createdDate).format("DD-MM-YYYY hh:mm A");
    },
    BindUsage()
    {
      var self=this;
      var userinfo = JSON.parse(localStorage.getItem("userusage"));
      if(userinfo!="")
      {
        self.Cv_Used_Count= userinfo.noCv;
        
      }    
    },
    formattedDateTime(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // Use 12-hour format with AM/PM
      };
      return date.toLocaleDateString('en-GB', options); // Adjust the locale as needed
    },
    formatDate(date) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options); // Adjust the locale as needed
    },
    GetPackage() {
      debugger;
      var self=this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserActivePackage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data.result.length>0) {
            self.ActivePackage=response.data.result;
            var dataToStore = JSON.stringify(response.data.result);
            localStorage.setItem("UserPackageDetails", dataToStore);
            if(self.ActivePackage.length>0){
             self.Preparechart();
             self.IsCoverletterAllowed();
             self.IsCvAllowed();
             self.IsCvjdMatchAllowed();
           }

           // self.BindPackage();
          } else {
            if(uid.user.expiresIn!=null){
            self.$router.push({ path: "paymenthistory" });}
            
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    GetUsage() {
      debugger;
      var self=this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserUsage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          userid: uid.user.uniqueId,
        },
      })
        .then(function (response) {
          if (response.data) {
            var dataToStore = JSON.stringify(response.data.result);
            localStorage.setItem("userusage", dataToStore);
            self.BindUsage();
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    GetCoversletters() {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      axios({
        method: "get",
        url:
          rest_service.baseURL + "/CoverLetter/GetCoverLetters?userid="+uid.user.uniqueId,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result.length > 0) {
            debugger;
            self.coverletterlist = response.data.result;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    listcvs() {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      axios({
        method: "get",
        url:rest_service.baseURL + "/User/GetUserCvs?email="+uid.user.email,
        // url: "https://localhost:7101/api" + "/User/GetUserCvs?email="+uid.user.email,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result.length > 0) {
            debugger;
            self.cvlist = response.data.result;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    logout() {
      var self = this;
      this.$router.push({ path: "signout" });
    },
    createcv(){
      this.$router.push({
                              name: "createCV"
                              
                            });          
    },
    createcoverletter()
    {
      localStorage.removeItem("resumeobj");
      localStorage.removeItem("local_currentstep");
      localStorage.removeItem("coverletterid");
      this.$router.push({
                              name: "CoverLetter"
                              
                            });
    },
    editcoverletter(item)
    {
      debugger;
      localStorage.removeItem("resumeobj");
      localStorage.removeItem("local_currentstep");
      localStorage.removeItem("coverletterid");
      this.$router.push({path: "CoverLetterFinal?coverletterid="+ item.uniqueId});
                           
    },
    getcv(item) {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      var filename = "";
      if(item.cvFileName!=null){
        filename = item.cvFileName;
      }
      else{
        filename=item.resumeAsitis;
      }
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.userEmail +
          "/" +
          filename,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            debugger;
            self.pdfUrl="";
            //
            if (/\.(pdf)$/i.test(filename)) {
            var loadingTask = pdf.createLoadingTask(response.data.result);
              self.pdfUrl = loadingTask;
            self.pdfUrl.promise.then(pdf => {

			self.numPages = pdf.numPages;
		});
           // self.pdfUrl = response.data.result;
            $("#viewbalance").modal("show");
            }
            else{
              window.open(response.data.result, "_blank", "channelmode=1");
            }
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    downloadwordcv(item){
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.userEmail +
          "/" +
          item.resume,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            debugger;
            self.downloadFile(response.data.result,item.resume);
            // window.open(response.data.result, "_blank", "channelmode=1");
            
            // $("#viewbalance").modal("show");
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    downloadcvasitis(item){
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.userEmail +
          "/" +
          item.resumeAsitis,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            debugger;
           //  window.open(response.data.result, "_blank", "channelmode=1");
           self.downloadFile(response.data.result,item.resumeAsitis);
            
            // $("#viewbalance").modal("show");
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    downloadcv(item) {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var self = this;
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/S3proxy/GetCVlink?key=" +
            item.userEmail +
          "/" +
          item.cvFileName,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response.data.result) {
            debugger;
            // window.open(response.data.result, "_blank", "channelmode=1");
            self.downloadFile(response.data.result,item.cvFileName);
            // $("#viewbalance").modal("show");
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    logout() {
      var self = this;
      this.$router.push({ path: "signout" });
    },
    editcv(item){
      var dataToStore = JSON.stringify(item);
            localStorage.setItem("UserCV", dataToStore);
      this.$router.push({
                              name: "CV",
                              params: { id: item.uniqueId },
                            });
    },
    cvjdmatch(item){
      var dataToStore = JSON.stringify(item);
            localStorage.setItem("UserCV", dataToStore);
      this.$router.push({
                              name: "Cvjdmatch"
                            });
    },
  },
  watch: {},
  updated() {
    $('[data-toggle="tooltip"]').tooltip();
   
    

    $(function () {
       $('[data-toggle="tooltip"]').tooltip({
           trigger : 'hover'
       });
     
       $('[data-toggle="tooltip"]').on('click', function () {
         $(this).tooltip('hide')
       });
    });

    $(document).ready(function(){
        // $(function() {
        //   $(document).scrollTop( $("#CoverLetter").offset().top + 120);  
        // });
        // $(function() {
        //     $(document).scrollTop( $("#CVDetails").offset().top + 120);  
        // });

    //  setTimeout(function () {
    //         var movetarget = $('#CVDetails').offset().top - 100;
    //         var movetarget = $('#CoverLetter').offset().top - 100;
    //         $('html, body').animate({
    //             scrollTop: movetarget
    //         }, 500);
    //     }, 800);


       var count = $("#hiddenmenusection").val();
     // if (count == "1") {
       $('.left-nav ul.main-menu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
        $('.left-nav ul.main-menu .submenu > li > a').click(function () {
            $(this).parent().toggleClass('active');
        });
       //  $("#hiddenmenusection").val("2");
//}
        

    });
    $(".menu-toggle").click(function(){
          $(".main-section").toggleClass("menu-active");
        });

  },
};
</script>
 
<style>
.datehold {
  display: table;
  margin-top: 15px;
  margin-left: 17px;
}
.datehold a {
  display: table-cell;
}
.Pharmacy-dashboard .ehr-main-holdleft .card {
  border: 1px solid #ccc !important;
  padding: 20px !important;
  display: block;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 1px 6px 0 rgba(0, 0, 0, 0.05);
}
.Pharmacy-dashboard .ehr-main-holdleft .card img {
  max-width: 100%;
}
.border-line {
  background: #d3d4dc;
  height: 1px;
  display: block;
}
.Pharmacy-dashboard .ehr-main-holdleft .card h4 {
  font-size: 16px !important;
  font-weight: 500;
  background: #f6f7fb;
  text-align: center;
  padding: 8px;
  margin: 0;
}
.graph_table {
  width: 100%;
  background: fdfdfd;
}
.graph_table th table thead tr,
.graph_tablehold table thead tr {
  background: #fdfdfd;
  font-weight: 500;
}
.graph_tablehold table tbody {
  border: 0;
}
.ehr-main-holdleft {
  padding-bottom: 40px;
}
.Pharmacy-dashboard h2 {
  font-size: 17px;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme1 {
  background: #ffe2e2;
  color: #fff;
  box-shadow: none;
  border: 1px solid #f76565 !important;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme2 {
  background: #fff2db;
  color: #fff;
  box-shadow: none;
  border: 1px solid #f1a31a !important;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme3 {
  background: #fffae5;
  color: #fff;
  box-shadow: none;
  border: 1px solid #e8c211 !important;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme table {
  width: 100%;
  table-layout: fixed;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme {
  padding: 15px;
  min-height: 265px;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme table tbody,
.Pharmacy-dashboard .ehr-main-holdleft .card.theme table tbody td,
.Pharmacy-dashboard .ehr-main-holdleft .card.theme table thead tr {
  background: 0 0;
  border: 0;
  color: #111;
}
.card.theme table th,
.card.theme table td {
  text-align: left !important;
  width: 33%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.card.theme table th:nth-child(2),
.card.theme table td:nth-child(2) {
  width: 20% !important;
}
.card.theme table th:nth-child(3),
.card.theme table td:nth-child(3) {
  padding-left: 10px;
}
.view-all {
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
  padding: 6px 15px;
  margin-top: 20px;
  display: inline-block;
  border-radius: 3px;
}
.view-all:hover {
  color: #fff;
  text-decoration: none;
  background: #fff;
  color: #0056e0;
  border: 1px solid #fff;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme1 .view-all {
  background: #f76565;
  color: #fff;
  border: 1px solid #f76565;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme1 .view-all:hover {
  background: #fff;
  color: #181717;
  border: 1px solid #f76565;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme2 .view-all {
  background: #f1a31a;
  color: #fff;
  border: 1px solid #f1a31a;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme2 .view-all:hover {
  color: #f1a31a;
  background: #fff;
  color: #f1a31a;
  border: 1px solid #f1a31a;
}

.Pharmacy-dashboard .ehr-main-holdleft .card.theme3 .view-all {
  background: #e8c211;
  color: #fff;
  border: 1px solid #e8c211;
}
.Pharmacy-dashboard .ehr-main-holdleft .card.theme3 .view-all:hover {
  background: #fff;
  border: 1px solid #e8c211;
  color: #e8c211;
}
.stock-list {
  min-height: 90px;
  overflow: hidden;
}
.no-records {
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  color: #484446;
}
.bigfont {
  font-size: 30px;
}
input.flatpickr-input.form-control.input {
  /* border-radius: 10px;*/
  border: 0px;
  box-shadow: 0 1px 3px -1px rgb(0 0 0 / 5%), 0 2px 4px 0 rgb(0 0 0 / 5%),
    0 1px 6px 0 rgb(0 0 0 / 5%);
  border: 1px solid #ccc !important;
}
select#lastdays {
  color: #484446;
  border-color: #ccc;
  height: 50px;
  border-radius: 3px;
}
.Pharmacy-dashboard .card .Pharmacy-selecthold::before {
  color: #484446;
  right: 10px;
}
.payment-chart .Pharmacy-select,
.inventory-chart .Pharmacy-select {
  color: #484446;
  border: 1px solid #ccc;
  height: 41px;
}
.payment-chart .container-comm input,
.inventory-chart .container-comm input {
  height: 41px !important;
}
.inventory-chart select {
  height: 41px !important;
}

.card.theme h5 {
  font-weight: 600;
}
.card.theme1 h5 {
  color: #f76565;
}
.card.theme2 h5 {
  color: #f1a31a !important;
}
.card.theme3 h5 {
  color: #e8c211 !important;
}
.card {
  position: relative;
  background-color: transparent;
  border: 0px;
}
.full-spinner.processing_popup {
  position: absolute !important;
  border: 0px !important;
}
.payment-chart .container-comm.datepickerhold input {
  padding-right: 30px !important;
}
.card.top-five-drugs {
  min-height: 258px;
}
.no-data img {
  margin-top: 23px;
}
.info-cart {
  position: absolute;
  right: 10px;
  top: 10px;
}
.info-cart i.material-icons {
  color: #a1a1a1 !important;
}
.form-group .btn.btn-add.payment-go-btn {
  padding: 9px 5px;
  min-width: 60px;
}


/************new css************** */

.header {
    z-index: 999;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.2s ease-in-out 0.2s;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
}
.main-header {
    background: #1f85cd;
    display: flex;
    width:100%;
}
.main-header .left-logo {
    padding: 10px;
    max-width: 250px;
    text-align: center;
    width: 100%;
}
.main-header .left-logo img {
    max-width: 200px;
    margin: 0 auto;
    display: block;
}
.main-header .top-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 0 20px;
}
.main-header .top-right .top-head-left {
    display: flex;
    align-items: center;
    color:#fff;
}
.main-header .top-right .top-head-left a i {
    position: relative;
    top: 2px;
    color: #fff;
}
.main-header .top-right .top-head-left h2 {
    font-weight: 500;
    font-size: 20px;
    margin-left: 20px;
}
.main-header .top-right .top-head-right .dropdown button {
    background: none;
    border: none;
}
.main-header .top-right .top-head-right .dropdown button img {
    width:50px;
    height:50px;
    object-fit: cover;
    border-radius: 50%;
}

.main-section {
  padding:66px 0 0;;
}

.main-section .left-nav {
    background: #187ac1;
    color: #fff;
    max-width: 250px;
    width: 100%;
    position: fixed;
    left: 0;
    top:66px;
    bottom: 0;
    z-index: 99;
    scrollbar-width: thin;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
}
.left-nav ul.main-menu {
    padding: 20px 15px;
    position: relative;
}
.left-nav ul.main-menu li {
    margin-bottom: 8px;
}
.left-nav ul.main-menu li a {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    background:transparent;
    border-radius: 10px;
    font-size: 13px;
    position: relative;
    font-weight: 500;
}
.left-nav ul.main-menu li a img {
    max-width: 30px;
    margin-right: 5px;
}
.left-nav ul.main-menu li a i.material-icons {
    position: absolute;
    right: 10px;
    transition: all 0.5s;
}
.left-nav  ul.main-menu > li.active > a i {
    transform: rotate(-180deg);
    transition: all 0.5s;
}
.left-nav  ul.main-menu .submenu > li.active > a i {
    transform: rotate(-180deg);
    transition: all 0.5s;
}
.left-nav ul.main-menu li a:hover, .left-nav ul.main-menu > li.active > a {
  background: #329ae3;
}
.left-nav ul.main-menu .submenu, .left-nav ul.main-menu .submenu .sub-inner-menu {
  padding-left:10px;
  margin-top: 10px;
  display: none;
  -webkit-transition: .5s cubic-bezier(.4,0,.2,1);
    transition: .5s cubic-bezier(.4,0,.2,1);
}
.left-nav ul.main-menu li.active .submenu{
  display: block;
}
.left-nav ul.main-menu .submenu li.active .sub-inner-menu{
  display: block;
}
.main-section .right-section {
    padding: 20px 20px 20px 270px;
    text-align: left;
    min-height: calc(100vh - 85px);
    color:#000;
}
.main-section.menu-active .left-nav {
    max-width: 80px;
    overflow: initial;
}
.main-section.menu-active .left-nav ul.main-menu li a i {
    display: none;
}
.main-section.menu-active .left-nav ul.main-menu > li > a {
  font-size: 0;
}
.main-section.menu-active .right-section {
    padding-left: 100px;
}
.menu-active .left-nav ul.main-menu .submenu {
    font-size: 13px;
    background: #187ac1;
    position: absolute;
    left: 65px;
    min-width: 230px;
    top: 0;
    padding:20px;
}
.menu-active .left-nav ul.main-menu li:hover .submenu {
  display:block;
}
.menu-active .left-nav ul.main-menu .submenu .sub-inner-menu {
    display: block;
}
.section {
  margin-bottom: 30px;
}
.section h3 {
    font-weight: 600;
    font-size: 20px;
}
.border-radius-box {
    background: #fff;
    border-radius:15px;
    padding: 25px;
    /* border: 1px solid #e3e3e6; */
    box-shadow: 0 0 7px rgba(0,0,0,0.1);
    /* overflow: hidden; */
}
.border-radius-box .inner-sec {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.border-radius-box .inner-sec > div {
    margin-right: 25px;
}
.border-radius-box .inner-sec dl {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom:20px;
}
.border-radius-box .inner-sec dl:last-child {
  margin-bottom: 0;
}
.border-radius-box .inner-sec dl dt {
    font-weight: 500;
    margin-right: 15px;
}
.border-radius-box.table-section {
    padding: 0;
}
.border-radius-box.table-section table tr:nth-child(2n) {
    background: #f5f5f5;
}
.border-radius-box.table-section table tr:last-child td:first-child {
    border-radius:0 0 0 15px;
}
.border-radius-box.table-section table tr:last-child td:last-child {
    border-radius:0 0 15px 0;
}
.table-section .inner-sec {
    display: block;
    width: 100%;
}
.table-section .inner-sec table {
    width: 100%;
    font-size: 15px;
}
.table-section table th {
  font-weight: 600;
}
.table-section table td, .table-section table th {
    vertical-align: middle;
    padding: 20px;
}
.table-section table td div {
    display: flex;
    align-items: center;
}
.table-section table td div .r-asmple {
    background: #187ac1;
    padding: 8px;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    margin-right: 20px;
}
.table-section table td div img {
    margin-right: 15px;
}
.btn-primary {
    color: #fff;
    background-color: #187ac1;
    border-color: #187ac1;
}
.btn {
    border-radius: 40px;
    font-weight: 500;
    font-size: 14px;
    padding: 8px 25px;
}
.btn-primary:hover {
    color: #187ac1;
    background: #fff;
    border-color: #187ac1;
}
.table-section.small-size .inner-sec table {
    font-size: 12px;
    text-align: center;
}
.account-details {
  max-width:400px;
}
.account-details dl {
    display: flex;
    font-size: 13px;
    margin-bottom:25px;
}

.account-details dl dt {
    min-width: 170px;
}
.inner-sec .dropdown-menu {
    margin: 0;
    padding: 0;
    min-width:160px;
}
.inner-sec .dropdown-menu li {
    padding: 5px 10px;
    color: #000;
}
.inner-sec .dropdown-menu li a {
   color: #000;
}
.inner-sec .dropdown-menu li a:hover {
   color: #187ac1;
}

.chart-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    text-align: center;
}
.chart-block .chart-box {
    position: relative;
    text-align: center;
}
.chart-block .chart-box > div {
    position: relative;
    width: 140px;
    margin: 0 auto;
}
.chart-block .chart-box h4 {
    font-weight: 600;
    font-size: 16px;
    font-family: Poppins, sans-serif;
    max-width: 170px;
    position: relative;
    top: 10px;
    z-index: 1;
    text-align: center;
    margin: 0 auto;
}
.top-heading-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.chart-block .chart-box .chart-count {
    position: absolute;
    bottom: 61px;
    font-weight: bold;
    font-size: 16px;
    left: 50%;
    transform: translate(-50% , 0);
}
.cv-count {
  color:#6852e2;
}
.cl-count {
  color:#27da90;
}
.i-count {
  color:#f46262;
}
.pcv-count {
  color:#daae27;
}
.pi-count {
  color:#2899e9;
}
.border-radius-box.table-section table tr:last-child td.no-content-added {
    border-radius: 15px;
    border: none;
    font-weight: bold !important;
    color: #000 !important;
    font-size: 18px;
}
.no-packages {
  font-weight: bold;
    color: #000;
    font-size: 18px;
    text-align:center;
}
.top-head-right .dropdown-menu {
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    min-width: inherit;
}
.top-head-right .dropdown-item:focus, .top-head-right .dropdown-item:hover {
    color: #187ac1;
}
.top-head-right a.dropdown-item i {
    margin-right: 2px;
    min-width: 15px;
    max-width: 15px;
}
</style>