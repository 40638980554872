import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/CSS/style.css"
import cognitoAuth from './cognito'
//-------------moment-------//
import moment from 'moment';
//-------------moment-------//

//import vuetify from '@/plugin/vuetify';



Vue.config.productionTip = false


new Vue({
  router,
  store,
  cognitoAuth,
  render: h => h(App),
  components: { App },
  template: '<App/>',
  onIdle() {  
    var currenturl=this.$route.path;
    if (currenturl!="/" && currenturl!="/signin") {
      this.$router.push({ path: "Sessionout" });
    }
  },
}).$mount('#app')

