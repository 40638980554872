<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        class="main-container"
        style="display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div class="left-container blue-bg" style="width: 30%;color: #000;">
          <div class="img-box" style="padding: 20px 20px 0;">
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 150px;width: 100%;object-fit: cover;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 150px;width: 100%;object-fit: cover;"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              >
            </div>
        </div>
        <div
          v-if="resume.currentstep!=17 && !(resume.summary)"
          class="right-container"
          style="width: 70%;padding:0 30px;"
        >
          <div class="heading" style="padding: 30px 0 10px;">
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >STATEMENT</h4>
          </div>
          <div class="resume-box">
            <p :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="margin:0; color:grey;padding-bottom: 15px;"
            >Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.</p>
          </div>
        </div>
        <div v-if="resume.summary" class="right-container" style="width: 70%;padding:0 30px;">
          <div class="heading" style="padding: 30px 0 10px;">
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >STATEMENT</h4>
          </div>
          <div class="resume-box">
            <p
              v-html="resume.summary" :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="margin:0; color:#000;padding-bottom: 15px;"
            ></p>
          </div>
        </div>
      </div>
      <div
        style=" font-size: 16px; color: #000; line-height: 1.3;font-family:Poppins, sans-serif; padding:0 30px;"
      >
        <h1
          v-if="resume.currentstep!=17 && !resume.profile.firstname"
          style="display:block; font-size: 36px;font-weight: 700; margin:0;"
        >Suresh Raina</h1>
        <h1
          v-if="resume.profile.firstname"
          style="display:block;word-wrap:break-word; font-size: 36px;font-weight: 700; margin:0;"
        >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
        <span
          style="display:block;font-weight:500; margin-bottom:30px; font-size:20px;"
        >{{resume.profile.profession}}</span>
      </div>
      <div
        class="main-container"
        style="display: flex;height: 100%; font-size: 16px; color: #000; font-weight:500;line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div class="left-container blue-bg" style="width: 30%;color: #000; padding:0 30px;">
          <div class="heading" style="padding: 30px 0 10px;">
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >INFO</h4>
          </div>
          <div class="resume-block" style="padding: 30px 0;">
            <div v-if="resume.profile.linkedin" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/color/36/linkedin.png"
                  alt="linkedin"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.linkedin }}</p>
            </div>
            <div v-if="resume.profile.website" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/glyph-neue/24/000000/globe--v1.png"
                  alt="linkedin"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.website }}</p>
            </div>
            <div v-if="resume.profile.twitter" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/glyph-neue/36/5B94DE/twitter--v1.png"
                  alt="linkedin"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.twitter }}</p>
            </div>
            <div v-if="resume.profile.address" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/glyph-neue/36/5B94DE/home--v1.png"
                  alt="home--v1"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.address }}</p>
            </div>
            <div v-if="resume.currentstep!=17 && !(resume.profile.email)" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/3d-fluency/36/mail.png"
                  alt="mail"
                >
              </span>
              <p style="margin:0 0 0 10px;">suresh@email.com</p>
            </div>
            <div v-if="resume.profile.email" style="display:flex; margin-bottom:15px;">
              <span
                style="background:#fff; display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/3d-fluency/36/mail.png"
                  alt="mail"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.email }}</p>
            </div>
            <div v-if="resume.profile.phone" style="display:flex;">
              <span
                style="background:#fff;display:flex; width:24px; height:24px;
align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/ios-filled/36/5B94DE/phone-disconnected.png"
                  alt="phone-disconnected"
                >
              </span>
              <p style="margin:0 0 0 10px;">{{ resume.profile.phone }}</p>
            </div>
          </div>

          <div v-if="resume.skills.length>0" class="heading" style="padding: 30px 0 10px;">
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >TECHNICAL SKILLS</h4>
          </div>

          <div v-if="resume.skills.length>0" class="resume-block" style="padding: 10px 0;">
            <ul
              v-for="(item,index) in resume.skills"
              style="list-style: none;padding-left: 0px; margin:0;"
            >
              <li style=" padding-bottom: 10px;">
                {{item.text}}
                <div style="display:block;">
                  <span
                    v-for="(data,index) in item.rate"
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>-->
                  <span
                    v-if="(5-item.rate)!=5"
                    v-for="(data,index) in (5-item.rate)"
                    style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                  ></span>
                </div>
              </li>
            </ul>
          </div>
          <div
            v-if="resume.currentstep!=17 && !resume.skills.length>0"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >TECHNICAL SKILLS</h4>
          </div>

          <div
            v-if="resume.currentstep!=17 && !resume.skills.length>0"
            class="resume-block"
            style="padding: 10px 0;"
          >
            <ul style="list-style: none;padding-left: 0px; margin:0;">
              <li style=" padding-bottom: 10px;color:grey;">
                Accurate money handling
              </li>
              <li style=" padding-bottom: 10px;color:grey;">
                sales enterprising
              </li>
              <li style=" padding-bottom: 10px;color:grey;">
                Store opening and closing
              </li>
            </ul>
          </div>
        </div>

        <div
          class="right-container" :style="{borderLeft:3+'px '+'solid '+ resume.profile.color }"
          style="width: 70%;padding:0 30px; margin-top:30px;"
        >
          <div
            v-if="resume.currentstep!=17 && !(resume.workExperience && resume.workExperience.length>0)"
            class="heading"
            style="padding: 0 0 10px;"
          >
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >EXPERIENCE</h4>
          </div>

          <div
            v-if="resume.currentstep!=17 && !(resume.workExperience && resume.workExperience.length>0)"
            class="resume-box"
          >
            <div style="display:flex; justify-content:space-between; padding:10px 0;">
              <div>
                <h5 style="margin:0; font-size:18px;color:grey;">Software Engineer</h5>
                <p
                  style="margin:5px 0 0; font-size:15px;color:grey;"
                >Compubotics Banglore India</p>
              </div>
              <span style="font-weight:600;color:grey; display:inline-block; max-width:88px; width:100%;">07/2017 - Current</span>
            </div>

            <div class="resume-block" style="padding: 10px 20px;">
              <ul style="list-style: disc;padding-left: 15px; margin:0;color:grey;">Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</ul>
            </div>
          </div>
          <div
            v-if="resume.workExperience && resume.workExperience.length>0"
            class="heading"
            style="padding: 0 0 10px;"
          >
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >EXPERIENCE</h4>
          </div>

          <div
            v-if="resume.workExperience && resume.workExperience.length>0"
            v-for="(item,index) in resume.workExperience"
            class="resume-box"
          >
            <div style="display:flex; justify-content:space-between; padding:10px 0;">
              <div>
                <h5 style="margin:0; font-size:18px;">{{item.jobtitle}}</h5>
                <p
                  style="margin:5px 0 0; font-size:15px;"
                >{{item.employer}}, {{item.city +" " + item.country}}</p>
              </div>
              <span style="font-weight:600; max-width: 88px; width:100%; display:inline-block;">{{item.startdate}} - {{item.enddate}}</span>
            </div>

            <div class="resume-block" style="padding: 10px 10px;">
              <ul v-html="item.description" style="list-style: disc;padding-left: 0; margin:0;"></ul>
            </div>
          </div>
          <div
            v-if="resume.education && resume.education.length>0"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
            >EDUCATION</h4>
          </div>

          <div v-if="resume.education && resume.education.length>0" class="resume-box">
            <div
              v-for="(item,index) in resume.education"
              class="resume-block"
              style="padding: 20px 0;"
            >
              <div style="display:flex; margin-bottom:15px;">
                <span
                  style="font-weight:600; margin-right:30px; display:inline-block; max-width:88px; width:100%;"
                >{{item.grad_startdate }} - {{item.grad_enddate}}</span>
                <p style="margin:0; max-width:75%; width:100%;">
                  <b>{{ item.school_name }}</b>
                  <br>
                  {{item.degree +" "+item.field }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.education && resume.education.length>0)"
            class="heading"
            style="padding: 30px 0 10px;"
          >
            <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
              style="font-size: 20px;margin:0;padding-bottom: 15px; text-transform:uppercase;"
            >EDUCATION</h4>
          </div>

          <div v-if="resume.currentstep!=17 && !(resume.education && resume.education.length>0)" class="resume-box">
            <div
              class="resume-block"
              style="padding: 20px 0;"
            >
              <div style="display:flex; margin-bottom:15px;">
                <span
                  style="font-weight:600; margin-right:30px; color:grey; display:inline-block; max-width:25%; width:100%;"
                >01/2013 - 03/2016</span>
                <p style="margin:0; color:grey; max-width:75%; width:100%;">
                  <b>Oxford University</b>
                  <br>
                  Msc Computer Science
                </p>
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.accomplishments_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
              >ACCOMPLISHMENTS</h4>
            </div>
            <div class="resume-box">
              <p
                v-html="resume.extraFields.accomplishments_text" :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div v-if="resume.extraFields.affiliations_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
              >AFFILIATIONS</h4>
            </div>
            <div class="resume-box">
              <p
                v-html="resume.extraFields.affiliations_text" :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div v-if="resume.extraFields.additional_information_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
              >ADDITIONAL INFORMATION</h4>
            </div>
            <div class="resume-box">
              <p
                v-html="resume.extraFields.additional_information_text" :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div v-if="resume.extraFields.software_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
              >SOFTWARE</h4>
            </div>
            <div class="resume-box">
              <p
                v-html="resume.extraFields.software_text" :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div v-if="resume.extraFields.languages_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0;padding-bottom: 15px; text-transform:uppercase;"
              >LANGUAGE</h4>
            </div>
            <div class="resume-box">
              <p :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                v-html="resume.extraFields.languages_text"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div v-if="resume.extraFields.certifications_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
              >CERTIFICATION</h4>
            </div>
            <div class="resume-box">
              <p :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                v-html="resume.extraFields.certifications_text"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div v-if="resume.extraFields.interests_text!='None'">
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0;padding-bottom: 15px; text-transform:uppercase;"
              >INTEREST</h4>
            </div>
            <div class="resume-box">
              <p :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                v-html="resume.extraFields.interests_text"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
          <div
            v-if="resume.extraFields.add_your_own_json.length>0"
            v-for="(data,index) in resume.extraFields.add_your_own_json"
          >
            <div class="heading" style="padding: 30px 0 10px;">
              <h4 :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="font-size: 20px;margin:0; padding-bottom: 15px; text-transform:uppercase;"
              >{{data.title}}</h4>
            </div>
            <div class="resume-box">
              <p
                v-html="data.body" :style="{borderBottom:3+'px '+'solid '+ resume.profile.color }"
                style="margin:0; color:#000;padding-bottom: 15px;"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {},
  methods: {
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Color:self.resume.color,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");

            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


