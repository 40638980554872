<template>
  <div>   
    <span>{{item.description}}</span><br/>    
  </div>
</template>
 
<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script> 