<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div style="font-family:Montserrat, sans-serif; padding:25px;">
        <h2 v-if="resume.profile.firstname"
          style="text-transform:uppercase;word-wrap:break-word; font-size:36px; font-weight:600; margin:0;letter-spacing:1px; text-align:center;"
        >{{resume.profile.firstname +" " +resume.profile.surname}}</h2>
        <h2 v-if="resume.currentstep!=17 && !resume.profile.firstname"
          style="text-transform:uppercase; font-size:36px; font-weight:600; margin:0;letter-spacing:1px; text-align:center;"
        >Suresh Raina</h2>
        <span
          style="display:block; text-align:center; font-size:14px; text-transform:uppercase; margin:10px 0 0; font-weight:600;"
        >{{resume.profile.profession}}</span>
      </div>

      <div
        class="main-container"
        style="display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Montserrat, sans-serif;"
      >
        <div
          class="left-container blue-bg" :style="{background:resume.profile.color}"
          style="width: 30%;color: #fff;padding:50px 0;"
        >
          <div style="padding: 10px 15px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Contact</h4>
          </div>

          <div style="margin: 10px 15px 20px;padding-bottom: 15px;" :style="{borderBottom:1+'px '+'dotted '+ resume.profile.color }">
            <div v-if="resume.profile&&resume.profile.phone" style="display:flex; margin-bottom:15px;">
              <span
                style=" display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/phone-disconnected.png"
                  alt="phone-disconnected"
                >
              </span>
              <p style="margin:3px 0 0 5px;">{{resume.profile.phone}}</p>
            </div>
            <div v-if="resume.profile&&resume.profile.email" style="display:flex; margin-bottom:15px;">
              <span
                style="bdisplay:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/new-post--v1.png"
                  alt="new-post--v1"
                >
              </span>
              <p style="margin:0 0 0 5px;">{{resume.profile.email}}</p>
            </div>
            <div v-if="resume.currentstep!=17 && !(resume.profile&&resume.profile.email)" style="display:flex; margin-bottom:15px;">
              <span
                style="bdisplay:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/new-post--v1.png"
                  alt="new-post--v1"
                >
              </span>
              <p style="margin:0 0 0 5px;">suresh@email.com</p>
            </div>
            <div v-if="resume.profile&&resume.profile.linkedin" style="display:flex; margin-bottom:15px;">
              <span
                style="display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/linkedin--v1.png"
                  alt="linkedin-2--v1"
                >
              </span>
              <p style="margin:3px 0 0 5px;">{{resume.profile.linkedin}}</p>
            </div>
            <div v-if="resume.profile&&resume.profile.website" style="display:flex; margin-bottom:15px;">
              <span
                style="display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/globe--v1.png"
                  alt="linkedin-2--v1"
                >
              </span>
              <p style="margin:3px 0 0 5px;">{{resume.profile.website}}</p>
            </div>
            <div v-if="resume.profile&&resume.profile.twitter" style="display:flex; margin-bottom:15px;">
              <span
                style="display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/twitter--v1.png"
                  alt="linkedin-2--v1"
                >
              </span>
              <p style="margin:3px 0 0 5px;">{{resume.profile.twitter}}</p>
            </div>
            <div v-if="resume.profile&&resume.profile.address" style="display:flex;">
              <span
                style="display:flex; width:24px; height:24px;align-items:center; justify-content:center; min-width:24px;"
              >
                <img
                  width="18"
                  height="18"
                  src="https://img.icons8.com/ios/24/ffffff/home--v1.png"
                  alt="home--v1"
                >
              </span>
              <p style="margin:3px 0 0 5px;">{{resume.profile.address}}</p>
            </div>
          </div>

          <div v-if="resume.skills.length>0" style="padding: 10px 15px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Skills</h4>
          </div>
          <div v-if="resume.skills.length>0" :style="{borderBottom:1+'px '+'dotted '+ resume.profile.color }" style="margin: 0 15px 20px;padding-bottom: 15px;">
            <ul v-for="(item,index) in resume.skills"  style="list-style: none;padding-left: 0; margin:0;">
               <li style=" padding-bottom: 10px;">{{item.text}}
                 <div style="display:block;">
                  <span
                  v-for="(data,index) in item.rate"
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span> -->
                  <span v-if="(5-item.rate)!=5"
                  v-for="(data,index) in (5-item.rate)"
                    style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                  ></span>
                </div>
               </li>
              
              
            </ul>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.skills.length>0" style="padding: 10px 15px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Skills</h4>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.skills.length>0" :style="{borderBottom:1+'px '+'dotted '+ resume.profile.color }" style="margin: 0 30px 20px;padding-bottom: 15px;">
            <ul style="list-style: none;padding-left: 0; margin:0;">
               <li style=" padding-bottom: 10px;color:grey;">
                 Accurate money handling
               </li>
              <li style=" padding-bottom: 10px;color:grey;">
                 Sales Enterprising
               </li>
              <li style=" padding-bottom: 10px;color:grey;">
                 Store opening and closing 
               </li>
            </ul>
          </div>
          <div v-if="resume.education&&resume.education.length>0" style="padding: 10px 15px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Education</h4>
          </div>
          <div v-if="resume.education&&resume.education.length>0" v-for="(item,index) in resume.education" style="margin: 0 15px 20px;padding-bottom: 15px;">
            <div>
              <p style="font-weight:600; margin:0; padding:0; font-size:14px;">{{ item.degree }}</p>
              <p
                style="font-weight:600; margin:5px 0 0; padding:0; font-size:14px;"
              >{{ item.school_name }} ({{ item.school_location }})</p>
              <span style="display:block;font-size:14px; margin:15px 0 10px;font-weight: 600;  max-width:87px; width:100%;">{{ item.grad_startdate }} - {{ item.grad_enddate }}</span>
              <p
                style="margin:0; padding:0;font-size:14px;"
                v-html="item.description"
              ></p>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" style="padding: 10px 15px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Education</h4>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" style="margin: 0 15px 20px;padding-bottom: 15px;">
            <div>
              <p style="font-weight:600; margin:0; padding:0; font-size:14px;color:grey;">Msc</p>
              <p
                style="font-weight:600; margin:5px 0 0; padding:0; font-size:14px;color:grey;"
              >Oxford University (Mumbai,India)</p>
              <span style="display:block;font-size:14px; margin:15px 0 10px;color:grey;">01/2013 - 03/2016</span>
              <p
                style="margin:0; padding:0;font-size:14px;color:grey;"
                
              >Dean's list for the 2015–2016 academic year</p>
            </div>
          </div>
          <!-- <div style="margin: 0 30px 20px;border-bottom: 1px dotted#ddd;padding-bottom: 15px;">
            <div>
              <p style="font-weight:600; margin:0; padding:0; font-size:14px;">MBA</p>
              <p
                style="font-weight:600; margin:5px 0 0; padding:0; font-size:14px;"
              >JECRC University Jaipur (Raj.)</p>
              <span style="display:block;font-size:14px; margin:15px 0 10px;">2021 - 2023</span>
              <p
                style="margin:0; padding:0;font-size:14px;"
              >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed semper, ex id suscipit hendrerit, magna</p>
            </div>
          </div> -->
        </div>

        <div class="right-container" :style="{borderTop:1+'px '+'solid '+ resume.profile.color }" style="width: 70%;padding: 20px; ">
          <div v-if="resume.summary" style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Profile</h4>
          </div>
          <div v-if="resume.summary" :style="{borderBottom:1+'px '+'dotted '+ resume.profile.color }" style="margin: 0 0 20px;padding-bottom: 15px;">
            <p v-html="resume.summary" style="font-size: 16px;line-height: 1.3;margin:0;">            
            </p>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.summary" style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
            <h4
              style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
            >Profile</h4>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.summary" :style="{borderBottom:1+'px '+'dotted '+ resume.profile.color }" style="margin: 0 0 20px;padding-bottom: 15px;">
            <p  style="font-size: 16px;line-height: 1.3;margin:0;color:grey;"> Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.           
            </p>
          </div>
          <div>
            <div v-if="resume.workExperience&&resume.workExperience.length>0" style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >Work Experience</h4>
            </div>
            <div v-if="resume.workExperience&&resume.workExperience.length>0" v-for="(item,index) in resume.workExperience" style="margin:15px 0 30px;">
              <h2 style="font-size: 17px;font-weight: 600;margin: 0 0 10px;">{{ item.jobtitle }}</h2>
              <div style="display:flex; justify-content:space-between;">
                <span
                  style="display: block; font-size:16px; font-weight:600;"
                >{{ item.employer }}, {{ item.city+" " }} {{ item.country }}</span>
                <span
                  style="font-weight: 600;display: block; font-size:16px; max-width:87px; width:100%;"
                >{{ item.startdate }} - {{ item.enddate }}</span>
              </div>
              <div style="margin:15px 0 0;">
                <ul v-html="item.description" style="list-style: disc;padding-left: 15px; margin:0;">
                  <!-- <li style="padding-bottom: 10px;">Aenean sem tellus, placerat et varius eget.</li> -->
                </ul>
              </div>
            </div>
            <div v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)" style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >Work Experience</h4>
            </div>
            <div v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)"  style="margin:15px 0 30px;">
              <h2 style="font-size: 17px;font-weight: 600;margin: 0 0 10px;color:grey;">Software Engineer</h2>
              <div style="display:flex; justify-content:space-between;">
                <span
                  style="display: block; font-size:16px; font-weight:600;color:grey;"
                >Compubotics, Banglore India</span>
                <span
                  style="font-weight: 600;display: block; font-size:16px;color:grey;"
                >07/2017 - Current</span>
              </div>
              <div style="margin:15px 0 0;">
                <ul  style="list-style: disc;padding-left: 15px; margin:0;">
                  <li style="padding-bottom: 10px;color:grey;">Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.accomplishments_text!='None'" :style="{borderBottom:1+'px '+'dotted '+ resume.profile.color,borderTop:1+'px '+'dotted'+resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >ACCOMPLISHMENTS</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="resume.extraFields.accomplishments_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.affiliations_text!='None'" style="padding-top:15px;border-top: 1px dotted#000;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >AFFILIATIONS</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="resume.extraFields.affiliations_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.additional_information_text!='None'" :style="{borderTop:1+'px '+'dotted '+ resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >ADDITIONAL INFORMATION</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="resume.extraFields.additional_information_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.software_text!='None'" :style="{borderTop:1+'px '+'dotted '+ resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >SOFTWARE</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="resume.extraFields.software_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.languages_text!='None'" :style="{borderTop:1+'px '+'dotted '+ resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >LANGUAGES</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="resume.extraFields.languages_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.certifications_text!='None'" :style="{borderTop:1+'px '+'dotted '+ resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >CERTIFICATIONS</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p  v-html="resume.extraFields.certifications_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.interests_text!='None'" :style="{borderTop:1+'px '+'dotted '+ resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >INTERESTS</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="resume.extraFields.interests_text" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.add_your_own_json.length>0"
            v-for="(data,index) in resume.extraFields.add_your_own_json" :style="{borderTop:1+'px '+'dotted '+ resume.profile.color }" style="padding-top:15px;">
            <div style="font-size: 18px;font-weight: 600;margin: 20px 0 10px;">
              <h4
                style="font-size: 18px;margin:0; text-transform:uppercase; letter-spacing:1px;"
              >{{data.title}}</h4>
            </div>
            <div style="    margin: 0 0 20px;padding-bottom: 15px;">
              <p v-html="data.body" style="font-size: 16px;line-height: 1.3;margin:0;">
              </p>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {
    showClosePopUp() {
      var self = this;
      self.showmodal = self.showlist;
    }
  },
  methods: {
    closePopUp: function(item) {
      if (item === "goBack") {
        if (this.parentObj.$data.showResumetwo) {
          this.parentObj.$data.showResumetwo = false;
        }
      }
    },
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        Color:self.resume.color,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: "resume5.png"
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      // let formData = new FormData();
      // const pdfBlob = await html2pdf().from(pdfContent).outputPdf();
      // const blobPartArray = await new Response(pdfBlob).arrayBuffer(); // Convert Blob to BlobPart array

      // const formData = new FormData();
      // formData.append('file', new Blob([blobPartArray]), 'output.pdf');

      // formData.append('pdfFile', pdfBlob, 'output.pdf');
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        // url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
      //const pdfBuffer =  pdfBlob.arrayBuffer();
      //   const s3 = new AWS.S3({
      //   accessKeyId: 'AKIA5BLF7Z4HNIYBATMF',
      //   secretAccessKey: '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',
      //   region: 'us-east-1',
      // });

      // // Define S3 upload parameters
      // const params = {
      //   Bucket: 'documents-gen-ehr-dev',
      //   Key: 'shri@mailinator.com/document.pdf', // Desired name for the uploaded file
      //   Body: pdfBlob,
      //   ContentType: 'application/pdf',
      //   ACL: 'private',
      // };

      // // Upload to S3
      // s3.upload(params, (error, data) => {
      //   if (error) {
      //     console.error('Error uploading to S3:', error);
      //   } else {
      //     console.log('PDF uploaded to S3:', data.Location);
      //   }
      // });
      //  html2pdf(document.getElementById("resumesec"), {
      // 		margin: 1,
      // 	filename: "i-was-html.pdf",
      // 	});
    }
  }
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease-in-out;
}
/* .modal-wrapper {
  display: table-cell;
  vertical-align: middle;
} */
.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 50px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: opacity 0.5s ease-in-out;
}
.modal-mask.upl-holdNEW.wdth-700 .modal-container {
  width: 700px;
}

.modal-mask:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

.modal-wrapper {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

