<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="word-wrap:break-word;display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div
          style="width: 30%;color: #fff; padding:30px 15px; background:#2a2e37;"
        >
          <div class="img-box" style="padding:0;position: relative;z-index: 1;">
            <img v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
              style="max-width: 100%;width: 100px;object-fit: cover;height: 100px;margin: 0 auto;display: block;"
              :src="resume.pic"
              alt
            >
            <img v-else
              style="max-width: 100%;width: 100px;object-fit: cover;height: 100px;margin: 0 auto;display: block;"
              src="https://img.freepik.com/free-photo/handsome-man-with-glasses_144627-18665.jpg?w=360&t=st=1694776719~exp=1694777319~hmac=47469ae6d7ce2888947cad33a4b8ab5cba0c7db1b96fed5e459d9bf559abbc8d"
              alt
            >
          </div>

          <div style>
            <div style="padding:20px 0">
              <div v-if="resume.profile.address" style="display:flex; margin-bottom:15px;">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px; display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/000000/home--v1.png"
                    alt="home--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.address}}</p>
              </div>
              <div v-if="resume.profile.phone" style="display:flex; margin-bottom:15px;">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px; display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/000000/phone-disconnected.png"
                    alt="phone-disconnected"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.phone}}</p>
              </div>
              <div v-if="resume.profile.linkedin" style="display:flex; margin-bottom:15px; ">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px; display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/color/36/linkedin.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.linkedin}}</p>
              </div>
                <div v-if="resume.profile.website" style="display:flex; margin-bottom:15px; ">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px;display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/glyph-neue/24/000000/globe--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.website}}</p>
              </div>
              <div v-if="resume.profile.twitter" style="display:flex; margin-bottom:15px; ">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px; display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/glyph-neue/36/5B94DE/twitter--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.twitter}}</p>
              </div>
              <div v-if="resume.profile.email" style="display:flex; margin-bottom:15px;">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px; display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/000000/new-post--v1.png"
                    alt="new-post--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.email}}</p>
              </div>
              <div v-if="resume.currentstep!=17 && !resume.profile.email" style="display:flex; margin-bottom:15px;">
                <span :style="{background:resume.profile.color}"
                  style=" width:24px; height:24px;min-width:24px; display:flex; align-items:center; justify-content:center; padding:2px;"
                >
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/000000/new-post--v1.png"
                    alt="new-post--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;color:grey;">suresh@email.com</p>
              </div>
            </div>
          </div>

          <div v-if="resume.education&&resume.education.length>0" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style=" color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Education</div>
            <div style="padding:20px 0">
              <div v-for="(item,index) in resume.education" style="margin-bottom:15px;">
                <p style="margin:0 0 5px; font-weight:500; font-size:18px;">{{item.degree +" "+item.field }}</p>
                <span style="margin:0 0 5px; display:block; font-size:16px;">{{ item.school_name }}</span>
                <span style="margin:0 0 5px; display:block; font-size:16px;">{{item.grad_startdate }} - {{item.grad_enddate}}</span>
              </div>

              
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style=" color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Education</div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p style="margin:0 0 5px; font-weight:500; font-size:18px;color:grey;">Msc Computer Science</p>
                <span style="margin:0 0 5px; display:block; font-size:16px;color:grey;">Oxford University</span>
                <span style="margin:0 0 5px; display:block; font-size:16px;color:grey;">01/2013 - 03/2016</span>
              </div>

              
            </div>
          </div>
          <div v-if="resume.skills && resume.skills.length>0 " style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Expertise</div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <ul  style="margin:0 0 10px; font-weight:500; font-size:16px; padding:0; list-style: none;">
                    <li v-for="(item,index) in resume.skills">{{item.text}}
                        <div style="display:block;">
                  <span
                  v-for="(data,index) in item.rate"
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span> -->
                  <span v-if="(5-item.rate)!=5"
                  v-for="(data,index) in (5-item.rate)"
                    style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                  ></span>
                </div>
                    </li>
                    
                </ul>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.skills && resume.skills.length>0) " style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Expertise</div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <ul  style="margin:0 0 10px; font-weight:500; font-size:16px;color:grey; padding:0;list-style: none;">
                    <li >
                      Store opening and closing
                    </li>
                    <li >
                      Accurate money handling
                    </li>
                    <li >
                      Sales Enterprising
                    </li>
                </ul>
                
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.accomplishments_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Accomplishment</div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.accomplishments_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.affiliations_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Affiliation</div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.affiliations_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.additional_information_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Additional Information</div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.additional_information_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.software_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style=" color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Software </div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.software_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.languages_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style=" color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Languages </div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.languages_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.certifications_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Certification </div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.certifications_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.interests_text!='None'" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Interests </div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="resume.extraFields.interests_text"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
          <div v-if="resume.extraFields.add_your_own_json.length>0"
            v-for="(data,index) in resume.extraFields.add_your_own_json" style="padding-left:0;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="color:#fff; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >{{data.title}} </div>
            <div style="padding:20px 0">
              <div style="margin-bottom:15px;">
                <p  style="margin:0 0 10px; font-weight:500; font-size:16px;" v-html="data.body"></p>
                <!-- <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Branding</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Copywrighting</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Design</p>
                <p style="margin:0 0 10px; font-weight:500; font-size:16px;">Others</p> -->
              </div>
            </div>
          </div>
        </div>

        <div style="width: 70%;padding: 30px;">
          <div :style="{background:resume.profile.color}"
            style="color: #fff; position: relative;left: -30px;  width: calc(100% + 60px); padding: 30px;    box-sizing: border-box;"
          >
            <h1 v-if="resume.profile.firstname"
              style="display:block; word-wrap:break-word;text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
            >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
            <h1 v-if="resume.currentstep!=17 && !resume.profile.firstname"
              style="display:block; text-transform:uppercase; font-size: 36px;font-weight: 700; margin:0;"
            >Suresh Raina</h1>
            <span
              style="display:block; text-transform:uppercase; font-weight:500; letter-spacing:1px;"
            >{{resume.profile.profession}}</span>
          </div>
          <div v-if="resume.summary" style="margin-top:20px;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="font-weight:500; color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Profile</div>
            <div style="position:relative; display:flex;padding-top:30px;">
              <div style="background:#000; width:2px; position:relative; "></div>
              <div style>
                <div>
                  <span 
                    style="background:#fff;  border: 2px solid #000; width:10px; height:10px; border-radius:50%; position:absolute; left:-5px;"
                  ></span>
                  <div style="padding-left:25px; padding-bottom:25px;">
                    <p v-html="resume.summary" style="margin:0 0 10px; font-size:16px;">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.summary" style="margin-top:60px;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="font-weight:500; color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Profile</div>
            <div style="position:relative; display:flex;padding-top:30px;">
              <div style="background:#000; width:2px; position:relative; "></div>
              <div style>
                <div>
                  <span
                    style="background:#fff;  border: 2px solid #000; width:10px; height:10px; border-radius:50%; position:absolute; left:-5px;"
                  ></span>
                  <div style="padding-left:25px; padding-bottom:25px;">
                    <p  style="margin:0 0; font-size:16px;color:grey;">Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="resume.workExperience&&resume.workExperience.length>0" style="margin-top:60px;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="font-weight:500; color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Experience</div>
            <div style="position:relative; display:flex;padding-top:30px;">
              <div style="background:#000; width:2px; position:relative; "></div>
              <div style>
                <div v-for="(item,index) in resume.workExperience">
                  <span
                    style="background:#fff;  border: 2px solid #000; width:10px; height:10px; border-radius:50%; position:absolute; left:-5px;"
                  ></span>
                  <div style="padding-left:25px; padding-bottom:25px;">
                    <h5
                      style="margin:0 0 5px; font-size:15px;font-weight:500; color:#666;"
                    >{{item.startdate}} to {{item.enddate}}</h5>
                    <h4
                      style="margin:0 0 5px; font-size:15px; font-weight:500;color:#666;"
                    >{{item.employer}}</h4>
                    <h6 style="margin:0; font-size:18px; font-weight:500;">{{item.jobtitle}}</h6>
                    <p v-html="item.description" style="margin:0 0 10px; font-size:16px;">
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)" style="margin-top:60px;">
            <div :style="{borderBottom:2+'px '+'solid '+ resume.profile.color }"
              style="font-weight:500;color:#2a2e37; padding:10px 0; font-size:18px; text-transform:uppercase;"
            >Experience</div>
            <div style="position:relative; display:flex;padding-top:30px;">
              <div style="background:#000; width:2px; position:relative; "></div>
              <div style>
                <div >
                  <span
                    style="background:#fff;  border: 2px solid #000; width:10px; height:10px; border-radius:50%; position:absolute; left:-5px;"
                  ></span>
                  <div style="padding-left:25px; padding-bottom:25px;">
                    <h5
                      style="margin:0 0 5px; font-size:15px;font-weight:500; color:grey;"
                    >07/2017 - Current</h5>
                    <h4
                      style="margin:0 0 5px; font-size:15px; font-weight:500;color:grey;"
                    >Compubotics</h4>
                    <h6 style="margin:0; font-size:18px; font-weight:500;color:grey;">Software Engineer</h6>
                    <p  style="margin:0 0 10px; font-size:16px;color:grey;">
                      Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {},
  methods: {
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Color:self.resume.color,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


