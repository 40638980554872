import PromiseWorker from 'promise-worker'
import Worker from 'worker-loader!./worker'

const worker = new Worker();
const promiseWorker = new PromiseWorker(worker);

const send = message => promiseWorker.postMessage({
  type: 'message',
  message
});

export default {
	//DEV Keys
	region: process.env.VUE_APP_AWS_REGION,
	IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
	UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
	ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
	send,
	printsomthing()
	{
		console.log("this.region>>",this.region,process.env.VUE_APP_ASHISH);
		console.log("this.IdentityPoolId>>",this.IdentityPoolId);
		console.log("this.UserPoolId>>",this.UserPoolId);
		console.log("this.ClientId>>",this.ClientId);
	}
};