<template>
  <div>
    <Header :heading="'Change password'"></Header>
    <div class="main-section">
      <Leftmenu></Leftmenu>
      <div class="right-section">
        <div class="changepw-hold">
          <div class="border-radius-box">
            <h3>Change Password</h3>
            <div class="alert alert-danger" v-if="oldpasserror">
              <p v-if="oldpasserror" class="error">{{ oldpasserror }}</p>
            </div>
            <div class="mart-40">
              <div class="form-group">
                <label for="psw">
                  <b>Enter your current password</b>
                </label>
                <input
                  type="password"
                  v-model="oldpass"
                  name="psw"
                  class="password-field form-control"
                  id="Password"
                  autocomplete="off"
                  required
                  :maxlength="maxpswdCharLen"
                  placeholder=" "
                />
                <div class="input-visible">
                  <span
                    @click="ShowPassword"
                    v-bind:class="{
                      'signin-pass-visible':
                        !showHideFlag && oldpass.length > 0,
                      'signin-pass-hidden': showHideFlag && oldpass.length > 0,
                    }"
                  ></span>
                </div>
                
                <span v-show="errors.isOldPswdFormatValid" class="error-message"
                  >Please input a valid password</span
                >
              </div>
              <div class="form-group changepw-icons">
                <label for="psw">
                  <b>New password</b>
                </label>
                <input
                  type="password"
                  class="password-field form-control"
                  id="Password2"
                  name="psw"
                  v-model="newpass"
                  autocomplete="off"
                  required
                  :maxlength="maxpswdCharLen"
                  placeholder=" "
                />
                <div class="input-visible">
                  <span
                    @click="ShowPassword2"
                    v-bind:class="{
                      'signin-pass-visible':
                        !showHideFlag2 && newpass.length > 0,
                      'signin-pass-hidden': showHideFlag2 && newpass.length > 0,
                    }"
                  ></span>
                </div>
                <div class="password-field input-visible">
                  <p>Password must contain :</p>
                  <ul>
                    <li
                      v-bind:class="{
                        'password-valid': hasUpperCaseChar,
                        'password-invalid': !hasUpperCaseChar,
                      }"
                    >
                      At least 1 upper case letter ( A - Z )
                    </li>
                    <li
                      v-bind:class="{
                        'password-valid': hasLowerCaseChar,
                        'password-invalid': !hasLowerCaseChar,
                      }"
                    >
                      At least 1 lower case letter ( a - z )
                    </li>
                    <li
                      v-bind:class="{
                        'password-valid': hasNumberChar,
                        'password-invalid': !hasNumberChar,
                      }"
                    >
                      At least 1 numeric character ( 0 - 9 )
                    </li>
                    <li
                      v-bind:class="{
                        'password-valid': hasSpecialChar,
                        'password-invalid': !hasSpecialChar,
                      }"
                    >
                      At least 1 special case character
                    </li>
                    <li
                      v-bind:class="{
                        'password-valid': hasPassLength,
                        'password-invalid': !hasPassLength,
                      }"
                    >
                      At least 8 characters
                    </li>
                  </ul>
                </div>
                <span v-show="errors.isNewPswdFormatValid" class="error-message"
                  >Please input a valid password</span
                >
                <span
                  v-show="errors.isNewPswdLengthFormatValid"
                  class="error-message"
                >
                  Maximumm password length reached.
                  <br />
                  It should be between 8 and {{ maxpswdCharLen }}
                </span>
                
              </div>
              <div
                class="form-group changepw-icons"
                v-bind:class="{
                  'error-msg-hold': confirmpass && confirmpass !== newpass,
                }"
              >
              <label for="psw">
                  <b>Confirm password</b>
                </label>
                <input
                  type="password"
                  v-model="confirmpass"
                  class="password-field form-control"
                  id="Password1"
                  autocomplete="off"
                  name="psw"
                  required
                />
                <div class="input-visible">
                  <span
                    @click="ShowPassword1"
                    v-bind:class="{
                      'signin-pass-visible':
                        !showHideFlag1 && confirmpass.length > 0,
                      'signin-pass-hidden':
                        showHideFlag1 && confirmpass.length > 0,
                    }"
                  ></span>
                </div>
                
                <span v-if="confirmpass && newpass != confirmpass" class="error"
                  >New password and confirm password does not match</span
                >
              </div>
              <div class="changepw-btn-hold row">
                <div class="col-md-12">
                  <a
                    href="#"
                    @click.prevent="passwordChange()"
                    class="btn btn-primary changepw-btn"
                    >Confirm</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Messages
        :datamessage="sucessmsgtext"
        :iserror="iserrormsg"
        @closemsg="closeMessage"
        :isVisible="isdisplaymsg"
      ></Messages>
    </transition>
    <div v-if="loader" class="template-loader-hiw hide">
      <div class="loader-overlay"></div>
      <div class="progress-bar-wrapper">
        <img
          src="../../assets/check-circle.svg"
          v-if="finished"
          alt="Check Circle"
          width="48"
          height="48"
          class="icon-check-circle hide"
        />
        <p class="progress-text step1">{{ loadermsg }}</p>
        <div v-if="!finished" class="progress-bar">
          <div class="progress-linear"></div>
          <div class="progress-gradient progress-animate"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import myWorker from "@/config";
import Leftmenu from "@/components/Leftmenu.vue";
import Header from "@/components/Header.vue";
import Messages from "@/components/MessagePopup/Messages.vue";
export default {
  components: {
    Header,
    Leftmenu,
    Messages,
  },
  data() {
    return {
      loader: false,
      finished: false,
      loadermsg: "",
      oldpasserror: "",
      sucessmsgtext: "",
      iserrormsg: false,
      isdisplaymsg: false,
      oldpass: "",
      newpass: "",
      confirmpass: "",
      error: null,
      errors: {},
      showerr: false,
      hasLowerCaseChar: false,
      hasUpperCaseChar: false,
      hasSpecialChar: false,
      hasPassLength: false,
      hasNumberChar: false,
      isaccountconfirm: false,
      pswdRegEx: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,30}/,
      maxpswdCharLen: 50,
      validationResult: false,
      showHideFlag: false,
      showHideFlag1: false,
      showHideFlag2: false,
      staffid: "",
      isprovider: "",
      loggeedin_userinfo: null,
    };
  },
  created() {
    if (window.localStorage.getItem("accesstoken")) {
      this.authenticationToken = window.localStorage.getItem("accesstoken");
    }
  },
  watch: {
    newpass: function (val, oldval) {
      var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      var numericformat = /[0-9]/;
      if (val) {
        if (val.toUpperCase() != val) {
          this.hasLowerCaseChar = true;
        } else {
          this.hasLowerCaseChar = false;
        }
        if (val.toLowerCase() != val) {
          this.hasUpperCaseChar = true;
        } else {
          this.hasUpperCaseChar = false;
        }
        if (numericformat.test(val)) {
          this.hasNumberChar = true;
        } else {
          this.hasNumberChar = false;
        }
        if (format.test(val)) {
          this.hasSpecialChar = true;
        } else {
          this.hasSpecialChar = false;
        }
        if (val.length >= 8) {
          this.hasPassLength = true;
        } else {
          this.hasPassLength = false;
        }

        if (
          this.hasLowerCaseChar &&
          this.hasUpperCaseChar &&
          this.hasNumberChar &&
          this.hasSpecialChar &&
          this.hasPassLength
        ) {
          if (val.length <= this.maxpswdCharLen) {
            if (val.length < this.maxpswdCharLen) {
              delete this.errors.isNewPswdLengthFormatValid;
            }
            delete this.errors.isNewPswdFormatValid;
          } else {
            this.newpass = oldval;
            this.errors.isNewPswdLengthFormatValid = true;
          }
          this.validationResult = true;
        } else {
          this.validationResult = false;
          this.errors.isNewPswdFormatValid = true;
        }
      } else {
        this.hasLowerCaseChar = false;
        this.hasUpperCaseChar = false;
        this.hasNumberChar = false;
        this.hasSpecialChar = false;
        this.hasPassLength = false;
        this.validationResult = false;
        delete this.errors.isNewPswdLengthFormatValid;
        delete this.errors.isNewPswdFormatValid;
      }
    },
  },
  methods: {
    ShowPassword() {
      var typ = document.getElementById("Password").getAttribute("type");
      if (typ == "password") {
        document.getElementById("Password").setAttribute("type", "text");
        this.showHideFlag = !this.showHideFlag;
      } else {
        document.getElementById("Password").setAttribute("type", "password");
        this.showHideFlag = !this.showHideFlag;
      }
    },
    ShowPassword1() {
      var typ = document.getElementById("Password1").getAttribute("type");
      if (typ == "password") {
        document.getElementById("Password1").setAttribute("type", "text");
        this.showHideFlag1 = !this.showHideFlag1;
      } else {
        document.getElementById("Password1").setAttribute("type", "password");
        this.showHideFlag1 = !this.showHideFlag1;
      }
      //return type;
    },
    ShowPassword2() {
      var typ = document.getElementById("Password2").getAttribute("type");
      if (typ == "password") {
        document.getElementById("Password2").setAttribute("type", "text");
        this.showHideFlag2 = !this.showHideFlag2;
      } else {
        document.getElementById("Password2").setAttribute("type", "password");
        this.showHideFlag2 = !this.showHideFlag2;
      }
      //return type;
    },
    closeMessage() {
      this.isdisplaymsg = false;
    },
    passwordChange() {
      debugger;
      var self = this;
      self.oldpasserror = "";
      var userinfo;
      if (localStorage.getItem("loggedinuserobj")) {
        userinfo = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      if (self.oldpass) {
        delete self.errors.isOldPswdFormatValid;
        if (self.validationResult) {
          if (self.newpass == self.confirmpass) {
            self.loaderms = "Changing password";
            self.loader = true;

            axios({
              method: "post",
              url: rest_service.baseURL + "/User/ChangePassword",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + self.authenticationToken,
              },
              data: {
                Email: userinfo.user.email,
                OldPassword: self.oldpass,
                NewPassword: self.newpass,
              },
            })
              .then(function (response) {
                if (response.data.result) {
                  if (response.data.result == "Success") {
                    self.oldpass="";
                    self.newpass="";
                    self.confirmpass="";
                    self.sucessmsgtext = "Password changed successfully";
                    self.isdisplaymsg = true;
                    self.iserrormsg = false;
                    setTimeout((xy) => {
                      self.closeMessage();
                    }, 5000);
                  } else if (response.data.result == "NotAuthorized") {
                    self.oldpasserror =
                      "Current password provided is incorrect";
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Add smooth scrolling behavior if you want it to be animated
                    });
                  } else {
                    self.sucessmsgtext =
                      "Something went wrong please try again";
                    self.isdisplaymsg = true;
                    self.iserrormsg = true;
                    setTimeout((xy) => {
                      self.closeMessage();
                    }, 5000);
                  }
                  self.loaderms = "";
                  self.loader = false;
                } else {
                  self.loaderms = "";
                  self.loader = false;
                  return null;
                }
              })
              .catch(function (error) {
                self.loaderms = "";
                self.loader = false;
                console.log(error);
                return error;
              });
          } else {
            self.showerr = true;
          }
        }
      } else {
        self.errors = {};
        self.errors.isOldPswdFormatValid = "true";
      }
    },
    clearerr() {
      this.showerr = false;
    },
  },
};
</script>

<style scoped type="text/css">
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
html,
body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100%;
}
html {
  font-size: 14px;
  height: 100%;
}
body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #000000;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* body {position: relative;  padding-bottom: 70px !important;   min-height:100%;} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
a {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: color, background-color, opacity;
}
a:hover {
  text-decoration: none !important;
}
/*Heading*/
h1 {
  font-size: 28px;
  font-weight: 500;
}
h2 {
  font-size: 28px;
  font-weight: 400;
}
h3 {
  font-size: 22px;
  font-weight: normal;
}
h4 {
  font-size: 19px;
  font-weight: normal;
}
h5 {
  font-size: 17px;
  font-weight: 500;
}
/*margin*/
.mart-40 {
  margin-top: 40px !important;
}
.mart-30 {
  margin-top: 30px !important;
}
.primary-btn {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  background: #0056e0;
  color: #ffffff;
  border: solid 1px #0056e0;
  border-radius: 0px;
  text-decoration: none;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 115px;
  float: left;
  text-align: center;
}
.primary-btn:hover {
  background: #0043a4;
  color: #ffffff;
  text-decoration: none;
  border-color: #0043a4;
}
.secondary-btn {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  background: #ffffff;
  color: #0056e0;
  border: solid 1px #0056e0;
  border-radius: 0px;
  text-decoration: none;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 115px;
  float: left;
  text-align: center;
}
.secondary-btn:hover {
  background: #0056e0;
  color: #ffffff;
  text-decoration: none;
  border-color: #0056e0;
}

.ripple {
  position: relative;
  overflow: hidden;
}
.ripple:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
.resetconfirm-frame-outer {
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: auto;
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
.ripple:focus {
  box-shadow: none;
}
.ripple:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
/*Sign in*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.change-pwmain {
  width: 100%;
  height: 100%;
  display: table;
  min-height: 100%;
}
.changepw-container {
  margin-top: 20px;
}
.changepw-container input {
  display: block;
  width: 100%;
  background: #ffffff !important;
  height: 40px !important;
  border: none !important;
  border-bottom: solid 2px #d2d4d5 !important;
  padding-left: 2px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #1d1d1d !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.changepw-container input:focus,
.changepw-container input:valid {
  box-shadow: none;
  outline: none;
  border-color: #0056e0 !important;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.changepw-container input:focus ~ label,
.changepw-container input:valid ~ label {
  top: -15px;
  font-size: 13px;
  color: #0056e0;
  font-weight: lighter;
}
.changepw-container input:not(:placeholder-shown) ~ label {
  top: -15px;
  font-size: 13px;
  color: #0056e0;
  font-weight: lighter;
}
.changepw-container label {
  position: absolute;
  top: 8px;
  left: 0px;
  pointer-events: none;
  color: #606060;
  font-size: 14px;
  font-weight: lighter;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.changepw-container {
  width: 100%;
  float: left;
}
.changepw-hold {
  width: 500px;
  margin: 0px;
}
.changepw-inn-hold {
  max-width: 100%;
  margin: 0;
  padding: 40px 80px 60px 80px;
}
.changepw-icons {
  position: relative;
}
.changepw-btn-hold {
  margin-top: 40px;
}
.changepw-btn {
  float: none !important;
  margin: 0;
  padding: 10px 45px;
  display: block;
  width: 150px;
}

.password-field:focus ~ .input-visible,
.password-field:valid ~ .input-visible {
  display: block;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
 .signin-pass-hidden:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat 2px -151px;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 5px;
  top: 35px;
} 
 .signin-pass-visible:after,
.signin-pass-visible1:after,
.signin-pass-visible2:after {
  content: "";
  background: url(../../assets/signin-icon.png) no-repeat 2px -110px;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 5px;
  top: 35px;
} 
.log-change-pass .signin-pass-visible:after,
.log-change-pass .signin-pass-visible1:after,
.log-change-pass .signin-pass-visible2:after,
.log-change-pass .signin-pass-hidden:after,
.log-change-pass .signin-pass-hidden:after,
.log-change-pass .signin-pass-hidden:after {
  top: 8px;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}
.erroe-message {
  font-size: 13px;
  color: #f44336;
  text-align: left;
  float: left;
  margin-top: 5px;
}
.password-field {
  margin-top: 5px;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.password-field p {
  text-align: left;
  color: #111111;
  font-weight: 500;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-top: 10px;
  position: relative;
}
/* .password-field p:after {
  content: "";
  background: url(../../static/images/signin-icon.png) no-repeat -40px -2px;
  width: 35px;
  height: 30px;
  position: absolute;
  display: block;
  left: -15px;
  top: -5px;
} */
.password-field ul li {
  font-weight: 500;
  color: #353b47;
  text-align: left;
  padding-left: 25px;
  position: relative;
  padding-top: 8px;
}
/* .password-field ul li::after {
  content: "";
  background: url(../../static/images/signin-icon.png) no-repeat -54px -93px;
  width: 35px;
  height: 30px;
  position: absolute;
  display: block;
  left: -1px;
  top: 2px;
} */
.password-field ul li.password-valid {
  color: #0056e0;
}
.password-field ul li.password-valid::after {
  background-position: -54px -30px;
}
.password-field ul li.password-current {
  color: #111111;
}
.password-field ul li.password-current::after {
  background-position: -54px -59px;
}
.password-field ul li.password-invalid {
  color: #f44539;
}
.password-field ul li.password-invalid::after {
  background-position: -54px -125px;
}
.signup-hold {
  border: solid 1px #dddddd;
  max-width: 890px;
  margin: 30px auto 0 auto;
  position: relative;
  border-radius: 5px;
}
.error-msg-hold label {
    color: #212529!important;
    position: static;
}
span.error-message {
    color: #f44539;
}
</style>