<template>
  <div>
    <!--Start-->
    <Header :heading="'Packages'"></Header>
    <div class="main-section">
      <Leftmenu></Leftmenu>
      <div class="right-section">
        <div class="section">
          <h3>Live Package(s)</h3>
          <div class="border-radius-box table-section small-size">
            <div class="inner-sec">
              <div class="table-responsive">
                <table v-if="ActivePackage.length > 0">
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>CV Limit</th>
                    <th>CV Used</th>
                    <th>Cover Letter Limit</th>
                    <th>Cover Letter Used</th>
                    <!-- <th>Interview Limit</th>
                    <th>Interview Used</th>
                    <th>Professional CV Writer</th>
                    <th>Professional CV Used</th>
                    <th>Professional Interviewer</th>
                    <th>Professional Interview Called</th>
                    <th>Cover Letter</th> -->
                  </tr>
                  <tr v-for="(item, index) in ActivePackage">
                    <td>1</td>
                    <td>{{ item.packageName }}</td>
                    <td>{{ formatDate(new Date(item.createddate)) }}</td>
                    <td>{{ formatDate(new Date(item.expirydate)) }}</td>
                    <td>{{ item.noCvs }}</td>
                    <td>{{ item.noCv }}</td>
                    <td>{{ item.noCoverLetter }}</td>
                    <td>{{ item.usedNoCoverLetter }}</td>
                    <!-- <td>5</td>
                    <td>5</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Lorem Ispum</td> -->
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h3 v-if="ExpiredPackage.length > 0">Expired Package(s)</h3>
          <div class="border-radius-box table-section small-size">
            <div class="inner-sec">
              <div class="table-responsive">
                <table v-if="ExpiredPackage.length > 0">
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>CV Limit</th>
                    <th>CV Used</th>
                    <th>Cover Letter Limit</th>
                    <th>Cover Letter Used</th>
                    <!-- <th>Interview Limit</th>
                    <th>Interview Used</th>
                    <th>Professional CV Writer</th>
                    <th>Professional CV Used</th>
                    <th>Professional Interviewer</th>
                    <th>Professional Interview Called</th>
                    <th>Cover Letter</th> -->
                  </tr>
                  <tr v-for="(item, index) in ExpiredPackage">
                    <td>1</td>
                    <td>{{ item.packageName }}</td>
                    <td>{{ formatDate(new Date(item.createddate)) }}</td>
                    <td>{{ formatDate(new Date(item.expirydate)) }}</td>
                    <td>{{ item.noCvs }}</td>
                    <td>{{ item.noCv }}</td>
                    <td>{{ item.noCoverLetter }}</td>
                    <td>{{ item.usedNoCoverLetter }}</td>
                    <!-- <td>5</td>
                    <td>5</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Lorem Ispum</td> -->
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="text-right mt-4">
            <button class="btn btn-primary" @click.prevent="ChangePlan">
              Buy New Package
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Leftmenu from "@/components/Leftmenu.vue";
import rest_service from "@/api/rest_service_api.js";

export default {
  components: {
    Header,
    Leftmenu,
  },
  data() {
    return {
      ActivePackage: [],
      ExpiredPackage: [],
    };
  },
  created() {
    this.GetPackage();
    this.GetExpPackage();
  },
  methods: {
    formatDate(date) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-GB", options); // Adjust the locale as needed
    },
    ChangePlan() {
      var self = this;
      this.$router.push({ path: "ChangePlan" });
    },
    GetPackage() {
      debugger;
      var self = this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserActivePackage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            self.ActivePackage = response.data.result;
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    GetExpPackage() {
      debugger;
      var self = this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserExpiredPackage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            self.ExpiredPackage = response.data.result;
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
  },
  mounted() {},
  name: "PurchasedPackages",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-section table td,
.table-section table th {
  white-space: nowrap;
}
</style>
