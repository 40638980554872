

<template>
  <div class="signin-main" id="signin_container">
    <div class="signin-container">
      <div class="signin-hold">
        <div class="alert alert-danger" v-if="isinvalidcred">
          <p class="mb-0">Incorrect Username or Password</p>
        </div>
        <a href="javascript:void(0);" class="logo-sign-hold">
          <div class="logo-sgncontainer">
            <img src="../../assets/CC-Logo-Blue.svg" class="logo-fixed" />
          </div>
        </a>
        <h3 class="mt-4 mb-4">Account Recovery</h3>
        <h6>
          Careercubicle will send a password reset link to your email address
        </h6>
        <form>
          <div class="mart-40">
            <div
              class="form-group signin-icons"
              v-bind:class="{ 'error-msg-hold': email && errormsg_email }"
            >
              <span class="signin-user"></span>

              <input
                type="text"
                name="email"
                v-model="email"
                class="form-control"
                autocomplete="off"
                placeholder=" "
                v-on:keyup.enter="nextPlease"
              />

              <label for="email">
                <b>Enter your email address</b>
              </label>
            </div>
            <span v-if="errormsg_email" style="color: #ff0000"
              >Please Enter Valid Email</span
            >
          </div>
          <div class="signin-btn-hold">
            <a
              href="#"
              :class="{
                'global-disabled': send_button,
              }"
              @click.prevent="Sendmail()"
              class="btn-primary md-ink-ripple signin-btn"
              >Send mail</a
            >
          </div>
        </form>
      </div>

      <div class="signin-bottom-hold">
        <div class="signin-help">
          <ul>
            <li>
              <a href="javascript:void(0);">Help</a>
            </li>
            <li class="divider-or">|</li>
            <li>
              <a href="javascript:void(0);">Privacy</a>
            </li>
            <li class="divider-or">|</li>
            <li>
              <a href="javascript:void(0);">Terms</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="loader" class="template-loader-hiw hide">
      <div class="loader-overlay"></div>
      <div class="progress-bar-wrapper">
        <img
          src="../../assets/check-circle.svg"
          v-if="finished"
          alt="Check Circle"
          width="48"
          height="48"
          class="icon-check-circle hide"
        />
        <p class="progress-text step1">{{ loadermsg }}</p>
        <div v-if="!finished" class="progress-bar">
          <div class="progress-linear"></div>
          <div class="progress-gradient progress-animate"></div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Messages
        :datamessage="sucessmsgtext"
        :iserror="iserrormsg"
        @closemsg="closeMessage"
        :isVisible="isdisplaymsg"
      ></Messages>
    </transition>
  </div>
</template>
  <script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api";
import myWorker from "@/config";
import moment from "moment";
import Messages from "@/components/MessagePopup/Messages.vue";
var tenantpostdat = {
  operation: "read",
  payload: {
    TableName: "tenantURLMap",
    Key: {
      url:
        process.env.NODE_ENV === "production"
          ? window.location.hostname
          : process.env.VUE_APP_DEV_URL,
    },
  },
};

export default {
  components: {
    Messages,
  },
  data() {
    return {
      send_button: false,
      loader: false,
      finished: false,
      loadermsg: "",
      sucessmsgtext: "",
      iserrormsg: false,
      isdisplaymsg: false,
      username: "",
      pass: "",
      error: null,
      errors: {},
      checkIsstaffexists: false,
      mutedloggedInUserInfo: this.loggedInUserInfo,
      is_login_success: false,
      tenantId: "",
      showHideFlag: false,
      contains_lowercase: false,
      contains_number: false,
      contains_uppercase: false,
      email: "",
      isinvalidcred: false,
      logo: "",
      errormsg_password: "",
      errormsg_email: "",
      showFreeTrialButton: false,
      remainingdays: 0,
      totalvalidity: 0,
      graceday: 0,
      validitydays: 0,
    };
  },
  created() {},
  beforeMount() {},
  mounted() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover",
    });
  },
  updated() {},
  computed: {},
  methods: {
    closeMessage() {
      this.isdisplaymsg = false;
    },
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
        this.isinvalidcred = false;
        this.errormsg_email = false;
      } else {
        this.msg["email"] = "Please enter a valid email";
        this.errormsg_email = false;
      }
    },
    Sendmail() {
      debugger;
      var self = this;
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        self.errormsg_email = false;
        self.loader = true;
        self.loadermsg = "Sending email";
        Axios({
          method: "post",
          url: rest_service.baseURL + "/Authenticate/RequestReset",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            email: self.email,
          },
        })
          .then(function (response) {
            if (response.data) {
              if (response.data.result == "Success") {
                self.send_button = true;
                self.sucessmsgtext = "Email sent successfully";
                self.isdisplaymsg = true;
                self.iserrormsg = false;
                setTimeout((xy) => {
                  self.closeMessage();
                }, 5000);
              } else {
                self.sucessmsgtext = "Failed to send email";
                self.isdisplaymsg = true;
                self.iserrormsg = true;
                setTimeout((xy) => {
                  self.closeMessage();
                }, 5000);
              }
              self.loader = false;
              self.loadermsg = "";
            } else {
              self.loader = false;
              self.loadermsg = "";
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            self.loader = false;
            self.loadermsg = "";
            return error;
          });
      } else {
        self.errormsg_email = true;
      }
    },
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
    },
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
  },
  updated() {},
};
</script>
  
  <style type="text/css">
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body,
html {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100%;
}
html {
  font-size: 14px;
  height: 100%;
}
body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  height: 100%;
}

.signin-main {
  background: #cbdeeb;
  max-width: 100% !important;
  min-height: 100vh !important;
  padding: 50px;
}
.signin-main a {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: color, background-color, opacity;
}
.signin-main a:hover {
  text-decoration: none !important;
}
.signin-main h1 {
  font-size: 28px;
  font-weight: 500;
}
.signin-main h2 {
  font-size: 28px;
  font-weight: 400;
}
.signin-main h3 {
  font-size: 22px;
  font-weight: 400;
}
.signin-main h4 {
  font-size: 19px;
  font-weight: 400;
}
.signin-main h5 {
  font-size: 17px;
  font-weight: 500;
}
.signin-main .mart-40 {
  margin-top: 40px !important;
}
.signin-main .primary-btn {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  background: #1f85cd;
  color: #fff;
  border: 1px solid #1f85cd;
  border-radius: 0;
  text-decoration: none;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 115px;
  float: left;
  text-align: center;
}
.signin-main .primary-btn:hover {
  background: #0043a4;
  color: #fff;
  text-decoration: none;
  border-color: #0043a4;
}
.signin-main .secondary-btn {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  color: #1f85cd;
  border: 1px solid #1f85cd;
  border-radius: 0;
  text-decoration: none;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 115px;
  float: left;
  text-align: center;
}
.signin-main .secondary-btn:hover {
  background: #1f85cd;
  color: #fff;
  text-decoration: none;
  border-color: #1f85cd;
}
.signin-main .ripple {
  position: relative;
  overflow: hidden;
}
.signin-main .ripple:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
.signin-main .ripple:focus {
  box-shadow: none;
}
.signin-main .ripple:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  box-shadow: 0 0 0 30px #fff inset !important;
}
.signin-container {
  margin-top: 140px;
  background: none !important;
}
.login_hold {
  border: 1px solid #e4e8e7;
  border-radius: 3px;
  box-shadow: 0 0 3px 1px #e0dde0;
}
.login_main {
  padding: 12% 10% 19%;
}
.login_img {
  max-height: 440px;
}
.login_img img {
  height: 100%;
  width: 100%;
}
.login_hold .logoholder {
  margin-bottom: 40px;
}
.signin-container input {
  display: block;
  width: 100%;
  background: #fff !important;
  height: 45px !important;
  border: none !important;
  border-bottom: solid 2px #d2d4d5 !important;
  padding-left: 35px;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1d1d1d !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.signin-container .error-msg-hold input {
  display: block;
  width: 100%;
  background: #fff !important;
  height: 45px !important;
  border: none !important;
  border-bottom: solid 2px #f44336 !important;
  padding-left: 35px;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #f44336 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.form-elements::after,
.form-group::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.signin-container label {
  position: absolute;
  top: 8px;
  left: 35px;
  pointer-events: none;
  color: #bababa;
  font-size: 14px;
  font-weight: lighter;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.signin-container input:focus,
.signin-container input:not(:placeholder-shown) {
  box-shadow: none;
  outline: none;
  border-color: #1f85cd !important;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.signin-container input:focus ~ label,
.signin-container input:not(:placeholder-shown) ~ label {
  top: -15px;
  font-size: 13px;
  color: #1f85cd;
  font-weight: lighter;
}
.form-group {
  margin-bottom: 1rem;
}
.signin-container .error-msg-hold label,
.signin-container .error-msg-hold input:focus ~ label,
.signin-container .error-msg-hold input:valid ~ label {
  left: 35px;
  padding: 0;
}
.signin-container .material-icons {
  position: absolute;
  top: 5px;
  left: -2px;
  color: #bababa;
}
.signin-main {
  width: 100%;
  height: 100%;
  display: table;
  min-height: 100%;
}
.signin-container {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.signin-hold {
  max-width: 475px;
  margin: 0 auto;
  border: 1px solid #ddd;
  padding: 60px 80px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.form-group.signin-icons {
  position: relative;
}
.signin-user:after {
  content: "";
  background: url(../../assets/signin-icon.png) -5px -5px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.singin-password:after {
  content: "";
  background: url(../../assets/signin-icon.png) -5px -57px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.error-msg-hold .signin-user:after {
  content: "";
  background: url(../../assets/signin-icon-red.png) -5px -5px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.error-msg-hold .singin-password:after {
  content: "";
  background: url(../../assets/signin-icon-red.png) -5px -57px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  left: 0;
  top: 9px;
}
.signin-pass-hidden:after {
  content: "";
  background: url(../../assets/signin-icon.png) 7px -151px no-repeat;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 5px;
  top: 9px;
}
.signin-icons .signin-pass-visible:after {
  content: "";
  background: url(../../assets/signin-icon.png) 7px -108px no-repeat;
  width: 30px;
  height: 20px;
  position: absolute;
  display: block;
  right: 0px;
  top: 9px;
}
.signin-btn {
  float: none;
  margin: 0 auto;
  padding: 10px 45px;
  text-transform: uppercase;
  display: block;
}
.forgot-hold {
  display: block;
  width: 100%;
  text-align: right;
}
.signin-btn-hold {
  margin-top: 20px;
}
.signin-btn-hold .signin-btn {
  float: none;
  margin: 0 auto;
  padding: 10px 35px;
  display: block;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
.erroe-message {
  font-size: 13px;
  color: #f44336;
  text-align: left;
  float: left;
  margin-top: 5px;
}
.signin-bottom-hold {
  max-width: 475px;
  margin: 15px auto;
}
.signin-powered {
  float: left;
  font-weight: 500;
}
.signin-powered img {
  padding-left: 5px;
  max-width: 100px;
}
.signin-help {
  float: none;
  text-align: center;
}
.signin-help ul li {
  list-style: none;
  display: inline-block;
  padding: 0 10px;
  color: #111;
  font-weight: 500;
}
.signin-help ul li:last-child {
  padding-right: 0;
}
.signin-help ul li a {
  color: #111;
}
.divider-or {
  color: #d2d4d5 !important;
}
.not-allowed-mark {
  cursor: not-allowed;
  max-width: 150px;
  margin: 0 auto;
  display: block;
}
.password-field:focus ~ .input-visible,
.password-field:valid ~ .input-visible {
  display: block;
  -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
  
  