<template>
    <div>
        <div v-if="showmodal">
        <!-- template for the modal component -->
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container modal-width500">

                            <div class="modal-header">
                                <!-- <slot name="header"></slot> -->
                                <button type="button" data-dismiss="modal" @click="$emit('cancelAction')" class="close d-close">
                                  <i class="material-icons">close</i>
                                </button>
                            </div>

                            <div class="modal-body">
                                <slot name="body">
                                   <p class="prompt-style-sml text-center"> {{actionmessage}}</p>
                                </slot>
                            </div>

                            <div class="modal-footer">
                                <slot name="footer">
                                <div class="col-md-12">
                                  <div class="pull-left">
                                    <button type="button" data-toggle="modal"
                              data-target="#myModal" class="secondary-btn marr-10 ripple" @click="$emit('confirmAction')">
                                        Yes
                                    </button>
                                    </div>
                                    <div class="pull-right">
                                    <button type="button" class="primary-btn ripple " @click="$emit('cancelAction')">
                                        No
                                    </button>
                                    </div>
                                    </div>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>


<script>
export default {
  props: ["actionmessage","showmodal"],
  data() {
    return {};
  },
  methods: {
    closemsg() {}
  },
  create(){},
  computed:{
      getModal(){}
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
 
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}



/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-container.modal-width500{max-width:500px; width:500px; padding: 10px;}
.modal-container.modal-width500 .modal-header{border-bottom:1px solid transparent !important; padding-bottom: 0px !important;}
.modal-container.modal-width500 .modal-footer{border-top:1px solid transparent !important;}
.modal-container.modal-width500 .modal-header{min-height: 10px !important;}
.modal-container.modal-width500 .modal-body{margin: 0px !important;}
.modal-container.modal-width500 .modal-body .prompt-style-sml{font-size: 16px;font-weight: 500;}
</style>